.pages-product-slide
    overflow: hidden
    @media screen and ( max-width: 1280px )
        min-height: 770px
    &.section
        padding-top: 90px
        padding-bottom: 110px
    .pages-content
        max-width: 850px
        margin: 0 auto
        margin-bottom: 25px
    .product-slide
        position: relative
