@function r($size)
	@return ($size/16px)*1rem
=img-ratio($ratio,$fit:cover)
	position: relative
	display: block
	height: 0
	overflow: hidden
	padding-top: $ratio *100%
	img,iframe,video
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		object-fit: $fit
		transition: .3s ease-in-out all
=center-image
	position: relative
	display: block
	height: 0
	overflow: hidden
	padding-top: 100%
	img,iframe,video
		position: absolute
		width: 100%
		height: 100%
		top: 50%
		left: 50%
		transform: translate(-50%,-50%)
=bg-top-right()
	background-image: url('../img/bg/right.png')
	background-repeat: no-repeat
	background-size: inherit
	background-position: top right

=left-arrow
	&:before
		display: inline-block
		font-family: 'Linearicons-Free'
		content: '\e87a'
		font-size: 12px
		color: $blue
		@apply mr-2
		@media screen and ( max-width:1024px)
			font-size: 10px
=left-dot
	@apply ml-3 flex items-start justify-start
	&:before
		display: inline-block
		font-family: 'Material Design Icons'
		content: '\F012F'
		font-size: 6px
		color: #333333
		@apply -ml-3 pr-2 pt-2
=line-grey
	&:after
		content: ''
		display: block
		position: absolute
		left: 0
		bottom: 0
		width: 100%
		height: 1px
		background: #d7d7d7
=boxshadow
	box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.09)
