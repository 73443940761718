.pages-product-detial-2
    .wrapper
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.03)
        background-color: $white
        padding: 30px 70px 60px
        @media screen and ( max-width: 1023.98px )
            padding: 15px 30px

        .tab-info
            padding-bottom: 15px
            @media screen and ( max-width: 768px )
                padding-bottom: 0px
            ul
                padding: 0px
                display: flex
                align-items: center
                overflow-x: auto
                padding-bottom: 15px
                position: relative
                &::before
                    content: ''
                    position: absolute
                    width: 100%
                    left: 0px
                    height: 1px
                    bottom: 2px
                    background-color: #e1e1e1
                &::-webkit-scrollbar
                    width: 0px
                    height: 0px
                li
                    list-style: none
                    padding: 0px 12px
                    white-space: nowrap
                    &.text-hover
                        &::after
                            height: 3px
                            top: 35px
                            @media screen and ( max-width: 1279.98px )
                                top: 33px
                            @media screen and ( max-width: 1023.98px )
                                top: 32px

        .tab-content
            .content
                margin-top: 15px
                *
                    color: #333333
                    font-size: r(18px)
                    font-weight: 400
                    & + *
                        margin-top: 25px
                strong
                    font-weight: 700
