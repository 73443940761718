.pages-product-list
	.product-sidebar
		.product-list
			.list-title
				color: $white
				font-size: r(18px)
				font-weight: 700
				text-transform: uppercase
				padding: 15px 18px
				background-color: $main
				@media screen and ( max-width: 1024px )
					background-color: $tran
					color: $black
			.list-item
				box-shadow: 0 0 15px rgba(0, 0, 0, 0.03)
				border: 1px solid #e1e1e1
				background-color: $white
				@media screen and ( max-width: 1024px )
					background-color: $tran
					color: $black
					border: 0px
					box-shadow: none
				ul
					padding: 0px
					li
						list-style: none
						color: #333333
						font-size: r(15px)
						font-weight: 600
						transition: .3s all ease-in-out
						padding: 15px 18px
						&:not(:last-child)
							border-bottom: thin solid #e1e1e1
						&.active,&:hover
							color: $main
							font-weight: 700

		@media screen and ( max-width: 1024.98px )
			position: fixed
			width: 100%
			max-width: 320px
			margin: 0 auto
			opacity: 0
			pointer-events: none
			visibility: hidden
			transition: .3s all ease-in-out
			z-index: 50
			right: 0
			left: 0
			bottom: 4vh
			overflow: auto
			padding: 15px 0px
			padding-right: 5px
			top: 4vh
			&::-webkit-scrollbar-track
				box-shadow: inset 0 0 6px rgba(255, 255, 255, 0)
				background-color: #cccccc
			&::-webkit-scrollbar
				width: 0px
				height: 0px
			&::-webkit-scrollbar-thumb
				border-radius: 20px
				box-shadow: inset 0 0 6px rgba(255, 255, 255, 0)
				background-color: $main
			&.active
				transition-delay: 0.5s
				opacity: 1
				pointer-events: visible
				visibility: visible
		@media screen and ( max-width: 375px )
			margin-top: 15px
			padding-top: 0px
	.product-list
		.head
			display: flex
			justify-content: space-between
			align-items: flex-start
			margin-bottom: 10px
			.filter
				display: flex
				align-items: center
				justify-content: flex-end
				flex: 1
				@media screen and ( max-width: 1280px )
					flex-direction: column
					align-items: flex-end
				.wrapper
					display: flex
					align-items: center
					& + .wrapper
						margin-top: 10px
						@media screen and ( min-width: 1280px )
							margin-left: r(30px)
							margin-top: 0px
				.title
					color: $black
					font-size: r(15px)
					font-weight: 600
					text-transform: uppercase
				select
					margin-left: r(20px)
					border: thin solid #e1e1e1
					background-color: $white
					min-width: 90px
					height: 40px
					padding: 0px 10px
					max-width: 155px
					appearance: none
					background: url(../img/expand_more.png) no-repeat right 10px center
        // .body
        //     .product-item
        //         .img
        //             a
        //                 img
        //                     object-fit: contain
	.product-describe
		margin-top: 30px
		position: relative
		.full-content
			max-height: 300px
			min-height: 1px
			overflow: hidden
			&.active
				max-height: 100%
				padding-bottom: 4rem

			*
				font-size: r(16px)
				color: $text
				& + *
					margin-top: r(20px)
			span
				color: $main
				font-weight: 600
		.expand
			position: absolute
			left: 50%
			transform: translateX(-50%)
			width: 100%
			height: 200px
			bottom: 0
			display: flex
			justify-content: center
			align-items: center
			cursor: pointer
			&.active
				height: fit-content
				&::before
					background-image: linear-gradient(#ffffff,#fff)
			span
				font-size: r(16px)
				color: $main
				font-weight: 400
				padding: 5px 40px
				display: flex
				justify-content: center
				align-items: center
				background-color: $white
				border-radius: 5px
				border: thin solid $main
				transition: .3s all ease-in-out
				&:hover
					border-radius: 20px
			&::before
				position: absolute
				content: ''
				bottom: 0
				left: 0
				width: 100%
				height: 100%
				background-image: linear-gradient(#ffffff00, #fff)
				z-index: -1
	.filter_tool
		position: fixed
		width: 70px
		height: 70px
		display: flex
		justify-content: center
		align-items: center
		bottom: -10px
		left: -10px
		border-radius: 50% 25% 50% 0
		background-color: #f8f8f8
		cursor: pointer
		transition: .3s all ease-in-out
		z-index: 20
		box-shadow: 0px 0px 20px rgba(#000 ,0.25 )
		&.active
			width: 100%
			height: 100%
			left: 0px
			bottom: 0px
			border-radius: 0px
			box-shadow: none
			.main
				em
					opacity: 0
					pointer-events: none
					visibility: hidden
			.close-button
				pointer-events: visible
				visibility: visible
				display: block
				position: fixed
				right: 15%
				top: 2vh
				animation: 2s slideUp
				z-index: 999
				cursor: pointer
				@media screen and ( max-width: 576px )
					right: 5%
		@media screen and ( min-width: 1024px )
			display: none
		.main
			width: 100%
			height: 100%
			display: flex
			justify-content: center
			align-items: center
			em
				color: $main
				transition: .3s all ease-in-out
				font-size: r(35px)
		.close-button
			pointer-events: none
			visibility: hidden
			transition: .3s all ease-in-out
			display: none
