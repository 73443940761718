.pages-brand-sec-7
    min-height: 735px
    .option-box
        max-width: 1020px
        margin: 0 auto
        margin-top: 30px
        @media screen and ( min-width: 1024px )
            margin-top: 48px
        .col
            flex: 0 0 25% !important
        .option-wrapper
            .option
                box-shadow: 2px 2px 30px rgba(4, 4, 4, 0.05)
                border-radius: 50px
                background-color: $white
