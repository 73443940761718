.block_banner-home
	position: relative
	.img-banner
		img
			width: 100%
			height: 100%
			object-fit: cover
			@media screen and ( max-width: 1280px )
				min-height: 60vh
	.content-banner
		position: absolute
		width: 100%
		bottom: r(160px)
		left: 0
		@media screen and ( max-width: 1023px )
			left: 50%
			transform: translate(-50%,-50%)
			bottom: unset
			top: 50%
		@media screen and ( max-width: 576px )
			bottom: r(100px)
		.title-banner
			max-width: 625px
			@media screen and ( max-width: 768px )
				width: 100%
				max-width: unset

			h2
				font-size: r(34px)
				color: #fff
				padding-bottom: r(20px)
				text-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
				color: $white
				font-weight: 700
				@media screen and ( max-width: 576px )
					padding-bottom: r(10px)
					font-size: r(26px)
		.readmore
			width: r(200px)
			height: r(40px)
			border: 2px solid #ffffff
			color: #fff
			text-transform: uppercase
			display: flex
			justify-content: center
			align-items: center
			font-size: 1rem
			margin: unset
			span
				padding-left: r(20px)
	.swiper-pagination
		bottom: 80px
		z-index: 99999
		@media screen and ( min-width: 1024px )
			bottom: 120px

		.swiper-pagination-bullet
			background-color: $tran
			border: thin solid $main
			opacity: 1
			width: 12px
			height: 12px
		.swiper-pagination-bullet-active
			background-color: $main

	.overlay-bot
		position: absolute
		bottom: 0
		left: 50%
		width: 100%
		background-color: rgba(0,0,0,0.2)
		display: flex
		align-items: center
		justify-content: center
		z-index: 2
		transform: translateX(-50%)
		@media screen and ( max-width: 576px )
			padding: 0 20px

		.trademark
			padding: 19.5px 22px
			@media screen and ( max-width: 768px )
				flex: 0 0 75px
				padding: 5px
				margin: 0 15px
			@media screen and ( max-width: 576px )
				margin: 0 5px
				flex: 0 0 20%
				img
					width: 95%
					height: 95%
					object-fit: cover
