.block_s-4
	&.section
		padding-top: 0px
		@media screen and ( min-width: 1280px )
			padding-bottom: 80px
	.box-video
		max-width: 810px
		margin: 0 auto
		.video
			position: relative
			&::after
				content: ''
				display: block
				width: 100%
				padding-bottom: 58%
			iframe
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				padding-top: r(20px)
				object-fit: cover

.option-box
	.row
		justify-content: space-between
		.col
			flex: 0 0 20%
			// @media screen and (max-width: 1024px)
			// 	flex: 0	0 33.33333%
			// @media screen and (max-width: 576px)
			// 	flex: 0	0 50%
			padding: 0 15px
			@media screen and ( max-width: 425px )
				flex: 1 !important
			.option-wrapper
				display: flex
				flex-direction: column
				align-items: center
				margin-bottom: 30px
				flex-wrap: wrap
				.option
					width: 180px
					height: 180px
					flex: 0 0 180px
					background-color: #fafafa
					border-radius: 30%
					padding: 0
					@media screen and ( min-width:425px )
						padding: 18.5%
					@media screen and ( max-width: 425px )
						padding: 10%
					.img
						width: 100%
						position: relative
						&::after
							content: ''
							display: block
							width: 100%
							padding-bottom: 100%
						img
							position: absolute
							top: 0
							left: 0
							width: 100%
							height: 100%
							object-fit: cover
							@media screen and ( min-width:425px )
								object-fit: none
				.content-option
					font-size: 1rem
					padding: 0 r(5px)
					padding-top: r(20px)
					text-align: center
