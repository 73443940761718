.pages-document
    .box-table
        margin-top: 25px
        margin-bottom: r(40px)
        table
            width: 100%
            th
                font-size: r(16px)
                font-weight: 400
                padding: 13px 0px
                @media screen and ( max-width: 1024px )
                    padding: 13px 5px
                &:first-child
                    padding-left: 75px
                    @media screen and ( max-width: 1280px )
                        padding-left: 15px
                &:nth-of-type(2)
                    width: 675px
                &:last-child
                    @media screen and ( max-width: 1280px )
                        padding-right: 15px
            thead
                background-color: $main
                tr
                    width: 100%
                    th
                        color: $white
                        font-weight: 700
                        white-space: nowrap
                        margin: 0px 10px

            tbody
                tr
                    width: 100%
                    &:nth-of-type(even)
                        background-color: #f6f6f6
                    th
                        color: #333333
