.block_s-5
	background-color: #f8f8f8
	&.section
		@media screen and ( min-width: 1280px )
			padding-bottom: 75px
	.block_title
		margin-bottom: r(10px)
	.tab_menu
		ul
			list-style: none
			display: flex
			flex-wrap: wrap
			justify-content: center
			padding: 0 r(12px)
			@media screen and (max-width: 1024px)
				padding: 0
			li
				font-size: 1rem
				margin: 1rem
				cursor: pointer
				transition: all .2s
				position: relative
				&:after
					position: absolute
					bottom: -2px
					left: 0
					right: 0
					margin: 0 auto
					width: 0%
					content: ''
					color: transparent
					background: $main
					height: 2px
					transition: all .2s
				&:hover
					color: $main
					&:after
						width: 100%
				&.active
					color: $main
					&:after
						width: 100%
		.body-tab_menu
			padding-top: 3rem
			.img-wrapper
				margin-bottom: 30px
				position: relative
				.img
					width: 100%
					position: relative
					&::after
						content: ''
						display: block
						width: 100%
						padding-bottom: 100%
					img
						position: absolute
						top: 0
						left: 0
						width: 100%
						height: 100%
						object-fit: cover
				.content_img, .big-content_img
					position: absolute
					z-index: 2
					bottom: r(20px)
					left: r(30px)
					margin-right: r(30px)
					color: #fff
					font-size: 1rem
					font-weight: 700
					transition: 0.2s all
					border-bottom: 1px solid transparent
					&:after
						position: absolute
						bottom: -2px
						left: 0
						right: 0
						width: 0
						content: ''
						color: transparent
						background: #ffffff
						height: 2px
						transition: all .2s
				.big-content_img
					font-size: 2.4rem
				&:hover
					.content_img, .big-content_img
						&:after
							width: 100%
