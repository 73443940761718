
.product-item
    background-color: $white
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.03)
    border: thin solid #f3f3f3
    position: relative
    transition: .3s all  ease-in-out
    overflow: hidden
    &:hover
        border: thin solid $main
        border-bottom: 0px solid $tran
        .img
            img
                transform: scale(1.05)
        .fake-content
            opacity: 0
            pointer-events: none
            visibility: hidden
            .title
                animation: 0.5s loading
                animation-delay: 0.2s
            .content
                animation: 0.5s loading
                animation-delay: 0.1s
        .overlay-content
            transition-delay: 0.3s
            opacity: 1
            pointer-events: visible
            visibility: visible
    .img
        overflow: hidden
        a
            +img-ratio(260/285,contain)
    .fake-content
        transition: .3s all  ease-in-out
        padding: 20px
        border-top: thin solid #f3f3f3
        pointer-events: none
        .title
            color: $black
            font-size: r(16px)
            font-weight: 700
            display: -webkit-box
            -webkit-line-clamp: 2
            -webkit-box-orient: vertical
            overflow: hidden
            transition: .3s all  ease-in-out
            pointer-events: none
        .content
            margin-top: 5px
            color: $main
            color: $main
            font-family: "Be Vietnam"
            font-size: r(14px)
            font-weight: 400
            display: flex
            align-items: center
            transition: .3s all  ease-in-out
            pointer-events: none
            em
                font-family: "Material Icons"
                font-size: 18px
                margin-right: 5px
    .overlay-content
        opacity: 0
        pointer-events: none
        visibility: hidden
        position: absolute
        transition: .3s all  ease-in-out
        top: 0
        left: 0
        bottom: 0
        right: 0
        z-index: 2
        display: flex
        justify-content: space-between
        align-items: center
        flex-direction: column
        background-color: rgba(#000 ,0.5 )
        width: 100%
        height: 100%
        overflow: auto
        &::-webkit-scrollbar
            width: 3px
            height: 3px
        .head-info
            padding: 0px 13px
            padding-right: 4px
            display: flex
            align-items: flex-start
            flex-direction: column
            justify-content: center
            width: 100%
            height: 100%
            .wrapper
                padding-right: 8px
                width: 100%
                overflow: auto
                &::-webkit-scrollbar
                    width: 0px
                    height: 0px
            .title
                color: $white
                font-size: r(16px)
                font-weight: 700
                text-decoration: underline
            table
                width: 100%
                tr
                    color: $white
                    font-size: r(12px)
                    line-height: r(30px)
                    font-weight: 400
                    th
                        font-weight: 700
                        white-space: nowrap
                    td
                        margin-left: 10px
                        display: block
        .body-info
            display: flex
            justify-content: space-between
            align-items: center
            width: 100%
            .link
                display: flex
                justify-content: center
                align-items: center
                flex: 0 0 50%
                color: $main
                font-size: r(14px)
                font-weight: 400
                height: 65px
                background-color: $white
                transition: .3s all  ease-in-out
                @media screen and ( max-width: 768px )
                    height: 50px

                em
                    color: $main
                    font-size: r(18px)
                    font-weight: 400
                    margin-right: 5px
                    transition: .3s all  ease-in-out
                &:hover
                    background-color: $main
                    color: $white
                    em
                        color: $white
