.page-contact
    .item-1
        margin-top: 40px
        @media screen and ( max-width: 1280px )
            margin-top: 20px

        .col-lg
            &:first-child
                .box
                    .info
                        a,p
                            font-size: r(11px)
        .box
            width: 100%
            height: 144px
            text-align: center
            box-shadow: 0 0 21px rgba(131, 131, 131, 0.1)
            background-color: $white
            align-items: center
            justify-items: center
            display: flex
            flex-direction: column
            padding: 25px 15px
            padding-top: 15px
            margin-bottom: 40px
            @media screen and ( max-width: 1280px )
                margin-bottom: 20px
                padding-top: 20px

            *
                line-height: 1.3
            .title
                color: $main
                font-size: r(14px)
                font-weight: 700
                line-height: 8.76px
                text-align: center
                text-transform: uppercase
                margin-bottom: 10px
            .info
                a,p
                    color: #001111
                    display: block
                    font-size: r(12px)
                    font-weight: 600
                    text-align: center
        .image-box
            margin-bottom: 10px
    .item2
    .ModuleContent
        widows: 100%
        height: 100%
    .maps
        width: 100%
        overflow: hidden
        +img-ratio(630/705)
        @media screen and ( min-width: 992px )
            height: 100% !important
            iframe
                height: 630px
        @media screen and ( max-width: 1024px )
            padding-top: 100%

    .wrap-form
        width: 100%
        @media screen and ( max-width: 992px )
            padding-left: 0
    .form-group
        margin-bottom: 21px
        position: relative
        &:last-child
            margin-bottom: 0px
        span
            color: $main
            position: absolute
            padding-top: 2px
            font-size: r(10px)
            font-weight: 600
            top: 100%
            left: 20px
            @media screen and ( max-width: 576px )
                font-size: r(16px)
    label
        color: black
        color: #666666
        font-size: r(16px)
        display: block
        padding-bottom: 5px
    input,textarea
        width: 100%
        height: 45px
        border: 0px
        background-color: #f8f8f8
        padding: 0px 15px
    textarea
        min-height: 135px
        resize: none
    .frm-btnwrap
        label
            font-size: 0px
        .frm-btn
            .frm-btn-reset
                display: none
            .frm-btn-submit
                margin-top: 30px
                position: relative
                margin-left: auto
                width: 210px
                height: 50px
                display: flex
                justify-content: center
                align-items: center
                background-color: $main
                color: $white
                font-size: r(16px)
                font-weight: 700
                text-transform: uppercase
                letter-spacing: 0.8px
                border: thin solid $main
                background: url(../img/submit_arrow.png) no-repeat right 20px center
                transition: .3s all  ease-in-out
                &:hover
                    background-color: $main
                    background-position: right 10px center
    &.frm-btnwrap

    .Module-344
        @media screen and ( max-width: 992px )
            margin-top: 30px
