header
	position: fixed
	z-index: 20
	top: 0
	left: 0
	width: 100%
	background-color: #fff
	transition: .3s all ease-in-out
	&.header-scroll
		box-shadow: 0px 0px 20px rgba(#000 ,0.25 )
	&.disable_bsd
		box-shadow: none !important
	@media screen and ( max-width: 1280px )
		box-shadow: 0px 0px 20px rgba(#000 ,0.25 )
	ul
		li
			list-style: none
	.header
		height: r(90px)
		display: flex
		align-items: center
		justify-content: space-between
		.header_logo
			.logo
				width: r(100px)
		.header_main
			.header_menu
				display: flex
				align-items: center
				.menu
					@media screen and ( max-width: 1280px )
						display: none

				.language-box
					position: relative
					margin-right: r(35px)
					margin-left: r(40px)
					&::after
						position: absolute
						content: '\ea4d'
						font-family: "remixicon"
						font-size: r(20px)
						top: -5px
						right: -20px
					&:hover
						&::after
							color: $main
						.vn
							ul
								opacity: 1
								pointer-events: visible
								visibility: visible
								transform: translate(0)
						&::before
							pointer-events: visible
					&::before
						content: ''
						position: absolute
						width: 100%
						height: 30px
						top: 100%
						left: 50%
						transform: translateX(-50%)
						pointer-events: none
					.vn
						position: relative
						.img
							width: 20px
							height: 20px
							border-radius: 50%
							overflow: hidden
							img
								display: block
						ul
							position: absolute
							left: -1rem
							padding: 1rem
							background-color: #fff
							opacity: 0
							pointer-events: none
							visibility: hidden
							transition: .3s all ease-in-out
							transform: translateY(30px)
							box-shadow: 0px 0px 20px rgba(#000 ,0.1 )
							margin-top: 5px

				.search-icon
					cursor: pointer
					span
						font-size: r(20px)
						&:hover
							color: $main

				.header_btn
					width: r(40px)
					height: r(40px)
					margin-left: r(20px)
					background-color: $main
					color: #ffffff
					cursor: pointer
					font-size: r(20px)
					display: none
					@media screen and ( max-width: 1280px )
						display: flex
						justify-content: center
						align-items: center
	.menu
		display: flex
		@media screen and ( max-width: 1280px )
			display: block
			width: 70%
		li
			&.home
				a
					width: 20px
					display: block
					text-align: center
					margin: 0 auto
			& + li
				margin-left: r(40px)
			@media screen and ( max-width: 1280px )
				text-align: center
				padding: 10px 0px
				& + li
					margin-left: 0px
			a
				font-size: r(16px)
				font-weight: 500
				transition: all .2s
				position: relative
			&.active
				a.text-hover
					color: $main
					&:after
						width: 100%
	.has-dropdown
		position: relative
		&:hover
			&::before
				pointer-events: visible
		&::before
			content: ''
			position: absolute
			width: 100%
			height: 30px
			top: 100%
			left: 50%
			transform: translateX(-50%)
			pointer-events: none
		&:hover
			.dropdown-menu
				opacity: 1
				pointer-events: visible
				visibility: visible
				transform: translateY(0px)
		.wrapper
			em
				display: none
			@media screen and ( max-width:1280px)
				em
					font-size: r(20px)
					position: absolute
					transform: translateY(-50%)
					right: 0
					top: 50%
					z-index: 99
					width: 20px
					height: 20px
					display: flex
					justify-content: center
					align-items: center
					color: $black
					transition: .3s all ease-in-out
					&.active
						color: $main
						transform: translateY(-50%) rotate(180deg)

	.dropdown-menu
		position: absolute
		left: 0
		top: 38.5px
		padding: 30px
		padding-top: 25px
		box-shadow: 2px 2px 25px rgba(4, 4, 4, 0.05)
		background-color: $white
		transform: translateX(200%)
		transition: .3s all ease-in-out
		@media screen and ( min-width: 1281px )
			transform: translateY(30px)
			opacity: 0
			pointer-events: none
			visibility: hidden
			width: 660px
		@media screen and ( max-width: 1280px )
			z-index: 99
			top: 0
			bottom: 0
			position: fixed
			padding-top: 35px
			overflow-y: auto
			overflow-x: hidden
			width: 100%
			transition: all 0.8s cubic-bezier(.68,-.55,.265,1.55)
			.col-xl-6
				&:last-child
					margin-top: 2rem
		&.active
			transform: translateX(0%)
		.title
			color: $black
			font-size: r(16px)
			font-weight: 600
			text-transform: uppercase
			padding-bottom: 20px
			border-bottom: thin solid $main
			max-width: 260px
			width: 100%
		.col-xl-6
			@media screen and ( max-width: 1280px )
				padding-left: 15px
				flex: 0 0 100%
				max-width: 100%

		ul
			padding: 0px
			margin-top: 18px
			li
				margin-left: -5px !important
				margin-bottom: 10px
				&:hover,&.active
					color: $main
				& + li
					margin-left: 0px
				a
					width: 100%
					text-align: left
					color: #333333
					font-size: r(14px)
					font-weight: 500
					display: flex
					transition: .3s all ease-in-out

					em
						margin-right: 10px
						font-size: r(18px)
						font-weight: 400
						margin-top: 4px
		&#support_menu,&#project_menu
			@media screen and ( min-width:1281px  )
				width: 220px
				padding: 15px 20px
				ul
					margin-top: 0px
					li
						&:last-child
							margin-bottom: -5px
	#dropdown
		.header_btn
			z-index: 100
	.header_infor
		display: flex
		justify-content: flex-end
		padding-bottom: 1rem
		@media screen and ( max-width: 1280px )
			display: none
			padding: 2rem 0
			flex-direction: column
		.hotline-box
			padding-right: 1rem
			border-right: 1px solid #111111
			display: flex
			@media screen and ( max-width: 1280px )
				border: 0
				padding-right: 0
			.tel
				font-size: r(14px)
				padding-right: 5px
			ul
				display: flex
				@media screen and ( max-width: 1280px )
					display: block
				li
					display: flex
					&::after
						content: '-'
						font-size: r(14px)
						transform: scaleX(2)
						padding: 0 .8rem
						@media screen and ( max-width: 1280px )
							all: unset
					&:last-child
						&::after
							all: unset
					a
						font-size: r(14px)
						color: #ed1c24
						font-weight: 700
					p
						padding-left: 5px
						font-size: r(14px)
		.email-box
			font-size: r(14px)
			padding-left: 1rem
			@media screen and ( max-width: 1280px )
				padding-left: 0
				padding-top: 1rem
	.block_search
		width: 100%
		height: 100%
		background-color: rgba(237,28,36,0.9)
		position: fixed
		top: 0
		left: 0
		z-index: 10
		padding: 30px
		opacity: 0
		pointer-events: none
		transform: translateX(20%)
		transition: all .5s cubic-bezier(.68,-.55,.265,1.55)
		&.show
			opacity: 1
			pointer-events: auto
			transform: translateX(0)
		.search-icon
			position: absolute
			top: 1rem
			right: 1rem
			cursor: pointer
			width: 4rem
			height: 4rem
			border-radius: 50%
			background-color: #fff
			font-size: 2rem
			display: flex
			justify-content: center
			align-items: center
			&:hover
				color: $main
		.search_box
			position: relative
			top: 50%
			left: 50%
			transform: translate(-50%, -50%)
			width: 40rem
			height: 4rem
			position: relative
			input
				width: 100%
				height: 100%
				border: 0
				background-color: #ffffff
				padding-left: 1rem
				color: #000000
				font-size: r(16px)
				&::placeholder
					color: #000000
					font-size: r(16px)
					font-style: italic
			button
				position: absolute
				top: 0
				right: 0
				border: 0
				width: 4rem
				height: 100%
				background-color: unset
				font-size: 2rem
				color: #000000
	.menu_mobile
		display: none
		@media screen and ( max-width: 1280px )
			display: block
			width: 50%
			height: 100%
			background-color: #fff
			position: fixed
			top: 0
			right: 0
			z-index: 10
			padding: 15px
			opacity: 0
			bottom: 0
			overflow-y: auto
			overflow-x: hidden
			pointer-events: none
			transform: translateX(20%)
			transition: all .5s cubic-bezier(.68,-.55,.265,1.55)
			max-width: 320px
			width: 100%
		.header_btn
			position: absolute
			top: 0
			right: 0
			width: 35px
			height: 35px
			display: flex
			justify-content: center
			align-items: center
			background-color: $main
			color: #fff
			font-size: r(35px)
			cursor: pointer
		.mobile-wrapper
			display: flex
			flex-direction: column
			align-items: center
			justify-content: center
			margin-top: 20px
	.menu_mobile.show
		opacity: 1
		pointer-events: auto
		transform: translateX(0)
	.overlay
		position: fixed
		display: none
		width: 100%
		height: 100%
		z-index: 9
		top: 0
		left: 0
		background-color: rgba(0,0,0,0.5)
	.overlay.show
		@media screen and ( max-width: 1280px )
			display: block
