
.button
    .btn-submit
        position: relative
        height: 43px
        padding: 0px 30px
        display: flex
        justify-content: center
        align-items: center
        background-color: $main
        color: $white
        font-size: r(16px)
        font-weight: 700
        text-transform: uppercase
        letter-spacing: 0.8px
        border: thin solid $main
        transition: .3s all  ease-in-out
        display: flex
        align-items: center
        img
            margin-left: 15px
            width: fit-content
            height: fit-content
            transition: .3s all  ease-in-out
        &:hover
            background-color: $main
            img
                transform: translateX(10px)
    .btn-link
        padding: 0px 20px
        height: 43px
        border: 2px solid $black
        color: $black
        font-size: r(16px)
        font-weight: 700
        line-height: 40px
        text-align: center
        text-transform: uppercase
        letter-spacing: 0.8px
        transition: .3s all  ease-in-out
        &:hover
            background-color: $main
            border: 2px solid $main
            color: $white
    .btn-contact
        width: 200px
        height: 43px
        background-color: $main
        padding: 0px 25px
        display: flex
        justify-content: center
        align-items: center
        color: $white
        font-family: "Be Vietnam"
        font-size: r(16px)
        font-weight: 700
        text-transform: uppercase
        letter-spacing: 0.8px
        transition: .3s all  ease-in-out
        &:hover
            background-color: $white
            border: thin solid $main
            color: $main
        em
            margin-right: 20px
            font-size: r(25px)
            font-weight: 400
