.arrow-button
    .swiper-button-disabled
        opacity: 1 !important
    .swiper-button-prev
        left: -60px
        &::after
            background-image: url(../img/prev-arrow.png)
        &.swiper-button-disabled
            transform: scale(0.9)
    .swiper-button-next
        right: -60px
        &::after
            background-image: url(../img/next-arrow.png)
        &.swiper-button-disabled
            transform: scale(0.9)
    .swiper-button-prev,.swiper-button-next
        transition: .3s all  ease-in-out
        top: 50%
        height: 40px
        width: 40px
        border: thin solid #999999
        border-radius: 100%
        &::after
            transition: .3s all  ease-in-out
            filter: contrast(0)
            position: absolute
            width: 25px
            height: 12px
            content: ''
            background-repeat: no-repeat
            background-position: center
        &:hover
            border: thin solid $main
            background-color: $main
            &::after
                filter: contrast(1) brightness(10)
    &.trans-hover
        .swiper-button-prev,.swiper-button-next
            &:hover
                border: thin solid $main
                background-color: $tran
                &::after
                    filter: contrast(1)
    @media screen and ( max-width: 1280px )
        display: flex
        justify-content: space-between
        align-items: center
        padding-top: 30px
        position: relative
        .swiper-button-prev,.swiper-button-next
            position: static
