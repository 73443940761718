.news-list
    .col-lg-6,.col-xl-3,.col-12
        margin-bottom: 30px
        .col-12
            &:last-child
                margin-bottom: 0px
            @media screen and ( max-width: 576px )
                &:nth-of-type(odd),&:nth-of-type(even)
                    .img
                        order: 1
                    .box-content
                        order: 2
.news-item
    width: 100%
    height: 100%
    position: relative
    cursor: pointer
    @media screen and ( max-width: 576px )
        height: unset
    &:hover
        .img
            img
                transform: scale(1.05)
    .img
        overflow: hidden
        width: 100%
        height: 100%
        a
            width: 100%
            height: 100% !important
        img
            transition: .3s all  ease-in-out
    &.big
        .box-content
            height: 300px
            @media screen and ( max-width: 768px )
                height: unset

        .img
            a
                +img-ratio(600/600)
        @media screen and ( max-width: 768px )
            .img
                height: unset
                a
                    padding-top: 60%

    &.small
        display: flex
        justify-content: center
        flex-direction: column
        .img
            a
                +img-ratio(260/285)
        .box-content
            height: 205px
            .title
                -webkit-line-clamp: 3 !important
                height: r(70.2px)
                @media screen and ( max-width: 768px )
                    height: unset
                    -webkit-line-clamp: unset !important

        &.horizontal
            display: flex
            align-items: center
            justify-content: flex-start
            flex-direction: row
            &:nth-of-type(oven),&:nth-of-type(even)
            @media screen and ( max-width: 576px )
                flex-direction: column
                .box-content
                    width: 100%
            .box-content
                height: unset
                @media screen and ( min-width: 576px )
                    height: 100%
                @media screen and ( min-width: 1024px )
                    height: 285px
            .img
                flex: 50%
                a
                    +img-ratio(285/300)
            .box-content
                flex: 50%
                .title
                    -webkit-line-clamp: 5 !important
                    height: unset !important
                    @media screen and ( max-width: 768px )
                        -webkit-line-clamp: unset !important
    .box-content
        display: block
        background-color: #f8f8f8
        display: flex
        justify-content: space-between
        flex-direction: column
        padding: 15px
        @media screen and ( min-width: 425px )
            padding: 30px

        &.box-inner
            @media screen and ( min-width: 768px )
                position: absolute
                bottom: -1px
                left: -1px
                width: 50%

        &:hover
            .wrapper
                .date,.title
                    color: $main
        .wrapper
            .date
                color: $text
                font-size: r(14px)
                font-weight: 400
                position: relative
                padding-bottom: 5px
                transition: .3s all  ease-in-out
                pointer-events: none
                &::before
                    position: absolute
                    content: ''
                    width: 30px
                    height: 2px
                    background-color: $main
                    top: 100%
                    left: 0
            .title
                transition: .3s all  ease-in-out
                color: $black
                font-size: r(18px)
                font-weight: 700
                margin-top: 20px
                max-width: 210px
                display: -webkit-box
                -webkit-line-clamp: 5
                -webkit-box-orient: vertical
                overflow: hidden
                pointer-events: none
                line-height: 1.3
                @media screen and ( max-width: 1024px )
                    max-width: unset !important
        .link
            margin-top: 20px
            display: block
