.block_s-2
	.product-wrapper
		margin-bottom: 30px
		&:hover
			.big-content-product
				background-color: rgba(0,0,0,0.9) !important
				color: #fff
		.img
			width: 100%
			position: relative
			&::after
				content: ''
				display: block
				width: 100%
				padding-bottom: 100%
			img
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				object-fit: cover
			.big-content-product
				position: absolute
				bottom: 0
				background-color: rgba(255,255,255,0.9)
				width: 100%
				height: r(60px)
				font-size: 1rem
				display: flex
				justify-content: center
				align-items: center
				text-transform: uppercase
				font-weight: 700
				transition: 0.2s all
				span
					padding-left: r(20px)
					color: $main
