.pages-project-detail-sec-2
    min-height: 300px
    @media screen and ( min-width:1280px  )
        min-height: 585px

    &.section
        @media screen and ( min-width: 1280px )
            padding-bottom: 80px

    .project-detail-slide-2
        position: relative
