.pages-product-detial-1
    .info-wrapper
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.03)
        background-color: $white
        display: flex
        flex-wrap: wrap
        .box-img
            flex: 0 0 470px
            max-width: 470px
            padding: 40px 30px
            border-right: thin solid #e1e1e1
            @media screen and ( max-width: 1279.98px )
                flex: 0 0 50%
                max-width: 50%
            @media screen and ( max-width:1023.98px )
                flex: 0 0 100%
                max-width: 100%
            @media screen and ( max-width:768px )
                padding: 15px
            .top-slide
                position: relative
                .img
                    +img-ratio(358/358)
                    @media screen and ( max-width:1023.98px )
                        padding-top: 60%
                        img
                            object-fit: contain
                .swiper-button-next
                    right: 0px
                .swiper-button-prev
                    left: 0px
            .thumbs-slide
                margin-top: 20px
                .swiper-slide
                    border: thin solid #e1e1e1
                    background-color: $white
                    padding: 10px
                    .img
                        +img-ratio(70/70,contain)
                    &.swiper-slide-thumb-active
                        border: thin solid $main
        .box-content
            flex: 0 0 475px
            max-width: 475px
            padding: 30px
            padding-top: 25px
            border-right: thin solid #e1e1e1
            @media screen and ( max-width: 1279.98px )
                flex: 0 0 50%
                max-width: 50%
            @media screen and ( max-width:1023.98px )
                flex: 0 0 100%
                max-width: 100%
                padding-top: 0px
                padding-bottom: 10px
            @media screen and ( max-width:768px )
                padding: 15px
                padding-top: 0px
                padding-bottom: 10px
            .main-title
                padding-bottom: 25px
                border-bottom: thin solid #e1e1e1
                line-height: 1.3
                @media screen and ( max-width:1023.98px )
                    padding-bottom: 10px
            .content
                margin-top: 15px
                .table
                    margin-top: 10px
                    border-bottom: thin solid  #e1e1e1
                    padding-bottom: 5px
                    table
                        width: 100%
                        tr
                            color: #333333
                            font-size: r(16px)
                            &:nth-of-type(odd)
                                background-color: #f3f3f3
                            th
                                font-weight: 700
                                white-space: nowrap
                                padding: 8px 0px
                                padding-left: 20px
                            td
                                font-weight: 400
                                padding-left: 10px
                .wrapper
                    margin-top: 30px
                    display: flex
                    align-items: center
                    @media screen and ( max-width:1023.98px )
                        margin-top: 20px
                    .social-icons
                        margin-left: 30px
                        display: flex
                        justify-content: center
                        align-items: center
                        .icon
                            width: 40px
                            height: 40px
                            border: thin solid #999999
                            display: flex
                            justify-content: center
                            align-items: center
                            transition: .3s all  ease-in-out
                            border-radius: 100%
                            @media screen and ( max-width: 768px )
                                width: r(30px)
                                height: r(30px)
                            &:hover
                                border: thin solid $main
                                background-color: $white
                                em
                                    color: $main
                            & + .icon
                                margin-left: 20px
                                @media screen and ( max-width: 1024px )
                                    margin-left: 10px

                            em
                                color: #999999
                                transition: .3s all  ease-in-out
                                font-size: r(20px)
                                @media screen and ( max-width: 768px )
                                    font-size: r(16px)

        .box-last
            padding: 10px 30px
            flex: 1
            @media screen and ( max-width: 1279.98px )
                flex: 100%
                max-width: unset
            .item
                display: flex
                padding: 20px 0px
                border-bottom: thin solid #e1e1e1
                .img
                    flex: 0 0 60px
                    height: 60px
                    border-radius: 10px
                    background-color: #f3f3f3
                    display: flex
                    justify-content: center
                    align-items: center
                    padding: 10px
                    img
                        width: 100%
                        height: 100%
                        object-fit: contain
                .content
                    margin-left: 18px
                    color: #333333
                    font-size: r(14px)
                    font-weight: 400
                    span
                        color: $main
                        font-weight: 700
