.pages-project-detail-sec-1
	border-bottom: 2px solid #f3f3f3
	.header
		.wrapper
			margin-top: 10px
			display: flex
			justify-content: space-between
			align-items: center
			.social-icons
				display: flex
				justify-content: center
				align-items: center
				.icon
					width: 40px
					height: 40px
					border: thin solid #999999
					display: flex
					justify-content: center
					align-items: center
					transition: .3s all ease-in-out
					border-radius: 100%
					@media screen and ( max-width: 768px )
						width: r(30px)
						height: r(30px)
					&:hover
						border: thin solid $main
						background-color: $main
						em
							color: $white
					& + .icon
						margin-left: 20px
						@media screen and ( max-width: 1024px )
							margin-left: 10px

					em
						color: #999999
						transition: .3s all ease-in-out
						font-size: r(20px)
						@media screen and ( max-width: 768px )
							font-size: r(16px)
	.body
		.wrapper
			position: relative
			.swiper-button-prev,.swiper-button-next
				transition: .3s all ease-in-out
				top: 45%
		.project-detail-top
			.img
				+img-ratio(100/188)
		.project-detail-thumbs
			.img
				+img-ratio(650/1230)
		.pages-content,.table
			max-width: 1020px
			margin: 0 auto
			margin-top: 25px
		.table
			width: 100%
			overflow: auto
			&::-webkit-scrollbar
				width: 2px
				height: 2px
			table
				width: 100%
				@media screen and ( max-width: 576px )
					width: 800px
				tr
					color: #333333
					font-size: r(18px)
					line-height: 50px
					&:nth-of-type(odd)
						background-color: #f3f3f3
					th
						font-weight: 700
						padding-left: 30px
						padding-right: 10px
						max-width: 200px
					td
						font-weight: 400
