.d-flex
	display: flex
.justify-between
	justify-content: space-between
.justify-center
	justify-content: center
.align-center
	align-items: center
.text-center
	text-align: center
.text-right
	text-align: right
.flex-wrap
	flex-wrap: wrap
.text-white
	color: #ffffff
.text-uppercase
	text-transform: uppercase
.mb-10
	margin-bottom: 10px
.mt-30
	margin-top: 30px
.mb-30
	margin-bottom: 30px
.mt-20
	margin-top: 20px
.mb-20
	margin-bottom: 20px !important
.mb-xl-30
	@media screen and ( max-width: 1280px )
		margin-bottom: 30px
.mb-lg-30
	@media screen and ( max-width: 1024px )
		margin-bottom: 30px
.mx-auto
	margin-right: auto
	margin-left: auto
.disable
	overflow: hidden
	pointer-event: none
.disablE
	overflow: hidden
	pointer-event: none
.bg-white
	background-color: $white !important
.text-nowrap
	white-space: nowrap
.search-overlay
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	background: linear-gradient(90deg,rgba(#000 ,0.7 ) 0%,rgba(#000 ,0.2 ))
	opacity: 0
	pointer-events: none
	visibility: hidden
	transition: .3s all ease-in-out
	z-index: 9999
	display: flex
	justify-content: center
	align-items: center
	.Module
		width: 100%
	&.active
		background: linear-gradient(90deg,rgba(#000 ,0.7 ) 100%,rgba(#000 ,0.5 ))
		opacity: 1
		pointer-events: visible
		visibility: visible
		transition-delay: .3s
		transition: .3s all ease-in-out
		.searchbox
			&::before
				width: 100%
				transition-delay: 0.3s
				transition: 1s all ease-in-out
				opacity: 1
				background: $white
			input
				&.searchinput
					&::placeholder
						font-weight: 900
						color: rgba(#fff ,1 )
						transition-delay: 0.3s
						transition: 1s all ease-in-out
						transform: translate(0)
						opacity: 1
			.btn-search
				transform: translate(0)
				transition-delay: 0.3s
				transition: 1s all ease-in-out
				em
					color: rgba(#fff ,1 )
					transition-delay: 0.3s
					transition: 1.5s all ease-in-out
					opacity: 1

	.searchbox
		height: 50px
		display: flex
		justify-content: center
		align-items: center
		width: 50%
		margin: 0 auto
		position: relative
		transition: .3s all ease-in-out
		@media screen and ( max-width: 1280px  )
			width: 80%
		&::before
			pointer-events: none
			width: 0%
			height: 1px
			position: absolute
			content: ''
			top: 100%
			left: 0
			background: transparent
			opacity: 0
		input
			&.searchinput
				padding: 15px
				width: 100%
				height: 100%
				background: transparent
				border: 0
				font-size: 1rem
				color: $white
				font-weight: 600
				&::placeholder
					font-weight: 900
					color: rgba(#fff ,0.5 )
					transform: translateX(30px)
					opacity: 0

		.btn-search
			border: 0
			height: auto
			background-color: transparent
            // margin-left: 20px
			transform: translateY(30px)
			em
				font-size: r(30px)
				font-weight: 400
				color: rgba(#fff ,0.5 )
				opacity: 0
				@media screen and ( max-width: 768px )
					font-size: r(23px)
.img-overlay
	position: relative
	background-color: rgba($black ,0.25 )
	overflow: hidden
	img
		transition: .3s all ease-in-out
	&::before
		position: absolute
		content: ''
		bottom: 0
		left: 0
		right: 0
		height: 30%
		z-index: 1
		pointer-events: none
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 75%)
		width: 100%
.width-1540
	max-width: 1540px
	margin: 0 auto
.zoom-img
	overflow: hidden
	&:hover
		img
			transform: scale(1.05)
	img
		transition: .3s all ease-in-out

.news-detail-page
	.pages.newsdetailpager
		display: none
