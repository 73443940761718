.global-breadcrumb
	width: 100%
	border-bottom: thin solid #d5d5d5
	.breadcrumb
		display: flex
		align-items: center
		background-color: transparent
		list-style: none
		margin-bottom: 0
		padding: 5px 0px
		overflow: hidden
		text-overflow: ellipsis
		li
			position: relative
			display: flex
			align-items: center
			white-space: nowrap

			&:first-child
				a
					font-size: 0px
					width: 20px
					height: 30px
					&:hover
						&::before
							color: $main
					&::before
						position: absolute
						content: "\EE26"
						font-family: remixicon
						color: #999999
						font-size: r(20px)
						font-weight: 400
						margin-top: 2px
						transition: .3s all  ease-in-out
			& + li
				margin-left: 8px
				padding-left: 12px
				&::before
					position: absolute
					top: 50%
					left: 0px
					transform: translateY(-50%)
					content: url(../img/next.png)
			&.active
				a
					color: $main
			a
				color: #999999
				font-size: r(14px)
				font-weight: 400
			&:hover
				a
					color: $main
