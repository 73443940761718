.pages-brand-sec-1
    @media screen and ( min-width:768px  )
        padding-top: 40px
    @media screen and ( min-width: 1280px )
        padding-bottom: 75px
        min-height: 945px
    .pages-content
        text-align: center
        max-width: 970px
        margin: 0 auto
        margin-bottom: 25px
    .img
        +img-ratio(650/1230)
