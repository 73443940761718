@keyframes navfade
	0%
		opacity: 0
		filter: blur(2px)
	100%
		opacity: 1
@keyframes spinner
	0%
		transform: rotate(0deg)
	100%
		transform: rotate(360deg)
@keyframes flash
	0%
		opacity: 0.4
	100%
		opacity: 1
@keyframes loading
	100%
		transform: translateY(100px)
@keyframes goDown
	0%
		transform: translateY(-100px)
	100%
		transform: translateY(0)
@keyframes slideUp
	0%
		opacity: 0
		transform: translateY(-50px)
	100%
		opacity: 1
		transform: translateY(0px)
