.pages-project-list
    .main-tab
        ul
            padding: 0
            display: flex
            justify-content: center
            align-items: center
            flex-wrap: wrap
            @media screen and ( max-width: 768px )
                flex-wrap: nowrap
                overflow: auto
                justify-content: flex-start
                &::-webkit-scrollbar
                    width: 2px
                    height: 2px
                li
                    white-space: nowrap
            li
                list-style: none
                display: inline-block
                color: $text
                font-size: r(16px)
                font-weight: 400
                text-align: center
                margin: r(8px) r(18px)
                @media screen and ( max-width: 768px )
                    margin: r(3px) r(12px)
    .main-content
