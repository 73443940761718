.block_s-3
	position: relative
	@media screen and ( max-width: 576px )
		background-color: #fafafa
	.img
		position: relative
		&::after
			content: ''
			display: block
			padding-bottom: 37.63%
		img
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
	.trademark-box
		position: absolute
		top: 38%
		transform: translateY(-50%)
		width: 100%
		@media screen and ( max-width: 1024px )
			position: static
			transform: none
			background-color: #fafafa
			padding-bottom: 15px
			.container
				display: flex
				justify-content: center
				align-items: center
				flex-direction: column
		.trademark
			font-size: r(36px)
			color: #333333
			font-weight: 700
			margin-bottom: r(20px)
		.readmore
			margin: unset
		@media screen and ( max-width: 576px )
			top: unset
			bottom: -3%
			display: flex
