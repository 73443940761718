.block_title
	text-align: center
	margin-bottom: r(40px)
	.title
		h1, h2, h3
			font-size: r(36px)
			font-weight: 700
			@media screen and ( max-width: 576px )
				font-size: r(25px)

	.content
		font-size: r(22px)
		@media screen and ( max-width: 576px )
			font-size: r(16px)
	.content-detail
		font-size: r(18px)
		padding: r(25px) 0
		@media screen and ( max-width: 576px )
			font-size: r(14px)
.readmore
	width: 200px
	padding: 8px 0px
	border: 2px solid #000
	color: #000
	text-transform: uppercase
	font-size: r(16px)
	font-weight: 700
	margin: 0 auto
	transition: 0.2s all
	display: flex
	justify-content: center
	align-items: center
	transition: .3s all  ease-in-out

	span
		margin-left: 20px
		font-weight: 700
		transition: .3s all  ease-in-out
	&:hover
		background-color: #000
		color: #fff
		border: 2px solid #000 !important
		span
			transform: translateX(10px)

.tab-wrapper
	.tab-item
		display: none
		&.active
			display: block
.zoom-in
	transition: .3s all
	&:hover
		img
			transform: scale(1.05)
	.img
		overflow: hidden
		img
			transition: .3s all
