.block_s-7
	.support-wrapper
		display: flex
		flex-direction: column
		align-items: center
		@media screen and ( max-width: 576px )
			margin-bottom: r(30px)
		.img
			width: r(60px)
			height: r(60px)
			margin-bottom: r(20px)
		.title
			font-size: r(24px)
			text-transform: uppercase
			font-weight: 700
			position: relative
			margin-bottom: r(40px)
			&::after
				position: absolute
				content: ''
				width: r(15px)
				height: 2px
				bottom: -r(15px)
				left: 50%
				transform: translateX(-50%)
				background-color: $main
		.content
			text-align: center
			margin-bottom: r(20px)
			p
				font-size: r(18px)
			a
				font-size: r(18px)
				color: $main
			.send
				display: flex
				align-items: center
				font-size: r(18px)
				color: $main
				font-weight: 500
				span
					padding-left: r(10px)
