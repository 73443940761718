.pages-product-detial-3
    &.section
        @media screen and ( min-width: 768px )
            padding-top: 30px
    .product-slide
        position: relative
        padding: 10px 0px
        .product-item
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.03)
            border: 1px solid #f3f3f3
            background-color: $white
