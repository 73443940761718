footer
	background-color: #fafafa
	padding-top: r(60px)
	.footer_logo
		width: r(90px)
		margin-bottom: r(30px)
	.title_footer
		font-size: r(16px)
		font-weight: 700
		text-transform: uppercase
		margin-bottom: r(30px)
		position: relative
		&::after
			position: absolute
			content: ''
			width: r(15px)
			height: 2px
			background-color: $main
			bottom: -5px
			left: 0
	.content_footer
		font-size: r(14px)
		color: #666666
		margin-bottom: r(12px)
		padding-right: 80px
		line-height: 1.3
		a
			font-size: r(14px)
			color: #666666
			margin-bottom: r(15px)
			&:hover
				color: $main
	.list-icon
		margin-top: r(25px)
		ul
			display: flex
			list-style: none
			li
				&+ li
					margin-left: 10px
				a
					width: r(30px)
					display: flex
					justify-content: center
					align-items: center
					height: r(30px)
					color: #666666
					border: 2px solid #666666
					border-radius: 50%
					font-size: r(14px)
					transition: .2s all
					&:hover
						color: $main
						border: 2px solid $main
	.registered
		width: r(180px)
		margin-top: r(20px)
	.copyright
		text-align: center
		font-size: r(14px)
		color: #999999
		padding-top: r(50px)
		padding-bottom: r(50.5px)
