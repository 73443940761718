@mixin center
    display: flex
    justify-content: center
    align-items: center

@mixin hover
    transition: all .2s
    position: relative
    &:after
        position: absolute
        bottom: -0.2rem
        left: 0
        right: 0
        width: 0%
        content: ''
        color: transparent
        background: $main
        height: 0.2rem
        transition: all .2s
    &:hover
        color: $main
        &:after
            width: 100%
