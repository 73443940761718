#page-banner
    .img
        width: 100%
        height: 100%
        img
            width: 100%
            height: 100%
            object-fit: cover
            min-height: 600px
            @media screen and ( max-width: 1024px )
                min-height: 50vh
