.apply-frm
    padding: 15px
    width: 100%
    h4
        font-size: 1rem
        font-weight: bold
        margin-bottom: 10px
    #ctl00_phMain_jobApply_upContact
        width: 100%
    .row
        div
            width: 100%
    .wrapper
        display: flex
        justify-content: center
        align-items: center
        flex-wrap: wrap
        .col-left
            padding-left: 0px
        .col-right
            padding-right: 0px
        .col-left,.col-right
            flex: 0 0 50%
            @media screen and ( max-width: 1024px )
                flex: 100%
                padding: 0px
    .form-group
        input
            width: 100%
            border: 0
            outline: 0
            height: 35px
            border-radius: 5px
            background-color: #f9f9f9
            padding: 0px 15px
        label
            display: block
            padding: 5px 0px
        span
            color: $main
            margin-left: 2px
        textarea
            height: 173px
            width: 100%
            border-radius: 5px
            border: 0
            background-color: #f7f7f7
            padding: 0px 15px
        .RadUpload
            height: auto
            span
                height: 100%
                .ruButton
                    width: 110px
                    height: 35px
                    background-color: $main !important
                    color: white
                    display: flex
                    align-items: center
                    justify-content: center
                    margin-top: 5px
                    font-size: 1rem
                    cursor: pointer
                    text-transform: uppercase

        .frm-btn
            position: relative
            &:hover
            input
                display: flex
                align-items: center
                justify-content: center
                background-color: $main!important
                transition: 0.3s all ease
                width: 140px!important
                height: 40px!important
                border-radius: 4px
                background-color: #e92431
                color: $white
                font-size: r(15px)
                font-weight: 600
                text-transform: uppercase
                &:hover
                    background-color: $main
                    color: $white
    .frm-btnwrap
