@import src/components/_core/_mixin.sass
.middle-fullwidth
	width: 100%
	margin: 0 auto
	.form-group
		&.forget
			input
				display: none
			label
				border: 0
				width: fit-content
	.uk-container
		width: 100%
		padding-right: 15px
		padding-left: 15px
		margin-right: auto
		margin-left: auto
		@media (min-width: 576px)
			max-width: 540px

		@media (min-width: 768px)
			max-width: 720px

		@media (min-width: 992px)
			max-width: 960px

		@media (min-width: 1200px)
			max-width: 1260px !important

		@media screen and ( min-width: 1920px)
			max-width: 1260px !important
.wrap-register
	padding: 60px 0px
	@media screen and ( max-width: 768px )
		padding: 30px 0px
	.signup-panel
		width: 100%
		border: thin solid #ccc
		border-radius: 10px
		padding: 15px
		.signup-form
			width: 100%
			.createuserbutton
				border-radius: 5px
				background: #56856a
				padding: 15px 60px
				width: fit-content
				color: #fff!important
				font-size: 1rem
				line-height: 100%
				text-transform: uppercase
.form-horizontal
	.riStrengthBarL0
		display: none
	.form-group
		position: relative
		margin-bottom: 20px
		.fa-exclamation-triangle
			position: absolute
			padding-top: 2px
			font-size: r(10px)
			font-weight: 600
			top: 100%
			left: 0px
			color: $main
		label
			margin-bottom: 5px
		input,textarea
			width: 100%
			height: 40px
			border: thin solid #ced4da !important
			border-radius: 5px
		span

		.settingcontrol
			input.form-control
				height: 40px
		.settingrow
			select
				height: 40px
				transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
				border: 1px solid #ced4da
				border-radius: .25rem
				background-clip: padding-box
				background-color: #fff
				padding: .375rem .75rem
				width: 100%
				height: 40px
				color: #495057
				font-size: 1rem
				font-weight: 400
				line-height: 1.5
