.block_s-1
	.block_title
		margin: 45px 0px
		@media screen and ( max-width: 1280px  )
			margin: r(30px) 0
		.title
			position: relative
			span
				color: $main
				letter-spacing: 1.8px
			&::after
				content: ''
				position: absolute
				background-image: url("../img/s-1/title.png")
				background-repeat: no-repeat
				background-size: cover
				width: r(46px)
				height: r(18px)
				top: -30px
				left: 50%
				transform: translateX(-50%)
	.system-wrapper
		margin-bottom: 30px
		.img
			width: 100%
			position: relative
			&::after
				content: ''
				display: block
				width: 100%
				padding-bottom: 68.33%
			&::before
				content: ''
				position: absolute
				bottom: 0
				width: 100%
				height: 30%
				background: linear-gradient(to bottom,rgba(0,0,0,.01),#1b1b1b)
				z-index: 1
				pointer-events: none
			img
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				object-fit: cover
			&:hover
				.content-system
					span
						&:first-child
							transform: translate(0)
						&:last-child
							opacity: 1
							pointer-events: visible
							visibility: visible
							transform: translate(0)
			.content-system
				position: absolute
				z-index: 2
				bottom: 15px
				left: 0
				right: 0
				padding: 0px r(30px)
				color: #fff
				font-size: 1rem
				font-weight: 700
				transition: .3s all ease-in-out
				> *
					pointer-events: none
				@media screen and ( max-width: 768px )
					padding: 0px 15px
				&.big
					font-size: r(24px)
					@media screen and ( max-width: 575px )
						font-size: 1rem
				span
					&:first-child
						transform: translateY(5px)
						transition: .3s all ease-in-out
						display: block
					&:last-child
						opacity: 0
						pointer-events: none
						visibility: hidden
						transition: .3s all ease-in-out
						transform: translateY(30px)
			.see-solution
				font-size: r(14px)
				font-weight: 400
				display: flex
				align-items: center
				em
					padding-left: r(8px)
				&:hover
					color: $main
	.readmore
		margin-top: r(10px)
