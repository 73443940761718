.pages-brand-sec-5
	&.section
		padding-bottom: 0px
	.brand-list
		.brand-item
			padding: 38px 0px
			padding-bottom: 0px
			&:nth-of-type(odd)
				background-color: #ffffff
			&:nth-of-type(even)
				background-color: #f8f8f8
			.container
				display: flex
				justify-content: space-between
				width: 100%
				flex-wrap: wrap
			.title
				flex: 0 0 420px
				color: $black
				font-size: r(18px)
				font-weight: 700
				text-transform: uppercase
				letter-spacing: 0.9px
				@media screen and ( max-width: 1280px )
					margin-bottom: 30px
					flex: 100%
			ul
				display: flex
				align-items: center
				flex-wrap: wrap
				flex: 0 0 810px
				padding: 0px
				margin: 0 -22.5px
				@media screen and ( max-width: 1280px )
					flex: 100%
				li
					list-style: none
					flex: 0 0 240px
					margin-bottom: 40px
					padding: 0 22.5px

					@media screen and ( max-width: 576px )
						flex: 100%
					a
						color: #333333
						font-weight: 400
						padding-bottom: 10px
						border-bottom: thin solid #e1e1e1
						transition: .3s all ease-in-out
						display: flex
						justify-content: space-between
						align-items: center
						span
							font-size: r(16px)
							letter-spacing: 0.8px
						em
							font-size: r(20px)
					&:hover
						a
							font-weight: 700
							color: $main
							border-bottom: thin solid $main
