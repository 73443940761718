.pages-recruit-detail
	.left-side
		.header
			display: flex
			justify-content: space-between
			align-items: center
			padding-bottom: 10px
			border-bottom: thin solid #d5d5d5
			.main-title
				color: black
				font-size: r(30px)
				font-weight: 700
			.social-icon
				display: flex
				align-items: center
				margin-left: 20px
				.icon
					width: 32px
					height: 32px
					background-color: $white
					display: flex
					justify-content: center
					align-items: center
					border-radius: 100%
					font-size: r(18px)
					font-weight: 400
					line-height: 20px
					transition: .3s all ease-in-out
					border: thin solid #999999
					em
						transition: .3s all ease-in-out
						color: #999999
					&:hover
						background-color: $main
						border: thin solid $tran
						em
							color: $white
					& +  .icon
						margin-left: 10px
		.body
			margin-top: 30px
			.sec-1
				display: flex
				box-shadow: 0px 0px 20px rgba(#000 ,0.1 )
				@media screen and ( max-width: 1024px )
					flex-direction: column
					.box-img
						.img
							padding-top: 60% !important
					.box-content
						padding: 30px !important
				@media screen and ( max-width: 576px )
					.box-img
						.img
							padding-top: 113.92405% !important
					.box-content
						padding: 15px 10px !important
				.box-img
					width: 100%
					height: 100%
					flex: 0 0 316px
					.img
						+img-ratio(360/316)
				.box-content
					padding: 30px
					padding-left: 40px
					flex: 1
					.title
						color: #721918
						font-size: r(24px)
						font-weight: 700
						text-transform: uppercase
					table
						width: 100%
						margin-top: 10px
						tbody

							tr
								display: flex
								justify-content: space-between
								padding: 3px 0px
								th
									color: $text
									font-size: r(16px)
									font-weight: 600
									flex: 0 0 50%
									white-space: nowrap
									@media screen and ( max-width: 320px )
										flex: 0 0 155px
									span
										width: 19px
										margin-right: 15px
										text-align: center
										display: inline-block
										@media screen and ( max-width: 576px )
											margin-right: 5px

										img
											width: fit-content
								td
									flex: 0 0 50%
									padding-left: 5px
									color: $text
									font-size: r(16px)
									font-weight: 500
									@media screen and ( max-width: 320px )
										flex: 1
			.sec-2
				.content
					h3
						color: $main
						font-size: r(24px)
						font-weight: 700
						text-transform: uppercase
						margin-top: 36px
					ul
						padding-left: 15px
						margin-top: 20px
						li
							color: black
							font-size: r(16px)
							font-weight: 500
							& + li
								margin-top: 15px
							span
								font-weight: 600
					.title
						color: $main
						font-size: r(25px)
						font-weight: 700
						text-transform: uppercase
						margin-top: 20px
				.button
					display: flex
					align-items: center
					margin-top: 20px
					flex-wrap: wrap
					.btn
						margin: 10px 0px
					span
						color: $black
						font-size: r(16px)
						font-weight: 400
						text-align: center
						letter-spacing: 0.8px
						margin: 0px 20px
	.col-xl-3
		@media screen and ( max-width: 1280px )
			margin-top: 30px

	.right-side
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.03)
		border: 1px solid #e1e1e1
		background-color: $white
		.rs-title
			color: $white
			font-size: r(18px)
			display: flex
			align-items: center
			padding: 0px 15px
			height: 50px
			background-color: $main
			font-weight: 700
			text-transform: uppercase
		.recruit-list
			margin-top: 5px
			.recruit-item
				transition: .3s all ease-in-out
				padding: 15px
				border-bottom: thin solid #ccc
				&:hover
					.title
						color: $main

				.title
					color: #111111
					font-size: r(18px)
					font-weight: 700
					transition: .3s all ease-in-out

				.wrapper
					display: flex
					justify-content: space-between
					align-items: center
					flex-wrap: wrap
					*
						font-weight: 400
						color: $text
						transition: .3s all ease-in-out
						font-size: r(15px)
					p
						margin-left: 5px
					.address,.money
						display: flex
						align-items: center
					*
						white-space: nowrap
					.money
						*
							color: $orange
					em
						font-size: r(16px)
						color: #999999
					.link
						a
							transition: .3s all ease-in-out
