.pages-brand-sec-3
    min-height: 610px
    &.section
        @media screen and ( min-width: 1280px )
            padding: 80px 0px
    .wrapper
        display: flex
        justify-content: space-between
        .box-img
            flex: 0 0 735px
        .box-content
            margin-top: 15px
            flex: 0 0 420px
        @media screen and ( max-width: 1280px )
            flex-direction: column
            .box-img,.box-content
                flex: 1
    .box-img
        .img
            +img-ratio(450/735)
