.pages-title
    color: #333333
    font-size: r(36px)
    margin-bottom: 10px
    font-weight: 700
    line-height: 1.3
    transition: .3s all  ease-in-out
    @media screen and ( max-width: 768px )
        font-size: r(25px)
    &.letspace
        letter-spacing: 1.8px

    span
        color: $main
    em
        display: block
        font-style: normal
.pages-content
    color: #333333
    font-size: r(18px)
    font-weight: 400
    &.big
        font-size: r(22px)
    * + *
        margin-top: 20px
    span
        color: $main
        font-weight: 700
    em
        display: block
        font-style: normal
.main-title
    color: $black
    font-size: r(30px)
    font-weight: 700
    text-align: left
    letter-spacing: 1.5px
    &.let-none
        letter-spacing: none
    @media screen and ( max-width: 768px )
        font-size: r(22px)
.text-hover
    position: relative
    padding-bottom: 2px
    &::after
        position: absolute
        content: ''
        width: 0%
        height: 2px
        background-color: #ec1f27
        transition: .3s all  ease-in-out
        top: 100%
        left: 50%
        transform: translateX(-50%)
    &:hover,&.active
        &::after
            width: 100%
.see-more
    color: $main
    font-size: r(14px)
    font-weight: 700
    letter-spacing: 0.7px
    transition: .3s all  ease-in-out
    display: flex
    align-items: center
    em
        color: $main
        font-size: r(15px)
        margin-left: 10px
        font-weight: 400
        transition: .3s all  ease-in-out
        transform: translateY(3px)
    &:hover
        em
            transform: translate(5px,3px) scale(0.8)
