.project-item
    position: relative
    &:hover
        .content
            letter-spacing: r(1.5px)
    .img
        a
            +img-ratio(390/390)
    .content
        position: absolute
        bottom: 30px
        left: 30px
        color: $white
        font-size: r(16px)
        width: 100%
        z-index: 2
        -size: 16px
        font-weight: 700
        text-transform: uppercase
        line-height: r(27px)
        transition: .3s all  ease-in-out
