.pages-recruit-list
    &.section
        @media screen and ( min-width: 1280px )
            padding-bottom: 75px
            padding-top: 70px

    .row
        margin-top: 25px
        margin-bottom: 30px
        .col-lg-6
            @media screen and ( min-width: 1280px )
                display: flex
                &:nth-of-type(odd)
                    justify-content: flex-start
                &:nth-of-type(even)
                    justify-content: flex-end

    .item
        border-top: thin solid transparent
        border-bottom: thin solid #d7d7d7
        padding: 15px 20px
        transition: .3s all  ease-in-out
        @media screen and ( min-width: 1280px )
            flex: 0 0 580px
        &:hover
            border-bottom: thin solid $tran
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.05)
            background-color: $white
            .link
                a
                    em
                        transform: translateX(5px)
            .title
                *
                    color: $main
        *
            font-weight: 400
            color: $text
            transition: .3s all  ease-in-out
        em
            font-size: r(16px)
            color: #999999
        p
            margin-left: 5px
            font-size: r(16px)
        .title
            transition: .3s all  ease-in-out
            *
                color: #111111
                font-size: r(18px)
                font-weight: 600
                transition: .3s all  ease-in-out
        .address,.money,.date
            display: flex
            align-items: center
        .wrapper
            display: flex
            justify-content: space-between
            align-items: center
            @media screen and ( max-width: 576px )
                flex-wrap: wrap
            *
                white-space: nowrap
            .money
                margin-right: 5px
                *
                    color: $orange
            .date
                margin-right: 5px
                margin-top: 0px
                color: $text
                font-size: r(16px)
                letter-spacing: 0px
                font-weight: 400
            .link
                em
                    font-family: remixicon
                    font-size: 18px
                    font-weight: 400
                    margin-left: 5px
                    color: $main
                    transition: .3s all  ease-in-out
                a
                    transition: .3s all  ease-in-out
                    display: flex
                    align-items: center
                    font-size: r(14px)
                    font-weight: 700
                    letter-spacing: 0.7px
                    color: $main
