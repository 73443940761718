.block_s-6
	&.section
		padding-bottom: 0px
	.block_title-news
		display: flex
		justify-content: space-between
		padding-bottom: r(30px)
		h2
			font-size: r(36px)
		.readall
			font-size: 1rem
			text-transform: uppercase
			display: flex
			align-items: center
			padding-top: r(10px)
			font-weight: 700
			transition: 0.2s all
			span
				padding-left: r(20px)
				font-size: r(20px)
				font-weight: 700
			&:hover
				color: $main

	.big-news-wrapper
		position: relative
		margin-bottom: 30px
		@media screen and ( max-width: 576px )
			margin-bottom: 58%
		&::after
			content: ''
			display: block
			width: 100%
			padding-bottom: 100%
		.img
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			img
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				object-fit: cover
		.content-news
			position: absolute
			bottom: 0
			left: 0
			width: 50%
			height: 50%
			background-color: #f8f8f8
			@media screen and ( max-width: 576px )
				width: 100%
				bottom: -50%

	.news-wrapper
		position: relative
		margin-bottom: 30px
		@media screen and ( max-width: 576px )
			margin-bottom: 58%
		&::after
			content: ''
			display: block
			width: 100%
			padding-bottom: 47.5%
			@media screen and ( max-width: 576px )
				padding-bottom: 100%
		.img
			position: absolute
			top: 0
			left: 0
			width: 50%
			height: 100%
			@media screen and ( max-width: 576px )
				width: 100%
			img
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				object-fit: cover
		.content-news
			position: absolute
			top: 0
			right: 0
			width: 50%
			height: 100%
			background-color: #f8f8f8
			@media screen and ( max-width: 576px )
				width: 100%
				height: 50%
				top: unset
				bottom: -50%
				right: unset
				left: 0
	.content-news
		padding: r(30px)
		display: flex
		flex-direction: column
		justify-content: space-between
		.body-content
			time
				display: block
				font-size: r(14px)
				color: #666666
				margin-bottom: r(30px)
				position: relative
				&::after
					content: ''
					position: absolute
					left: 0
					bottom: -1rem
					width: r(30px)
					height: 2px
					background-color: $main
			.link-news
				font-size: r(20px)
				color: #111111
				font-weight: 700
				-webkit-box-orient: vertical
				-webkit-line-clamp: 5
				display: -webkit-box
				overflow: hidden
				text-overflow: ellipsis
				transition: 0.2s all
				&:hover
					color: $main
		.read-more
			display: flex
			align-items: center
			font-size: r(14px)
			color: $main
			font-weight: 700
			width: max-content
			@include hover
			span
				padding-left: r(10px)
				font-size: r(18px)
	.row
		@media screen and ( min-width: 576px )
			.col-lg-6
				&:nth-child(2)
					.news-wrapper
						&:nth-child(2)
							.content-news
								left: 0
								right: unset
							.img
								left: unset
								right: 0
