.block_tool
	position: fixed
	bottom: r(50px)
	right: r(30px)
	z-index: 10
	transition: 0.2s all
	@media screen and ( max-width: 1024px )
		bottom: 10%
		right: 2%
	ul
		list-style: none
		li
			&.back-to-top
				opacity: 0
				visibility: hidden
				transition: .3s all  ease-in-out
				transform: translateY(10px)
				a
					background-color: #fff
					color: #999999
					font-size: r(34px)
					border: thin solid #ececec
					transition: 0.2s all
					@media screen and ( max-width: 1280px )
						font-size: r(27px)
					@media screen and ( max-width: 768px )
						font-size: r(20px)

					&:hover
						border: thin solid $main
						color: $main
				&.active
					opacity: 1
					visibility: visible
					transform: translate(0)
			a
				width: r(60px)
				display: flex
				justify-content: center
				align-items: center
				height: r(60px)
				font-size: r(48px)
				background-color: $main
				color: #fff
				border-radius: 50%
				margin-top: 10px
				font-size: r(34px)
				transition: 0.2s all
				@media screen and ( max-width: 1280px )
					width: r(50px)
					height: r(50px)
					font-size: r(27px)
					margin-top: 5px
				@media screen and ( max-width: 576px )
					width: 30px
					height: 30px
					font-size: r(20px)

	&.active
		opacity: 0
		visibility: hidden
		transition: .3s all ease-in-out
