.pages-brand-sec-8
    min-height: 680px
    .note
        color: #333333
        font-size: r(16px)
        font-weight: 400
    .contact-info
        display: flex
        align-items: flex-start
        margin-top: 30px
        flex-wrap: wrap
        .item
            @media screen and ( max-width: 1024px )
                &:nth-of-type(1)
                    flex: 0 0 100%
                &:nth-of-type(2),&:nth-of-type(3)
                    flex: 0 0 50%
                    padding: 10px 0px
            & + .item
                margin-left: 20px
                @media screen and ( max-width: 1024px )
                    margin-left: 0px
            .header
                display: flex
                align-items: center
                font-weight: 400
                em
                    color: #cccccc
                    font-size: r(24px)
                p
                    margin-left: 10px
                    color: #6d6e70
                    font-size: r(16px)
            .main
                margin-top: 5px
                margin-left: 35px
                .wrapper
                    display: flex
                    align-items: center
                    color: #333333
                    font-size: r(15px)
                    font-weight: 400
                    a
                    span
                        color: $main
                        margin-left: 3px
    .wrap-form
        #ctl00_mainContent_ctl03_pnlQuestions
            display: flex
            flex-wrap: wrap
            margin: 0px -10px
            margin-top: 15px
        .form-group
            margin-bottom: 20px
            padding: 0px 10px
            position: relative
            &:not(:nth-of-type(5))
                flex: 0 0 50%
            &:nth-of-type(5)
                flex: 100%
                margin-left: 0px
            @media screen and ( max-width: 768px )
                flex: 100%  !important
            input,textarea
                width: 100%
                height: 50px
                border: 1px solid #d7d7d7
                background-color: $white
                outline: none
                color: #999999
                font-size: r(16px)
                font-weight: 400
                padding: 0px 20px
                transition: .3s all  ease-in-out
                &::placeholder
                    color: #999999
                    font-size: r(16px)
                    font-weight: 400
                &:focus,&:active
                    border: thin solid $main
            span
                font-size: r(12px)
                color: $main
                display: block
                padding: 5px 0px
            textarea
                height: 100px
                resize: none
            &.frm-btnwrap
                .frm-btn
                    .frm-btn-reset
                        display: none
                    .frm-btn-submit
                        position: relative
                        width: 210px
                        height: 50px
                        display: flex
                        justify-content: center
                        align-items: center
                        background-color: $main
                        color: $white
                        font-size: r(16px)
                        font-weight: 700
                        text-transform: uppercase
                        letter-spacing: 0.8px
                        border: thin solid $main
                        background: url(../img/submit_arrow.png) no-repeat right 20px center
                        transition: .3s all  ease-in-out
                        &:hover
                            background-color: $main
                            background-position: right 10px center
