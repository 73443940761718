.pages-news-detail
    &.section
        padding-bottom: 30px
        @media screen and ( min-width: 1024px )
            padding-top: 45px

    .article-wrapper
        max-width: 1020px
        margin: 0 auto
        padding: 0 15px
    .article-head
        padding-bottom: 20px
        border-bottom: thin solid  #d5d5d5
        .title
            color: #0a0a0a
            font-size: r(30px)
            font-weight: 700
        .wrapper
            margin-top: 10px
            display: flex
            justify-content: space-between
            align-items: center
            .date
                color: $text
                font-size: r(14px)
                font-weight: 400
                letter-spacing: 0.7px
            .social-icons
                display: flex
                justify-content: center
                align-items: center
                .icon
                    width: 40px
                    height: 40px
                    border: thin solid #999999
                    display: flex
                    justify-content: center
                    align-items: center
                    transition: .3s all  ease-in-out
                    border-radius: 100%
                    @media screen and ( max-width: 768px )
                        width: r(30px)
                        height: r(30px)
                    &:hover
                        border: thin solid $main
                        background-color: $main
                        em
                            color: $white
                    & + .icon
                        margin-left: 20px
                        @media screen and ( max-width: 1024px )
                            margin-left: 10px

                    em
                        color: #999999
                        transition: .3s all  ease-in-out
                        font-size: r(20px)
                        @media screen and ( max-width: 768px )
                            font-size: r(16px)

    .article-body
        padding-bottom: 40px
        border-bottom: thin solid #d5d5d5
        .content
            *
                color: #333333
                font-size: r(16px)
                font-weight: 400
                line-height: 1.5
                letter-spacing: 0.4px
                margin-top: r(20px)

            strong
                font-weight: 700
            table
                max-width: 1230px
                margin: 0 auto
                margin-top: r(20px)
                tr
                    &:nth-of-type(odd)
                        background-color: #f5f5f5
                    td
                        width: 50%
                        &:first-child
                            font-weight: 600
                            padding-left: 10px
                        &:last-child
                            padding-left: 10px
                            padding-right: 10px
            .row
                .col-lg-6
                    margin-top: 0px
                    @media screen and ( min-width: 1024px )
                        &:last-child
                            img
                                margin-top: 0px

                    &:first-child
                        img
                            margin-top: 0px
.news-relate
    .relate-title
        color: #0a0a0a
        font-size: r(30px)
        margin-bottom: 20px
        font-weight: 700
    .news-relate-slide
        position: relative
    &.section
        padding-top: 0px
        @media screen and ( min-width: 1280px )
            padding-bottom: 80px
.product-detail-page
    #page-banner
        display: none
