.tab-panel,.nav-brand
    transition: .3s all  ease-in-out
    &.tab-panel
        padding-top: 40px
        padding-bottom: 30px
        @media screen and ( max-width: 1024px )
            padding: 30px 0px
    &.nav-brand
        background-color: #f3f3f3
        nav
            width: 100%
            ul
                padding-top: 5px
                @media screen and ( min-width: 1280px )
                    padding: 0px
                li
                    height: 40px
                    background: $tran
                    padding: 0px 18px
                    width: 100%
                    font-size: r(15px)
                    @media screen and ( max-width: 576px )
                        width: fit-content
                    &:hover,&.active
                        color: $white
                        background-color: $main
                        .text-hover
                            &::before
                                width: 100%
                    & + li
                        margin-left: 0px
    nav
        display: flex
        justify-content: center
        align-items: center
        ul
            padding: 0
            display: flex
            align-items: center
            overflow-x: auto
            padding-bottom: 3px
            &::-webkit-scrollbar-track
                box-shadow: inset 0 0 6px rgba(255, 255, 255, 0)
                background-color: #cccccc
            &::-webkit-scrollbar
                width: 2px
                height: 2px
            &::-webkit-scrollbar-thumb
                border-radius: 20px
                box-shadow: inset 0 0 6px rgba(255, 255, 255, 0)
                background-color: $main
            li
                list-style: none
                font-size: r(16px)
                background-color: $white
                display: flex
                justify-content: center
                align-items: center
                transition: .3s all  ease-in-out
                cursor: pointer
                color: $text
                font-weight: 400
                white-space: nowrap
                & + li
                    margin-left: 30px
                &:hover
                    color: $main
                &.active
                    color: $main
                    .text-hover
                        &::before
                            width: 100%
.pages-news-list
    &.section
        padding-top: 0px
    .col-lg-6
        &:nth-of-type(even)
            .col-12
                &:first-child
                    .img
                        order: 1
                    .box-content
                        order: 2
                &:last-child
                    .img
                        order: 2
                    .box-content
                        order: 1
#sticky-wrapper
    &.is-sticky
        .nav-brand
            padding: 2px 0px
            animation: 0.5s goDown
            &::before
                width: 150%
                height: 100%
                top: 0
                left: 50%
                transform: translateX(-50%)
                background-color: #f3f3f3
                position: absolute
                content: ''
                box-shadow: 0px 5px 10px rgba(0,0,0,.1)
                z-index: -1
.brand__page
    .nav-brand
        nav
            ul
                width: 100%
                justify-content: space-between
