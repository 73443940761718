body {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  background-color: #cccccc; }

::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
  background-color: #ed1c24; }

::selection {
  background-color: #ed1c24;
  color: white; }

* {
  outline: none; }

.container {
  padding-right: 30px;
  padding-left: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }

@media (min-width: 280px) {
  .container {
    max-width: 500px;
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 576px) {
  .container {
    max-width: 540px;
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 1024px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1280px) {
  .container {
    max-width: 1260px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }

.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.no-gutters > *,
.no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }

[class*="col-"] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1024px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1280px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

html {
  font-family: 'Be Vietnam', sans-serif;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: 'Be Vietnam', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  background-color: #ffffff;
  color: #111111;
  margin-top: 0;
  margin-bottom: 0;
  user-select: none; }

body, html {
  font-size: 14px; }
  @media (min-width: 1024px) {
    body, html {
      font-size: 15px; } }
  @media (min-width: 1280px) {
    body, html {
      font-size: 16px; } }

* {
  box-sizing: border-box; }
  *::before, *::after {
    box-sizing: border-box; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0;
  margin-left: 0; }

blockquote {
  margin: 0; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  text-decoration: none;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  color: inherit; }

a:not([href]):not([tabindex]) {
  text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

button:focus {
  outline: none;
  outline: 5px auto -webkit-focus-ring-color; }

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

select {
  word-wrap: normal; }

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden], .d-none, .hidden {
  display: none !important; }

input:focus, button:focus, a:focus, .button-prev:focus, .button-next:focus, textarea:focus, select:focus {
  outline: none; }

img {
  max-width: 100%;
  width: 100%;
  height: auto; }

@keyframes navfade {
  0% {
    opacity: 0;
    filter: blur(2px); }
  100% {
    opacity: 1; } }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes flash {
  0% {
    opacity: 0.4; }
  100% {
    opacity: 1; } }

@keyframes loading {
  100% {
    transform: translateY(100px); } }

@keyframes goDown {
  0% {
    transform: translateY(-100px); }
  100% {
    transform: translateY(0); } }

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(-50px); }
  100% {
    opacity: 1;
    transform: translateY(0px); } }

.button .btn-submit {
  position: relative;
  height: 43px;
  padding: 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ed1c24;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  border: thin solid #ed1c24;
  transition: .3s all  ease-in-out;
  display: flex;
  align-items: center; }
  .button .btn-submit img {
    margin-left: 15px;
    width: fit-content;
    height: fit-content;
    transition: .3s all  ease-in-out; }
  .button .btn-submit:hover {
    background-color: #ed1c24; }
    .button .btn-submit:hover img {
      transform: translateX(10px); }

.button .btn-link {
  padding: 0px 20px;
  height: 43px;
  border: 2px solid #111111;
  color: #111111;
  font-size: 1rem;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  transition: .3s all  ease-in-out; }
  .button .btn-link:hover {
    background-color: #ed1c24;
    border: 2px solid #ed1c24;
    color: #ffffff; }

.button .btn-contact {
  width: 200px;
  height: 43px;
  background-color: #ed1c24;
  padding: 0px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: "Be Vietnam";
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  transition: .3s all  ease-in-out; }
  .button .btn-contact:hover {
    background-color: #ffffff;
    border: thin solid #ed1c24;
    color: #ed1c24; }
  .button .btn-contact em {
    margin-right: 20px;
    font-size: 1.5625rem;
    font-weight: 400; }

.block_title {
  text-align: center;
  margin-bottom: 2.5rem; }
  .block_title .title h1, .block_title .title h2, .block_title .title h3 {
    font-size: 2.25rem;
    font-weight: 700; }
    @media screen and (max-width: 576px) {
      .block_title .title h1, .block_title .title h2, .block_title .title h3 {
        font-size: 1.5625rem; } }
  .block_title .content {
    font-size: 1.375rem; }
    @media screen and (max-width: 576px) {
      .block_title .content {
        font-size: 1rem; } }
  .block_title .content-detail {
    font-size: 1.125rem;
    padding: 1.5625rem 0; }
    @media screen and (max-width: 576px) {
      .block_title .content-detail {
        font-size: 0.875rem; } }

.readmore {
  width: 200px;
  padding: 8px 0px;
  border: 2px solid #000;
  color: #000;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  margin: 0 auto;
  transition: 0.2s all;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s all  ease-in-out; }
  .readmore span {
    margin-left: 20px;
    font-weight: 700;
    transition: .3s all  ease-in-out; }
  .readmore:hover {
    background-color: #000;
    color: #fff;
    border: 2px solid #000 !important; }
    .readmore:hover span {
      transform: translateX(10px); }

.tab-wrapper .tab-item {
  display: none; }
  .tab-wrapper .tab-item.active {
    display: block; }

.zoom-in {
  transition: .3s all; }
  .zoom-in:hover img {
    transform: scale(1.05); }
  .zoom-in .img {
    overflow: hidden; }
    .zoom-in .img img {
      transition: .3s all; }

.product-item {
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
  border: thin solid #f3f3f3;
  position: relative;
  transition: .3s all  ease-in-out;
  overflow: hidden; }
  .product-item:hover {
    border: thin solid #ed1c24;
    border-bottom: 0px solid transparent; }
    .product-item:hover .img img {
      transform: scale(1.05); }
    .product-item:hover .fake-content {
      opacity: 0;
      pointer-events: none;
      visibility: hidden; }
      .product-item:hover .fake-content .title {
        animation: 0.5s loading;
        animation-delay: 0.2s; }
      .product-item:hover .fake-content .content {
        animation: 0.5s loading;
        animation-delay: 0.1s; }
    .product-item:hover .overlay-content {
      transition-delay: 0.3s;
      opacity: 1;
      pointer-events: visible;
      visibility: visible; }
  .product-item .img {
    overflow: hidden; }
    .product-item .img a {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 91.22807%; }
      .product-item .img a img, .product-item .img a iframe, .product-item .img a video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: contain;
        transition: .3s ease-in-out all; }
  .product-item .fake-content {
    transition: .3s all  ease-in-out;
    padding: 20px;
    border-top: thin solid #f3f3f3;
    pointer-events: none; }
    .product-item .fake-content .title {
      color: #111111;
      font-size: 1rem;
      font-weight: 700;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      transition: .3s all  ease-in-out;
      pointer-events: none; }
    .product-item .fake-content .content {
      margin-top: 5px;
      color: #ed1c24;
      color: #ed1c24;
      font-family: "Be Vietnam";
      font-size: 0.875rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      transition: .3s all  ease-in-out;
      pointer-events: none; }
      .product-item .fake-content .content em {
        font-family: "Material Icons";
        font-size: 18px;
        margin-right: 5px; }
  .product-item .overlay-content {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    position: absolute;
    transition: .3s all  ease-in-out;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    overflow: auto; }
    .product-item .overlay-content::-webkit-scrollbar {
      width: 3px;
      height: 3px; }
    .product-item .overlay-content .head-info {
      padding: 0px 13px;
      padding-right: 4px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%; }
      .product-item .overlay-content .head-info .wrapper {
        padding-right: 8px;
        width: 100%;
        overflow: auto; }
        .product-item .overlay-content .head-info .wrapper::-webkit-scrollbar {
          width: 0px;
          height: 0px; }
      .product-item .overlay-content .head-info .title {
        color: #ffffff;
        font-size: 1rem;
        font-weight: 700;
        text-decoration: underline; }
      .product-item .overlay-content .head-info table {
        width: 100%; }
        .product-item .overlay-content .head-info table tr {
          color: #ffffff;
          font-size: 0.75rem;
          line-height: 1.875rem;
          font-weight: 400; }
          .product-item .overlay-content .head-info table tr th {
            font-weight: 700;
            white-space: nowrap; }
          .product-item .overlay-content .head-info table tr td {
            margin-left: 10px;
            display: block; }
    .product-item .overlay-content .body-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
      .product-item .overlay-content .body-info .link {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 50%;
        color: #ed1c24;
        font-size: 0.875rem;
        font-weight: 400;
        height: 65px;
        background-color: #ffffff;
        transition: .3s all  ease-in-out; }
        @media screen and (max-width: 768px) {
          .product-item .overlay-content .body-info .link {
            height: 50px; } }
        .product-item .overlay-content .body-info .link em {
          color: #ed1c24;
          font-size: 1.125rem;
          font-weight: 400;
          margin-right: 5px;
          transition: .3s all  ease-in-out; }
        .product-item .overlay-content .body-info .link:hover {
          background-color: #ed1c24;
          color: #ffffff; }
          .product-item .overlay-content .body-info .link:hover em {
            color: #ffffff; }

.news-list .col-lg-6, .news-list .col-xl-3, .news-list .col-12 {
  margin-bottom: 30px; }
  .news-list .col-lg-6 .col-12:last-child, .news-list .col-xl-3 .col-12:last-child, .news-list .col-12 .col-12:last-child {
    margin-bottom: 0px; }
  @media screen and (max-width: 576px) {
    .news-list .col-lg-6 .col-12:nth-of-type(odd) .img, .news-list .col-lg-6 .col-12:nth-of-type(even) .img, .news-list .col-xl-3 .col-12:nth-of-type(odd) .img, .news-list .col-xl-3 .col-12:nth-of-type(even) .img, .news-list .col-12 .col-12:nth-of-type(odd) .img, .news-list .col-12 .col-12:nth-of-type(even) .img {
      order: 1; }
    .news-list .col-lg-6 .col-12:nth-of-type(odd) .box-content, .news-list .col-lg-6 .col-12:nth-of-type(even) .box-content, .news-list .col-xl-3 .col-12:nth-of-type(odd) .box-content, .news-list .col-xl-3 .col-12:nth-of-type(even) .box-content, .news-list .col-12 .col-12:nth-of-type(odd) .box-content, .news-list .col-12 .col-12:nth-of-type(even) .box-content {
      order: 2; } }

.news-item {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer; }
  @media screen and (max-width: 576px) {
    .news-item {
      height: unset; } }
  .news-item:hover .img img {
    transform: scale(1.05); }
  .news-item .img {
    overflow: hidden;
    width: 100%;
    height: 100%; }
    .news-item .img a {
      width: 100%;
      height: 100% !important; }
    .news-item .img img {
      transition: .3s all  ease-in-out; }
  .news-item.big .box-content {
    height: 300px; }
    @media screen and (max-width: 768px) {
      .news-item.big .box-content {
        height: unset; } }
  .news-item.big .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%; }
    .news-item.big .img a img, .news-item.big .img a iframe, .news-item.big .img a video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  @media screen and (max-width: 768px) {
    .news-item.big .img {
      height: unset; }
      .news-item.big .img a {
        padding-top: 60%; } }
  .news-item.small {
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .news-item.small .img a {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 91.22807%; }
      .news-item.small .img a img, .news-item.small .img a iframe, .news-item.small .img a video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
    .news-item.small .box-content {
      height: 205px; }
      .news-item.small .box-content .title {
        -webkit-line-clamp: 3 !important;
        height: 4.3875rem; }
        @media screen and (max-width: 768px) {
          .news-item.small .box-content .title {
            height: unset;
            -webkit-line-clamp: unset !important; } }
    .news-item.small.horizontal {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row; }
      @media screen and (max-width: 576px) {
        .news-item.small.horizontal {
          flex-direction: column; }
          .news-item.small.horizontal .box-content {
            width: 100%; } }
      .news-item.small.horizontal .box-content {
        height: unset; }
        @media screen and (min-width: 576px) {
          .news-item.small.horizontal .box-content {
            height: 100%; } }
        @media screen and (min-width: 1024px) {
          .news-item.small.horizontal .box-content {
            height: 285px; } }
      .news-item.small.horizontal .img {
        flex: 50%; }
        .news-item.small.horizontal .img a {
          position: relative;
          display: block;
          height: 0;
          overflow: hidden;
          padding-top: 95%; }
          .news-item.small.horizontal .img a img, .news-item.small.horizontal .img a iframe, .news-item.small.horizontal .img a video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            transition: .3s ease-in-out all; }
      .news-item.small.horizontal .box-content {
        flex: 50%; }
        .news-item.small.horizontal .box-content .title {
          -webkit-line-clamp: 5 !important;
          height: unset !important; }
          @media screen and (max-width: 768px) {
            .news-item.small.horizontal .box-content .title {
              -webkit-line-clamp: unset !important; } }
  .news-item .box-content {
    display: block;
    background-color: #f8f8f8;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 15px; }
    @media screen and (min-width: 425px) {
      .news-item .box-content {
        padding: 30px; } }
    @media screen and (min-width: 768px) {
      .news-item .box-content.box-inner {
        position: absolute;
        bottom: -1px;
        left: -1px;
        width: 50%; } }
    .news-item .box-content:hover .wrapper .date, .news-item .box-content:hover .wrapper .title {
      color: #ed1c24; }
    .news-item .box-content .wrapper .date {
      color: #666666;
      font-size: 0.875rem;
      font-weight: 400;
      position: relative;
      padding-bottom: 5px;
      transition: .3s all  ease-in-out;
      pointer-events: none; }
      .news-item .box-content .wrapper .date::before {
        position: absolute;
        content: '';
        width: 30px;
        height: 2px;
        background-color: #ed1c24;
        top: 100%;
        left: 0; }
    .news-item .box-content .wrapper .title {
      transition: .3s all  ease-in-out;
      color: #111111;
      font-size: 1.125rem;
      font-weight: 700;
      margin-top: 20px;
      max-width: 210px;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      pointer-events: none;
      line-height: 1.3; }
      @media screen and (max-width: 1024px) {
        .news-item .box-content .wrapper .title {
          max-width: unset !important; } }
    .news-item .box-content .link {
      margin-top: 20px;
      display: block; }

.apply-frm {
  padding: 15px;
  width: 100%; }
  .apply-frm h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px; }
  .apply-frm #ctl00_phMain_jobApply_upContact {
    width: 100%; }
  .apply-frm .row div {
    width: 100%; }
  .apply-frm .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    .apply-frm .wrapper .col-left {
      padding-left: 0px; }
    .apply-frm .wrapper .col-right {
      padding-right: 0px; }
    .apply-frm .wrapper .col-left, .apply-frm .wrapper .col-right {
      flex: 0 0 50%; }
      @media screen and (max-width: 1024px) {
        .apply-frm .wrapper .col-left, .apply-frm .wrapper .col-right {
          flex: 100%;
          padding: 0px; } }
  .apply-frm .form-group input {
    width: 100%;
    border: 0;
    outline: 0;
    height: 35px;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 0px 15px; }
  .apply-frm .form-group label {
    display: block;
    padding: 5px 0px; }
  .apply-frm .form-group span {
    color: #ed1c24;
    margin-left: 2px; }
  .apply-frm .form-group textarea {
    height: 173px;
    width: 100%;
    border-radius: 5px;
    border: 0;
    background-color: #f7f7f7;
    padding: 0px 15px; }
  .apply-frm .form-group .RadUpload {
    height: auto; }
    .apply-frm .form-group .RadUpload span {
      height: 100%; }
      .apply-frm .form-group .RadUpload span .ruButton {
        width: 110px;
        height: 35px;
        background-color: #ed1c24 !important;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        font-size: 1rem;
        cursor: pointer;
        text-transform: uppercase; }
  .apply-frm .form-group .frm-btn {
    position: relative; }
    .apply-frm .form-group .frm-btn input {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ed1c24 !important;
      transition: 0.3s all ease;
      width: 140px !important;
      height: 40px !important;
      border-radius: 4px;
      background-color: #e92431;
      color: #ffffff;
      font-size: 0.9375rem;
      font-weight: 600;
      text-transform: uppercase; }
      .apply-frm .form-group .frm-btn input:hover {
        background-color: #ed1c24;
        color: #ffffff; }

.project-item {
  position: relative; }
  .project-item:hover .content {
    letter-spacing: 0.09375rem; }
  .project-item .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%; }
    .project-item .img a img, .project-item .img a iframe, .project-item .img a video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .project-item .content {
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: #ffffff;
    font-size: 1rem;
    width: 100%;
    z-index: 2;
    -size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.6875rem;
    transition: .3s all  ease-in-out; }

.section {
  padding: 35px 0; }
  @media (min-width: 768px) {
    .section {
      padding: 40px 0; } }
  @media (min-width: 1024px) {
    .section {
      padding: 45px 0; } }
  @media (min-width: 1280px) {
    .section {
      padding: 60px 0px; } }

.arrow-button .swiper-button-disabled {
  opacity: 1 !important; }

.arrow-button .swiper-button-prev {
  left: -60px; }
  .arrow-button .swiper-button-prev::after {
    background-image: url(../img/prev-arrow.png); }
  .arrow-button .swiper-button-prev.swiper-button-disabled {
    transform: scale(0.9); }

.arrow-button .swiper-button-next {
  right: -60px; }
  .arrow-button .swiper-button-next::after {
    background-image: url(../img/next-arrow.png); }
  .arrow-button .swiper-button-next.swiper-button-disabled {
    transform: scale(0.9); }

.arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
  transition: .3s all  ease-in-out;
  top: 50%;
  height: 40px;
  width: 40px;
  border: thin solid #999999;
  border-radius: 100%; }
  .arrow-button .swiper-button-prev::after, .arrow-button .swiper-button-next::after {
    transition: .3s all  ease-in-out;
    filter: contrast(0);
    position: absolute;
    width: 25px;
    height: 12px;
    content: '';
    background-repeat: no-repeat;
    background-position: center; }
  .arrow-button .swiper-button-prev:hover, .arrow-button .swiper-button-next:hover {
    border: thin solid #ed1c24;
    background-color: #ed1c24; }
    .arrow-button .swiper-button-prev:hover::after, .arrow-button .swiper-button-next:hover::after {
      filter: contrast(1) brightness(10); }

.arrow-button.trans-hover .swiper-button-prev:hover, .arrow-button.trans-hover .swiper-button-next:hover {
  border: thin solid #ed1c24;
  background-color: transparent; }
  .arrow-button.trans-hover .swiper-button-prev:hover::after, .arrow-button.trans-hover .swiper-button-next:hover::after {
    filter: contrast(1); }

@media screen and (max-width: 1280px) {
  .arrow-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    position: relative; }
    .arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
      position: static; } }

.pages-title {
  color: #333333;
  font-size: 2.25rem;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.3;
  transition: .3s all  ease-in-out; }
  @media screen and (max-width: 768px) {
    .pages-title {
      font-size: 1.5625rem; } }
  .pages-title.letspace {
    letter-spacing: 1.8px; }
  .pages-title span {
    color: #ed1c24; }
  .pages-title em {
    display: block;
    font-style: normal; }

.pages-content {
  color: #333333;
  font-size: 1.125rem;
  font-weight: 400; }
  .pages-content.big {
    font-size: 1.375rem; }
  .pages-content * + * {
    margin-top: 20px; }
  .pages-content span {
    color: #ed1c24;
    font-weight: 700; }
  .pages-content em {
    display: block;
    font-style: normal; }

.main-title {
  color: #111111;
  font-size: 1.875rem;
  font-weight: 700;
  text-align: left;
  letter-spacing: 1.5px; }
  .main-title.let-none {
    letter-spacing: none; }
  @media screen and (max-width: 768px) {
    .main-title {
      font-size: 1.375rem; } }

.text-hover {
  position: relative;
  padding-bottom: 2px; }
  .text-hover::after {
    position: absolute;
    content: '';
    width: 0%;
    height: 2px;
    background-color: #ec1f27;
    transition: .3s all  ease-in-out;
    top: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .text-hover:hover::after, .text-hover.active::after {
    width: 100%; }

.see-more {
  color: #ed1c24;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.7px;
  transition: .3s all  ease-in-out;
  display: flex;
  align-items: center; }
  .see-more em {
    color: #ed1c24;
    font-size: 0.9375rem;
    margin-left: 10px;
    font-weight: 400;
    transition: .3s all  ease-in-out;
    transform: translateY(3px); }
  .see-more:hover em {
    transform: translate(5px, 3px) scale(0.8); }

.d-flex {
  display: flex; }

.justify-between {
  justify-content: space-between; }

.justify-center {
  justify-content: center; }

.align-center {
  align-items: center; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.flex-wrap {
  flex-wrap: wrap; }

.text-white {
  color: #ffffff; }

.text-uppercase {
  text-transform: uppercase; }

.mb-10 {
  margin-bottom: 10px; }

.mt-30 {
  margin-top: 30px; }

.mb-30 {
  margin-bottom: 30px; }

.mt-20 {
  margin-top: 20px; }

.mb-20 {
  margin-bottom: 20px !important; }

@media screen and (max-width: 1280px) {
  .mb-xl-30 {
    margin-bottom: 30px; } }

@media screen and (max-width: 1024px) {
  .mb-lg-30 {
    margin-bottom: 30px; } }

.mx-auto {
  margin-right: auto;
  margin-left: auto; }

.disable {
  overflow: hidden;
  pointer-event: none; }

.disablE {
  overflow: hidden;
  pointer-event: none; }

.bg-white {
  background-color: #ffffff !important; }

.text-nowrap {
  white-space: nowrap; }

.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2));
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: .3s all ease-in-out;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center; }
  .search-overlay .Module {
    width: 100%; }
  .search-overlay.active {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 100%, rgba(0, 0, 0, 0.5));
    opacity: 1;
    pointer-events: visible;
    visibility: visible;
    transition-delay: .3s;
    transition: .3s all ease-in-out; }
    .search-overlay.active .searchbox::before {
      width: 100%;
      transition-delay: 0.3s;
      transition: 1s all ease-in-out;
      opacity: 1;
      background: #ffffff; }
    .search-overlay.active .searchbox input.searchinput::placeholder {
      font-weight: 900;
      color: white;
      transition-delay: 0.3s;
      transition: 1s all ease-in-out;
      transform: translate(0);
      opacity: 1; }
    .search-overlay.active .searchbox .btn-search {
      transform: translate(0);
      transition-delay: 0.3s;
      transition: 1s all ease-in-out; }
      .search-overlay.active .searchbox .btn-search em {
        color: white;
        transition-delay: 0.3s;
        transition: 1.5s all ease-in-out;
        opacity: 1; }
  .search-overlay .searchbox {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 0 auto;
    position: relative;
    transition: .3s all ease-in-out; }
    @media screen and (max-width: 1280px) {
      .search-overlay .searchbox {
        width: 80%; } }
    .search-overlay .searchbox::before {
      pointer-events: none;
      width: 0%;
      height: 1px;
      position: absolute;
      content: '';
      top: 100%;
      left: 0;
      background: transparent;
      opacity: 0; }
    .search-overlay .searchbox input.searchinput {
      padding: 15px;
      width: 100%;
      height: 100%;
      background: transparent;
      border: 0;
      font-size: 1rem;
      color: #ffffff;
      font-weight: 600; }
      .search-overlay .searchbox input.searchinput::placeholder {
        font-weight: 900;
        color: rgba(255, 255, 255, 0.5);
        transform: translateX(30px);
        opacity: 0; }
    .search-overlay .searchbox .btn-search {
      border: 0;
      height: auto;
      background-color: transparent;
      transform: translateY(30px); }
      .search-overlay .searchbox .btn-search em {
        font-size: 1.875rem;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        opacity: 0; }
        @media screen and (max-width: 768px) {
          .search-overlay .searchbox .btn-search em {
            font-size: 1.4375rem; } }

.img-overlay {
  position: relative;
  background-color: rgba(17, 17, 17, 0.25);
  overflow: hidden; }
  .img-overlay img {
    transition: .3s all ease-in-out; }
  .img-overlay::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
    z-index: 1;
    pointer-events: none;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 75%);
    width: 100%; }

.width-1540 {
  max-width: 1540px;
  margin: 0 auto; }

.zoom-img {
  overflow: hidden; }
  .zoom-img:hover img {
    transform: scale(1.05); }
  .zoom-img img {
    transition: .3s all ease-in-out; }

.news-detail-page .pages.newsdetailpager {
  display: none; }

footer {
  background-color: #fafafa;
  padding-top: 3.75rem; }
  footer .footer_logo {
    width: 5.625rem;
    margin-bottom: 1.875rem; }
  footer .title_footer {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.875rem;
    position: relative; }
    footer .title_footer::after {
      position: absolute;
      content: '';
      width: 0.9375rem;
      height: 2px;
      background-color: #ed1c24;
      bottom: -5px;
      left: 0; }
  footer .content_footer {
    font-size: 0.875rem;
    color: #666666;
    margin-bottom: 0.75rem;
    padding-right: 80px;
    line-height: 1.3; }
    footer .content_footer a {
      font-size: 0.875rem;
      color: #666666;
      margin-bottom: 0.9375rem; }
      footer .content_footer a:hover {
        color: #ed1c24; }
  footer .list-icon {
    margin-top: 1.5625rem; }
    footer .list-icon ul {
      display: flex;
      list-style: none; }
      footer .list-icon ul li + li {
        margin-left: 10px; }
      footer .list-icon ul li a {
        width: 1.875rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.875rem;
        color: #666666;
        border: 2px solid #666666;
        border-radius: 50%;
        font-size: 0.875rem;
        transition: .2s all; }
        footer .list-icon ul li a:hover {
          color: #ed1c24;
          border: 2px solid #ed1c24; }
  footer .registered {
    width: 11.25rem;
    margin-top: 1.25rem; }
  footer .copyright {
    text-align: center;
    font-size: 0.875rem;
    color: #999999;
    padding-top: 3.125rem;
    padding-bottom: 3.15625rem; }

header {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  transition: .3s all ease-in-out; }
  header.header-scroll {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25); }
  header.disable_bsd {
    box-shadow: none !important; }
  @media screen and (max-width: 1280px) {
    header {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25); } }
  header ul li {
    list-style: none; }
  header .header {
    height: 5.625rem;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    header .header .header_logo .logo {
      width: 6.25rem; }
    header .header .header_main .header_menu {
      display: flex;
      align-items: center; }
      @media screen and (max-width: 1280px) {
        header .header .header_main .header_menu .menu {
          display: none; } }
      header .header .header_main .header_menu .language-box {
        position: relative;
        margin-right: 2.1875rem;
        margin-left: 2.5rem; }
        header .header .header_main .header_menu .language-box::after {
          position: absolute;
          content: '\ea4d';
          font-family: "remixicon";
          font-size: 1.25rem;
          top: -5px;
          right: -20px; }
        header .header .header_main .header_menu .language-box:hover::after {
          color: #ed1c24; }
        header .header .header_main .header_menu .language-box:hover .vn ul {
          opacity: 1;
          pointer-events: visible;
          visibility: visible;
          transform: translate(0); }
        header .header .header_main .header_menu .language-box:hover::before {
          pointer-events: visible; }
        header .header .header_main .header_menu .language-box::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 30px;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          pointer-events: none; }
        header .header .header_main .header_menu .language-box .vn {
          position: relative; }
          header .header .header_main .header_menu .language-box .vn .img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            overflow: hidden; }
            header .header .header_main .header_menu .language-box .vn .img img {
              display: block; }
          header .header .header_main .header_menu .language-box .vn ul {
            position: absolute;
            left: -1rem;
            padding: 1rem;
            background-color: #fff;
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
            transition: .3s all ease-in-out;
            transform: translateY(30px);
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
            margin-top: 5px; }
      header .header .header_main .header_menu .search-icon {
        cursor: pointer; }
        header .header .header_main .header_menu .search-icon span {
          font-size: 1.25rem; }
          header .header .header_main .header_menu .search-icon span:hover {
            color: #ed1c24; }
      header .header .header_main .header_menu .header_btn {
        width: 2.5rem;
        height: 2.5rem;
        margin-left: 1.25rem;
        background-color: #ed1c24;
        color: #ffffff;
        cursor: pointer;
        font-size: 1.25rem;
        display: none; }
        @media screen and (max-width: 1280px) {
          header .header .header_main .header_menu .header_btn {
            display: flex;
            justify-content: center;
            align-items: center; } }
  header .menu {
    display: flex; }
    @media screen and (max-width: 1280px) {
      header .menu {
        display: block;
        width: 70%; } }
    header .menu li.home a {
      width: 20px;
      display: block;
      text-align: center;
      margin: 0 auto; }
    header .menu li + li {
      margin-left: 2.5rem; }
    @media screen and (max-width: 1280px) {
      header .menu li {
        text-align: center;
        padding: 10px 0px; }
        header .menu li + li {
          margin-left: 0px; } }
    header .menu li a {
      font-size: 1rem;
      font-weight: 500;
      transition: all .2s;
      position: relative; }
    header .menu li.active a.text-hover {
      color: #ed1c24; }
      header .menu li.active a.text-hover:after {
        width: 100%; }
  header .has-dropdown {
    position: relative; }
    header .has-dropdown:hover::before {
      pointer-events: visible; }
    header .has-dropdown::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 30px;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      pointer-events: none; }
    header .has-dropdown:hover .dropdown-menu {
      opacity: 1;
      pointer-events: visible;
      visibility: visible;
      transform: translateY(0px); }
    header .has-dropdown .wrapper em {
      display: none; }
    @media screen and (max-width: 1280px) {
      header .has-dropdown .wrapper em {
        font-size: 1.25rem;
        position: absolute;
        transform: translateY(-50%);
        right: 0;
        top: 50%;
        z-index: 99;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #111111;
        transition: .3s all ease-in-out; }
        header .has-dropdown .wrapper em.active {
          color: #ed1c24;
          transform: translateY(-50%) rotate(180deg); } }
  header .dropdown-menu {
    position: absolute;
    left: 0;
    top: 38.5px;
    padding: 30px;
    padding-top: 25px;
    box-shadow: 2px 2px 25px rgba(4, 4, 4, 0.05);
    background-color: #ffffff;
    transform: translateX(200%);
    transition: .3s all ease-in-out; }
    @media screen and (min-width: 1281px) {
      header .dropdown-menu {
        transform: translateY(30px);
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        width: 660px; } }
    @media screen and (max-width: 1280px) {
      header .dropdown-menu {
        z-index: 99;
        top: 0;
        bottom: 0;
        position: fixed;
        padding-top: 35px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
        header .dropdown-menu .col-xl-6:last-child {
          margin-top: 2rem; } }
    header .dropdown-menu.active {
      transform: translateX(0%); }
    header .dropdown-menu .title {
      color: #111111;
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      padding-bottom: 20px;
      border-bottom: thin solid #ed1c24;
      max-width: 260px;
      width: 100%; }
    @media screen and (max-width: 1280px) {
      header .dropdown-menu .col-xl-6 {
        padding-left: 15px;
        flex: 0 0 100%;
        max-width: 100%; } }
    header .dropdown-menu ul {
      padding: 0px;
      margin-top: 18px; }
      header .dropdown-menu ul li {
        margin-left: -5px !important;
        margin-bottom: 10px; }
        header .dropdown-menu ul li:hover, header .dropdown-menu ul li.active {
          color: #ed1c24; }
        header .dropdown-menu ul li + li {
          margin-left: 0px; }
        header .dropdown-menu ul li a {
          width: 100%;
          text-align: left;
          color: #333333;
          font-size: 0.875rem;
          font-weight: 500;
          display: flex;
          transition: .3s all ease-in-out; }
          header .dropdown-menu ul li a em {
            margin-right: 10px;
            font-size: 1.125rem;
            font-weight: 400;
            margin-top: 4px; }
    @media screen and (min-width: 1281px) {
      header .dropdown-menu#support_menu, header .dropdown-menu#project_menu {
        width: 220px;
        padding: 15px 20px; }
        header .dropdown-menu#support_menu ul, header .dropdown-menu#project_menu ul {
          margin-top: 0px; }
          header .dropdown-menu#support_menu ul li:last-child, header .dropdown-menu#project_menu ul li:last-child {
            margin-bottom: -5px; } }
  header #dropdown .header_btn {
    z-index: 100; }
  header .header_infor {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1rem; }
    @media screen and (max-width: 1280px) {
      header .header_infor {
        display: none;
        padding: 2rem 0;
        flex-direction: column; } }
    header .header_infor .hotline-box {
      padding-right: 1rem;
      border-right: 1px solid #111111;
      display: flex; }
      @media screen and (max-width: 1280px) {
        header .header_infor .hotline-box {
          border: 0;
          padding-right: 0; } }
      header .header_infor .hotline-box .tel {
        font-size: 0.875rem;
        padding-right: 5px; }
      header .header_infor .hotline-box ul {
        display: flex; }
        @media screen and (max-width: 1280px) {
          header .header_infor .hotline-box ul {
            display: block; } }
        header .header_infor .hotline-box ul li {
          display: flex; }
          header .header_infor .hotline-box ul li::after {
            content: '-';
            font-size: 0.875rem;
            transform: scaleX(2);
            padding: 0 .8rem; }
            @media screen and (max-width: 1280px) {
              header .header_infor .hotline-box ul li::after {
                all: unset; } }
          header .header_infor .hotline-box ul li:last-child::after {
            all: unset; }
          header .header_infor .hotline-box ul li a {
            font-size: 0.875rem;
            color: #ed1c24;
            font-weight: 700; }
          header .header_infor .hotline-box ul li p {
            padding-left: 5px;
            font-size: 0.875rem; }
    header .header_infor .email-box {
      font-size: 0.875rem;
      padding-left: 1rem; }
      @media screen and (max-width: 1280px) {
        header .header_infor .email-box {
          padding-left: 0;
          padding-top: 1rem; } }
  header .block_search {
    width: 100%;
    height: 100%;
    background-color: rgba(237, 28, 36, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 30px;
    opacity: 0;
    pointer-events: none;
    transform: translateX(20%);
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
    header .block_search.show {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(0); }
    header .block_search .search-icon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background-color: #fff;
      font-size: 2rem;
      display: flex;
      justify-content: center;
      align-items: center; }
      header .block_search .search-icon:hover {
        color: #ed1c24; }
    header .block_search .search_box {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40rem;
      height: 4rem;
      position: relative; }
      header .block_search .search_box input {
        width: 100%;
        height: 100%;
        border: 0;
        background-color: #ffffff;
        padding-left: 1rem;
        color: #000000;
        font-size: 1rem; }
        header .block_search .search_box input::placeholder {
          color: #000000;
          font-size: 1rem;
          font-style: italic; }
      header .block_search .search_box button {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        width: 4rem;
        height: 100%;
        background-color: unset;
        font-size: 2rem;
        color: #000000; }
  header .menu_mobile {
    display: none; }
    @media screen and (max-width: 1280px) {
      header .menu_mobile {
        display: block;
        width: 50%;
        height: 100%;
        background-color: #fff;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
        padding: 15px;
        opacity: 0;
        bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
        pointer-events: none;
        transform: translateX(20%);
        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        max-width: 320px;
        width: 100%; } }
    header .menu_mobile .header_btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ed1c24;
      color: #fff;
      font-size: 2.1875rem;
      cursor: pointer; }
    header .menu_mobile .mobile-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px; }
  header .menu_mobile.show {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(0); }
  header .overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
  @media screen and (max-width: 1280px) {
    header .overlay.show {
      display: block; } }

.block_tool {
  position: fixed;
  bottom: 3.125rem;
  right: 1.875rem;
  z-index: 10;
  transition: 0.2s all; }
  @media screen and (max-width: 1024px) {
    .block_tool {
      bottom: 10%;
      right: 2%; } }
  .block_tool ul {
    list-style: none; }
    .block_tool ul li.back-to-top {
      opacity: 0;
      visibility: hidden;
      transition: .3s all  ease-in-out;
      transform: translateY(10px); }
      .block_tool ul li.back-to-top a {
        background-color: #fff;
        color: #999999;
        font-size: 2.125rem;
        border: thin solid #ececec;
        transition: 0.2s all; }
        @media screen and (max-width: 1280px) {
          .block_tool ul li.back-to-top a {
            font-size: 1.6875rem; } }
        @media screen and (max-width: 768px) {
          .block_tool ul li.back-to-top a {
            font-size: 1.25rem; } }
        .block_tool ul li.back-to-top a:hover {
          border: thin solid #ed1c24;
          color: #ed1c24; }
      .block_tool ul li.back-to-top.active {
        opacity: 1;
        visibility: visible;
        transform: translate(0); }
    .block_tool ul li a {
      width: 3.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.75rem;
      font-size: 3rem;
      background-color: #ed1c24;
      color: #fff;
      border-radius: 50%;
      margin-top: 10px;
      font-size: 2.125rem;
      transition: 0.2s all; }
      @media screen and (max-width: 1280px) {
        .block_tool ul li a {
          width: 3.125rem;
          height: 3.125rem;
          font-size: 1.6875rem;
          margin-top: 5px; } }
      @media screen and (max-width: 576px) {
        .block_tool ul li a {
          width: 30px;
          height: 30px;
          font-size: 1.25rem; } }
  .block_tool.active {
    opacity: 0;
    visibility: hidden;
    transition: .3s all ease-in-out; }

.global-breadcrumb {
  width: 100%;
  border-bottom: thin solid #d5d5d5; }
  .global-breadcrumb .breadcrumb {
    display: flex;
    align-items: center;
    background-color: transparent;
    list-style: none;
    margin-bottom: 0;
    padding: 5px 0px;
    overflow: hidden;
    text-overflow: ellipsis; }
    .global-breadcrumb .breadcrumb li {
      position: relative;
      display: flex;
      align-items: center;
      white-space: nowrap; }
      .global-breadcrumb .breadcrumb li:first-child a {
        font-size: 0px;
        width: 20px;
        height: 30px; }
        .global-breadcrumb .breadcrumb li:first-child a:hover::before {
          color: #ed1c24; }
        .global-breadcrumb .breadcrumb li:first-child a::before {
          position: absolute;
          content: "\EE26";
          font-family: remixicon;
          color: #999999;
          font-size: 1.25rem;
          font-weight: 400;
          margin-top: 2px;
          transition: .3s all  ease-in-out; }
      .global-breadcrumb .breadcrumb li + li {
        margin-left: 8px;
        padding-left: 12px; }
        .global-breadcrumb .breadcrumb li + li::before {
          position: absolute;
          top: 50%;
          left: 0px;
          transform: translateY(-50%);
          content: url(../img/next.png); }
      .global-breadcrumb .breadcrumb li.active a {
        color: #ed1c24; }
      .global-breadcrumb .breadcrumb li a {
        color: #999999;
        font-size: 0.875rem;
        font-weight: 400; }
      .global-breadcrumb .breadcrumb li:hover a {
        color: #ed1c24; }

.page-contact .item-1 {
  margin-top: 40px; }
  @media screen and (max-width: 1280px) {
    .page-contact .item-1 {
      margin-top: 20px; } }
  .page-contact .item-1 .col-lg:first-child .box .info a, .page-contact .item-1 .col-lg:first-child .box .info p {
    font-size: 0.6875rem; }
  .page-contact .item-1 .box {
    width: 100%;
    height: 144px;
    text-align: center;
    box-shadow: 0 0 21px rgba(131, 131, 131, 0.1);
    background-color: #ffffff;
    align-items: center;
    justify-items: center;
    display: flex;
    flex-direction: column;
    padding: 25px 15px;
    padding-top: 15px;
    margin-bottom: 40px; }
    @media screen and (max-width: 1280px) {
      .page-contact .item-1 .box {
        margin-bottom: 20px;
        padding-top: 20px; } }
    .page-contact .item-1 .box * {
      line-height: 1.3; }
    .page-contact .item-1 .box .title {
      color: #ed1c24;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 8.76px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 10px; }
    .page-contact .item-1 .box .info a, .page-contact .item-1 .box .info p {
      color: #001111;
      display: block;
      font-size: 0.75rem;
      font-weight: 600;
      text-align: center; }
  .page-contact .item-1 .image-box {
    margin-bottom: 10px; }

.page-contact .ModuleContent {
  widows: 100%;
  height: 100%; }

.page-contact .maps {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 89.3617%; }
  .page-contact .maps img, .page-contact .maps iframe, .page-contact .maps video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: .3s ease-in-out all; }
  @media screen and (min-width: 992px) {
    .page-contact .maps {
      height: 100% !important; }
      .page-contact .maps iframe {
        height: 630px; } }
  @media screen and (max-width: 1024px) {
    .page-contact .maps {
      padding-top: 100%; } }

.page-contact .wrap-form {
  width: 100%; }
  @media screen and (max-width: 992px) {
    .page-contact .wrap-form {
      padding-left: 0; } }

.page-contact .form-group {
  margin-bottom: 21px;
  position: relative; }
  .page-contact .form-group:last-child {
    margin-bottom: 0px; }
  .page-contact .form-group span {
    color: #ed1c24;
    position: absolute;
    padding-top: 2px;
    font-size: 0.625rem;
    font-weight: 600;
    top: 100%;
    left: 20px; }
    @media screen and (max-width: 576px) {
      .page-contact .form-group span {
        font-size: 1rem; } }

.page-contact label {
  color: black;
  color: #666666;
  font-size: 1rem;
  display: block;
  padding-bottom: 5px; }

.page-contact input, .page-contact textarea {
  width: 100%;
  height: 45px;
  border: 0px;
  background-color: #f8f8f8;
  padding: 0px 15px; }

.page-contact textarea {
  min-height: 135px;
  resize: none; }

.page-contact .frm-btnwrap label {
  font-size: 0px; }

.page-contact .frm-btnwrap .frm-btn .frm-btn-reset {
  display: none; }

.page-contact .frm-btnwrap .frm-btn .frm-btn-submit {
  margin-top: 30px;
  position: relative;
  margin-left: auto;
  width: 210px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ed1c24;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  border: thin solid #ed1c24;
  background: url(../img/submit_arrow.png) no-repeat right 20px center;
  transition: .3s all  ease-in-out; }
  .page-contact .frm-btnwrap .frm-btn .frm-btn-submit:hover {
    background-color: #ed1c24;
    background-position: right 10px center; }

@media screen and (max-width: 992px) {
  .page-contact .Module-344 {
    margin-top: 30px; } }

.pages-document .box-table {
  margin-top: 25px;
  margin-bottom: 2.5rem; }
  .pages-document .box-table table {
    width: 100%; }
    .pages-document .box-table table th {
      font-size: 1rem;
      font-weight: 400;
      padding: 13px 0px; }
      @media screen and (max-width: 1024px) {
        .pages-document .box-table table th {
          padding: 13px 5px; } }
      .pages-document .box-table table th:first-child {
        padding-left: 75px; }
        @media screen and (max-width: 1280px) {
          .pages-document .box-table table th:first-child {
            padding-left: 15px; } }
      .pages-document .box-table table th:nth-of-type(2) {
        width: 675px; }
      @media screen and (max-width: 1280px) {
        .pages-document .box-table table th:last-child {
          padding-right: 15px; } }
    .pages-document .box-table table thead {
      background-color: #ed1c24; }
      .pages-document .box-table table thead tr {
        width: 100%; }
        .pages-document .box-table table thead tr th {
          color: #ffffff;
          font-weight: 700;
          white-space: nowrap;
          margin: 0px 10px; }
    .pages-document .box-table table tbody tr {
      width: 100%; }
      .pages-document .box-table table tbody tr:nth-of-type(even) {
        background-color: #f6f6f6; }
      .pages-document .box-table table tbody tr th {
        color: #333333; }

.pages-introduce-sec-2 {
  min-height: 870px; }
  @media screen and (min-width: 1280px) {
    .pages-introduce-sec-2.section {
      padding-top: 65px; } }
  .pages-introduce-sec-2 .pages-content {
    max-width: 860px;
    margin: 0 auto; }
  .pages-introduce-sec-2 .box-gallery {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 230px 345px;
    grid-auto-flow: row;
    grid-gap: 5px; }
    @media screen and (max-width: 1280px) {
      .pages-introduce-sec-2 .box-gallery {
        grid-template-rows: 230px 230px 320px; } }
    @media screen and (max-width: 768px) {
      .pages-introduce-sec-2 .box-gallery {
        grid-template-rows: auto; } }
    @media screen and (max-width: 768px) {
      .pages-introduce-sec-2 .box-gallery {
        grid-template-columns: 1fr; } }
    .pages-introduce-sec-2 .box-gallery .box-item {
      position: relative; }
      .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(1) {
        grid-column: 1 /3;
        grid-row: 1; }
      .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(2) {
        grid-column: 3/5;
        grid-row: 1; }
      .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(3) {
        grid-column: 5/7;
        grid-row: 1; }
      .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(4) {
        grid-column: 1/4;
        grid-row: 2; }
      .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(5) {
        grid-column: 4/7;
        grid-row: 2; }
      @media screen and (max-width: 1280px) {
        .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(1) {
          grid-column: 1 /4;
          grid-row: 1; }
        .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(2) {
          grid-column: 4/7;
          grid-row: 1; }
        .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(3) {
          grid-column: 1/4;
          grid-row: 2; }
        .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(4) {
          grid-column: 4/7;
          grid-row: 2; }
        .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(5) {
          grid-column: 1/7;
          grid-row: 3; } }
      @media screen and (max-width: 768px) {
        .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(1) {
          grid-column: auto;
          grid-row: 1; }
        .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(2) {
          grid-column: auto;
          grid-row: 2; }
        .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(3) {
          grid-column: auto;
          grid-row: 3; }
        .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(4) {
          grid-column: auto;
          grid-row: 4; }
        .pages-introduce-sec-2 .box-gallery .box-item:nth-of-type(5) {
          grid-column: auto;
          grid-row: 5; } }
      .pages-introduce-sec-2 .box-gallery .box-item:hover img {
        transform: scale(1.05); }
      .pages-introduce-sec-2 .box-gallery .box-item.small .img a {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 56.51106%; }
        .pages-introduce-sec-2 .box-gallery .box-item.small .img a img, .pages-introduce-sec-2 .box-gallery .box-item.small .img a iframe, .pages-introduce-sec-2 .box-gallery .box-item.small .img a video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; }
      .pages-introduce-sec-2 .box-gallery .box-item.big .img a {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 56.37255%; }
        .pages-introduce-sec-2 .box-gallery .box-item.big .img a img, .pages-introduce-sec-2 .box-gallery .box-item.big .img a iframe, .pages-introduce-sec-2 .box-gallery .box-item.big .img a video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; }
      .pages-introduce-sec-2 .box-gallery .box-item .img {
        overflow: hidden;
        width: 100%;
        height: 100%; }
        .pages-introduce-sec-2 .box-gallery .box-item .img a {
          height: 100% !important; }
      .pages-introduce-sec-2 .box-gallery .box-item .content {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        color: #ffffff;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.5rem;
        text-align: center;
        padding: 0px 40px;
        width: 100%;
        z-index: 2; }

#page-banner .img {
  width: 100%;
  height: 100%; }
  #page-banner .img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 600px; }
    @media screen and (max-width: 1024px) {
      #page-banner .img img {
        min-height: 50vh; } }

.modulepager .pagination {
  display: flex;
  list-style-type: none;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 10px; }
  .modulepager .pagination li {
    margin: 0px 5px;
    color: #ed1c24;
    display: flex;
    justify-content: center;
    align-items: center; }
    .modulepager .pagination li:last-child {
      margin-right: 0; }
    .modulepager .pagination li a, .modulepager .pagination li span {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      transition: .2s ease-in-out all;
      overflow: hidden;
      font-size: 1.125rem;
      color: #999999;
      font-size: 1rem;
      font-weight: 400;
      border: 2px solid #999999; }
    .modulepager .pagination li.active a, .modulepager .pagination li.active span, .modulepager .pagination li:hover a, .modulepager .pagination li:hover span {
      color: #ffffff;
      background: #ed1c24;
      border: 2px solid transparent; }
    @media (min-width: 1024px) {
      .modulepager .pagination li a, .modulepager .pagination li span {
        width: 40px;
        height: 40px; } }

.account-wrapper {
  display: flex;
  align-items: center; }
  .account-wrapper em {
    color: #999999;
    font-size: 1rem;
    font-weight: 400;
    display: block; }
  .account-wrapper .wrap {
    padding-left: 10px;
    display: flex;
    align-items: center; }
    .account-wrapper .wrap ul {
      padding: 0px; }
      .account-wrapper .wrap ul li {
        color: #666666;
        font-size: 0.875rem;
        font-weight: 400;
        font-style: italic;
        white-space: nowrap;
        list-style: none;
        transition: .3s all  ease-in-out; }
        .account-wrapper .wrap ul li a::after {
          display: none; }
        .account-wrapper .wrap ul li:nth-of-type(2) {
          display: none; }
        .account-wrapper .wrap ul li:hover {
          color: #ed1c24; }
          .account-wrapper .wrap ul li:hover a {
            color: #ed1c24 !important; }
    .account-wrapper .wrap .logout {
      margin-left: 5px;
      color: #666666;
      font-size: 0.875rem;
      font-weight: 400;
      font-style: italic;
      white-space: nowrap;
      list-style: none;
      transition: .3s all  ease-in-out; }
      .account-wrapper .wrap .logout:hover {
        color: #ed1c24; }
        .account-wrapper .wrap .logout:hover a {
          color: #ed1c24 !important; }

.user-sidemenu {
  padding: 60px 0px; }
  @media screen and (max-width: 768px) {
    .user-sidemenu {
      padding: 30px 0px; } }

.checkout-title .title-wrap {
  background-color: #f1f1f1;
  border-left: 10px solid #ed1c24;
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 1rem;
  font-weight: 700;
  color: #ed1c24;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding: 12px 15px;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  width: 100%; }
  .checkout-title .title-wrap .fas {
    margin-right: 10px; }
  @media screen and (max-width: 768px) {
    .checkout-title .title-wrap {
      font-weight: 600;
      border-left: 5px solid #ed1c24; } }

.admin-content .panel {
  margin: 60px 0;
  border-radius: 5px;
  background: #fff; }
  .admin-content .panel .panel-heading h1 {
    margin-bottom: 30px;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    color: #333333; }

.admin-content .form-horizontal {
  border: thin solid #ccc;
  border-radius: 15px;
  padding: 15px; }
  .admin-content .form-horizontal .form-group label {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 5px; }
  .admin-content .form-horizontal .form-group input {
    border: thin solid #ced4da !important;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    padding: 5px 15px; }
  .admin-content .form-horizontal .form-group input[type="submit"] {
    transition: all .2s ease-in-out;
    box-shadow: none;
    border: none;
    border-radius: 5px;
    background: #ed1c24;
    min-width: 120px;
    height: 40px;
    color: #fff;
    font-size: 16px; }
  .admin-content .form-horizontal .form-group span {
    font-size: 12px;
    color: red !important;
    display: flex;
    margin-top: 5px;
    font-style: italic; }
  .admin-content .form-horizontal .RadCaptcha {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap; }
    .admin-content .form-horizontal .RadCaptcha > * {
      width: 100%; }
    .admin-content .form-horizontal .RadCaptcha span {
      order: 3; }
    .admin-content .form-horizontal .RadCaptcha > div {
      order: 1;
      margin-bottom: 5px; }
      .admin-content .form-horizontal .RadCaptcha > div > div {
        display: flex;
        align-items: center;
        margin-bottom: 10px; }
        .admin-content .form-horizontal .RadCaptcha > div > div a {
          display: inline-block;
          padding-left: 15px;
          color: #1e87f0;
          font-size: 12px; }
    .admin-content .form-horizontal .RadCaptcha input {
      order: 2; }
  @media (min-width: 1200px) {
    .admin-content .form-horizontal {
      padding: 20px; } }

.customer-info {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 15px; }
  .customer-info .form-info, .customer-info .form-same, .customer-info .bill-export {
    flex: 100%;
    padding: 15px 0px; }
  .customer-info .form-group {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap; }
    .customer-info .form-group label {
      flex: 0 0 200px;
      font-size: 16px;
      color: black;
      font-weight: 400; }
    .customer-info .form-group input, .customer-info .form-group select {
      height: 40px;
      width: 100%; }
    .customer-info .form-group input, .customer-info .form-group select, .customer-info .form-group textarea {
      flex: calc( 100% - 230px);
      margin-left: 15px;
      border: thin solid #cccccc;
      border-radius: 5px;
      padding: 0px 15px; }
    .customer-info .form-group textarea {
      min-height: 200px;
      padding: 5px 15px; }
    .customer-info .form-group span {
      flex: 100%;
      color: #ed1c24;
      font-style: italic;
      padding: 5px 0px; }
    @media screen and (max-width: 768px) {
      .customer-info .form-group label {
        flex: 0 0 125px; }
      .customer-info .form-group textarea {
        min-height: 130px; } }
    @media screen and (max-width: 425px) {
      .customer-info .form-group {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px; }
        .customer-info .form-group span {
          font-size: 14px; }
        .customer-info .form-group label {
          flex: 100%; }
        .customer-info .form-group input, .customer-info .form-group select, .customer-info .form-group textarea {
          flex: 100%;
          width: 100%;
          margin-left: 0px;
          margin-top: 5px;
          flex: none; } }
  .customer-info .form-same {
    padding-top: 0px; }
    @media screen and (max-width: 425px) {
      .customer-info .form-same {
        display: flex;
        align-content: center; }
        .customer-info .form-same label {
          margin-left: 10px; } }
    .customer-info .form-same .form-wrapper {
      padding-top: 15px;
      display: none; }
  .customer-info .bill-export .form-wrapper {
    display: none;
    padding-top: 15px; }
    .customer-info .bill-export .form-wrapper i {
      padding: 5px 0px;
      display: block; }

.term-service-wrap .qcheckbox {
  padding: 10px 15px;
  display: flex;
  align-items: center; }
  .term-service-wrap .qcheckbox #PaymentAgree {
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: thin solid #ccc; }
    .term-service-wrap .qcheckbox #PaymentAgree:checked {
      border: thin solid transparent; }
  .term-service-wrap .qcheckbox label {
    font-size: 0.875rem;
    color: black;
    font-weight: 400;
    margin-left: 15px; }

.setting-control .btn-gap {
  display: flex;
  justify-content: space-between; }

@media screen and (max-width: 991px) {
  .setting-control {
    margin: 0 auto; }
    .setting-control .btn-gap {
      display: flex;
      justify-content: space-between; } }

.setting-control .btn-gap {
  margin-top: 15px;
  display: flex;
  align-items: center; }
  .setting-control .btn-gap .btn-primary {
    transition: .3s all ease-in-out;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: fit-content;
    background: #f1f1f1;
    color: #ed1c24;
    border-radius: 5px;
    border-left: 10px solid #ed1c24; }
    @media screen and (max-width: 768px) {
      .setting-control .btn-gap .btn-primary {
        border-left: 5px solid #ed1c24; } }
    .setting-control .btn-gap .btn-primary + .btn-primary {
      margin-left: 15px; }
    .setting-control .btn-gap .btn-primary span {
      margin-left: 10px;
      font-size: 0.875rem;
      font-weight: 500;
      text-transform: uppercase;
      white-space: nowrap; }
    .setting-control .btn-gap .btn-primary.red em {
      padding-right: 0; }
    @media screen and (max-width: 375px) {
      .setting-control .btn-gap .btn-primary.gray {
        margin-bottom: .9rem; } }
    .setting-control .btn-gap .btn-primary.gray em,
    .setting-control .btn-gap .btn-primary.gray span {
      color: #333333; }
    .setting-control .btn-gap .btn-primary:hover {
      background-color: #56856a;
      color: #ffffff;
      border-left: 10px solid #56856a; }

.middle-fullwidth {
  width: 100%;
  margin: 0 auto; }
  .middle-fullwidth .form-group.forget input {
    display: none; }
  .middle-fullwidth .form-group.forget label {
    border: 0;
    width: fit-content; }
  .middle-fullwidth .uk-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
    @media (min-width: 576px) {
      .middle-fullwidth .uk-container {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .middle-fullwidth .uk-container {
        max-width: 720px; } }
    @media (min-width: 992px) {
      .middle-fullwidth .uk-container {
        max-width: 960px; } }
    @media (min-width: 1200px) {
      .middle-fullwidth .uk-container {
        max-width: 1260px !important; } }
    @media screen and (min-width: 1920px) {
      .middle-fullwidth .uk-container {
        max-width: 1260px !important; } }

.wrap-register {
  padding: 60px 0px; }
  @media screen and (max-width: 768px) {
    .wrap-register {
      padding: 30px 0px; } }
  .wrap-register .signup-panel {
    width: 100%;
    border: thin solid #ccc;
    border-radius: 10px;
    padding: 15px; }
    .wrap-register .signup-panel .signup-form {
      width: 100%; }
      .wrap-register .signup-panel .signup-form .createuserbutton {
        border-radius: 5px;
        background: #56856a;
        padding: 15px 60px;
        width: fit-content;
        color: #fff !important;
        font-size: 1rem;
        line-height: 100%;
        text-transform: uppercase; }

.form-horizontal .riStrengthBarL0 {
  display: none; }

.form-horizontal .form-group {
  position: relative;
  margin-bottom: 20px; }
  .form-horizontal .form-group .fa-exclamation-triangle {
    position: absolute;
    padding-top: 2px;
    font-size: 0.625rem;
    font-weight: 600;
    top: 100%;
    left: 0px;
    color: #ed1c24; }
  .form-horizontal .form-group label {
    margin-bottom: 5px; }
  .form-horizontal .form-group input, .form-horizontal .form-group textarea {
    width: 100%;
    height: 40px;
    border: thin solid #ced4da !important;
    border-radius: 5px; }
  .form-horizontal .form-group .settingcontrol input.form-control {
    height: 40px; }
  .form-horizontal .form-group .settingrow select {
    height: 40px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    background-clip: padding-box;
    background-color: #fff;
    padding: .375rem .75rem;
    width: 100%;
    height: 40px;
    color: #495057;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5; }

.flex-wrap {
  display: flex;
  justify-content: center; }

.login-page .login-panel {
  margin: 60px 0;
  background: #fff;
  border-radius: 5px; }
  .login-page .login-panel .sitelogo {
    text-align: center;
    margin-bottom: 40px;
    width: 100%; }
  .login-page .login-panel img {
    height: 80px; }
  .login-page .login-panel .headtitle {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 30px; }
    .login-page .login-panel .headtitle:after {
      content: '';
      margin: 10px auto; }

@media (max-width: 1199px) {
  .login-page .login-panel {
    margin: 60px 0; } }

@media (max-width: 543px) {
  .login-page .sitelogo {
    margin-bottom: 30px; } }

.login-form {
  width: 100%;
  margin: 0 auto; }
  .login-form .fa-exclamation-triangle {
    @apply absolute top-100 left-0 text-12 text-primary pt-1 {} }
  .login-form .panel-heading {
    display: none; }
  .login-form .title {
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
    border-left: 3px solid #8c0000;
    padding-left: 10px;
    margin-bottom: 30px; }
  .login-form .module-title {
    font-size: 0.875rem;
    color: #666666;
    margin-bottom: 20px; }
  .login-form a {
    color: #8c0000; }
  .login-form .form-group {
    margin-bottom: 20px;
    position: relative;
    overflow: hidden; }
    .login-form .form-group label {
      color: #999999;
      text-transform: uppercase;
      font-size: 0rem;
      display: block;
      width: 50px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      border-right: 1px solid #e6e6e6;
      @apply text-14  text-main {} }
      .login-form .form-group label .fa {
        font-size: 1.125rem;
        line-height: 40px; }
  .login-form input {
    width: 100%;
    font-size: .875rem;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    padding: 10px 30px 10px 60px;
    -webkit-transition: all, 0.5s;
    transition: all, 0.5s; }
    .login-form input::placeholder {
      font-size: 15px; }
    .login-form input:active, .login-form input:hover, .login-form input:focus {
      border-color: #999999; }
    @media screen and (max-width: 400px) {
      .login-form input {
        width: 100%; } }
  .login-form a {
    display: inline-block;
    color: #000;
    padding: 10px 0;
    margin-right: 15px; }
    .login-form a:hover {
      color: #8c0000; }
  .login-form .forget {
    font-size: 0.875rem;
    color: #999999;
    margin-bottom: 30px; }
    .login-form .forget input[type='checkbox'] {
      @apply opacity-0 relative {} }
      .login-form .forget input[type='checkbox']:checked ~ label::before {
        content: '\F0132'; }
    .login-form .forget label {
      color: #333333;
      font-weight: 300;
      width: auto;
      height: auto;
      text-align: left;
      padding-left: 30px;
      line-height: 1.5;
      font-size: 0.875rem;
      text-transform: none;
      position: relative;
      -webkit-transition: all, 0.5s;
      transition: all, 0.5s; }
      .login-form .forget label:before {
        font-family: 'Material Design Icons';
        content: '\F0131';
        font-size: 20px;
        display: block;
        line-height: 14px;
        font-size: 16px;
        color: #333333;
        position: absolute;
        top: 2px;
        left: 1px;
        -webkit-transition: all, 0.5s;
        transition: all, 0.5s; }
      .login-form .forget label:hover {
        color: #333333; }
  .login-form .btn-login {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    position: relative;
    color: #fff;
    background: #8c0000;
    display: block;
    border-radius: 5px;
    overflow: hidden; }
    .login-form .btn-login:hover::after {
      transform: translateX(3px); }
    .login-form .btn-login:before {
      background: #c61017; }
    .login-form .btn-login:after {
      transition: .3s all  ease-in-out;
      font: normal normal normal 24px/1 Material Design Icons;
      content: '\F0142';
      position: absolute;
      top: 12px;
      right: 15px; }
  .login-form input[type='reset'] {
    display: none; }
  .login-form input[type="submit"] {
    line-height: 100%;
    cursor: pointer;
    width: fit-content;
    color: #fff !important;
    background: #ed1c24;
    border-radius: 5px;
    transition: .3s all  ease-in-out;
    padding: 15px 60px;
    border: none;
    text-transform: uppercase;
    font-size: 1rem; }
    .login-form input[type="submit"]:hover {
      background-color: #ed1c24; }
  .login-form .go-signup {
    text-align: center;
    margin-top: 15px; }
    .login-form .go-signup a {
      display: block;
      clear: both;
      color: #8c0000; }
      .login-form .go-signup a:hover {
        color: #000; }

@media (max-width: 543px) {
  .login-form .btn-login {
    width: 100%; } }

.login-bg {
  width: 60%;
  position: relative;
  overflow: hidden;
  padding: 50px;
  border-radius: 5px; }
  .login-bg .login-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
    .login-bg .login-img .login-overlay {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #000;
      opacity: 0.5;
      z-index: 1; }
  .login-bg img {
    height: 100%;
    width: auto;
    max-width: unset;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }
  .login-bg .login-des {
    position: relative;
    z-index: 1;
    color: #fff; }
    .login-bg .login-des .title {
      font-size: 2.875rem;
      font-weight: 300;
      margin-bottom: 20px; }
    .login-bg .login-des .module-title {
      font-size: 1.625rem;
      font-weight: 200;
      opacity: 0.9; }
    .login-bg .login-des .des {
      margin-top: 180px;
      font-size: 1.25rem; }
      .login-bg .login-des .des span {
        opacity: 0.8;
        font-weight: 200;
        display: inline-block;
        margin-right: 10px; }
      .login-bg .login-des .des a {
        display: inline-block;
        opacity: 1;
        font-weight: 300;
        font-size: 2rem;
        color: #fff; }
        .login-bg .login-des .des a:after {
          content: '\f105';
          font-family: 'fontawesome';
          margin-left: 5px; }
        .login-bg .login-des .des a:hover {
          color: #8c0000; }

@media (max-width: 991px) {
  .login-bg {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; } }

@media (max-width: 767px) {
  .login-bg {
    padding: 30px; } }

@media (max-width: 991px) {
  .login-bg .login-img img {
    height: auto;
    width: 100%; } }

@media (max-width: 767px) {
  .login-bg .login-img img {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    -khtml-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1); } }

@media (max-width: 543px) {
  .login-bg .login-img img {
    width: auto;
    height: 100%; } }

@media (max-width: 767px) {
  .login-bg .login-des .title {
    font-size: 2.25rem;
    margin-bottom: 10px; } }

@media (max-width: 767px) {
  .login-bg .login-des .module-title {
    font-size: 1.25rem; } }

@media (max-width: 991px) {
  .login-bg .login-des .des {
    margin-top: 30px; } }

@media (max-width: 767px) {
  .login-bg .login-des .des {
    margin-top: 10px; } }

@media (max-width: 543px) {
  .login-bg .login-des .des a {
    font-size: 1.625rem; } }

.panel-primary span {
  color: #eb0c22;
  font-style: italic;
  display: block; }

.loginstandard {
  padding: 10px;
  border: thin solid #ccc;
  border-radius: 15px;
  padding-top: 2rem; }

@media screen and (min-width: 768px) {
  .pages-brand-sec-1 {
    padding-top: 40px; } }

@media screen and (min-width: 1280px) {
  .pages-brand-sec-1 {
    padding-bottom: 75px;
    min-height: 945px; } }

.pages-brand-sec-1 .pages-content {
  text-align: center;
  max-width: 970px;
  margin: 0 auto;
  margin-bottom: 25px; }

.pages-brand-sec-1 .img {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 52.84553%; }
  .pages-brand-sec-1 .img img, .pages-brand-sec-1 .img iframe, .pages-brand-sec-1 .img video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: .3s ease-in-out all; }

.pages-brand-sec-2 {
  min-height: 610px;
  background-color: #f8f8f8; }
  @media screen and (min-width: 1280px) {
    .pages-brand-sec-2.section {
      padding: 80px 0px; } }
  .pages-brand-sec-2 .wrapper {
    display: flex;
    justify-content: space-between; }
    .pages-brand-sec-2 .wrapper .box-img {
      flex: 0 0 735px; }
    .pages-brand-sec-2 .wrapper .box-content {
      margin-top: 15px;
      flex: 0 0 420px; }
    @media screen and (max-width: 1280px) {
      .pages-brand-sec-2 .wrapper {
        flex-direction: column; }
        .pages-brand-sec-2 .wrapper .box-img, .pages-brand-sec-2 .wrapper .box-content {
          flex: 1; } }
  .pages-brand-sec-2 .box-img .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 61.22449%; }
    .pages-brand-sec-2 .box-img .img img, .pages-brand-sec-2 .box-img .img iframe, .pages-brand-sec-2 .box-img .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }

.pages-brand-sec-3 {
  min-height: 610px; }
  @media screen and (min-width: 1280px) {
    .pages-brand-sec-3.section {
      padding: 80px 0px; } }
  .pages-brand-sec-3 .wrapper {
    display: flex;
    justify-content: space-between; }
    .pages-brand-sec-3 .wrapper .box-img {
      flex: 0 0 735px; }
    .pages-brand-sec-3 .wrapper .box-content {
      margin-top: 15px;
      flex: 0 0 420px; }
    @media screen and (max-width: 1280px) {
      .pages-brand-sec-3 .wrapper {
        flex-direction: column; }
        .pages-brand-sec-3 .wrapper .box-img, .pages-brand-sec-3 .wrapper .box-content {
          flex: 1; } }
  .pages-brand-sec-3 .box-img .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 61.22449%; }
    .pages-brand-sec-3 .box-img .img img, .pages-brand-sec-3 .box-img .img iframe, .pages-brand-sec-3 .box-img .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }

.pages-brand-sec-4 {
  min-height: 610px;
  background-color: #f8f8f8; }
  @media screen and (min-width: 1280px) {
    .pages-brand-sec-4.section {
      padding: 80px 0px; } }
  .pages-brand-sec-4 .wrapper {
    display: flex;
    justify-content: space-between; }
    .pages-brand-sec-4 .wrapper .box-img {
      flex: 0 0 735px; }
    .pages-brand-sec-4 .wrapper .box-content {
      margin-top: 20px;
      flex: 0 0 420px;
      height: 380px;
      overflow: auto;
      padding-right: 20px; }
      .pages-brand-sec-4 .wrapper .box-content::-webkit-scrollbar {
        width: 3px;
        height: 3px; }
    @media screen and (max-width: 1280px) {
      .pages-brand-sec-4 .wrapper {
        flex-direction: column; }
        .pages-brand-sec-4 .wrapper .box-img, .pages-brand-sec-4 .wrapper .box-content {
          flex: 1; }
        .pages-brand-sec-4 .wrapper .box-img {
          order: 1; }
        .pages-brand-sec-4 .wrapper .box-content {
          order: 2; } }
  .pages-brand-sec-4 .box-img .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 61.22449%; }
    .pages-brand-sec-4 .box-img .img img, .pages-brand-sec-4 .box-img .img iframe, .pages-brand-sec-4 .box-img .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }

.pages-brand-sec-5.section {
  padding-bottom: 0px; }

.pages-brand-sec-5 .brand-list .brand-item {
  padding: 38px 0px;
  padding-bottom: 0px; }
  .pages-brand-sec-5 .brand-list .brand-item:nth-of-type(odd) {
    background-color: #ffffff; }
  .pages-brand-sec-5 .brand-list .brand-item:nth-of-type(even) {
    background-color: #f8f8f8; }
  .pages-brand-sec-5 .brand-list .brand-item .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap; }
  .pages-brand-sec-5 .brand-list .brand-item .title {
    flex: 0 0 420px;
    color: #111111;
    font-size: 1.125rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.9px; }
    @media screen and (max-width: 1280px) {
      .pages-brand-sec-5 .brand-list .brand-item .title {
        margin-bottom: 30px;
        flex: 100%; } }
  .pages-brand-sec-5 .brand-list .brand-item ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 0 0 810px;
    padding: 0px;
    margin: 0 -22.5px; }
    @media screen and (max-width: 1280px) {
      .pages-brand-sec-5 .brand-list .brand-item ul {
        flex: 100%; } }
    .pages-brand-sec-5 .brand-list .brand-item ul li {
      list-style: none;
      flex: 0 0 240px;
      margin-bottom: 40px;
      padding: 0 22.5px; }
      @media screen and (max-width: 576px) {
        .pages-brand-sec-5 .brand-list .brand-item ul li {
          flex: 100%; } }
      .pages-brand-sec-5 .brand-list .brand-item ul li a {
        color: #333333;
        font-weight: 400;
        padding-bottom: 10px;
        border-bottom: thin solid #e1e1e1;
        transition: .3s all ease-in-out;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .pages-brand-sec-5 .brand-list .brand-item ul li a span {
          font-size: 1rem;
          letter-spacing: 0.8px; }
        .pages-brand-sec-5 .brand-list .brand-item ul li a em {
          font-size: 1.25rem; }
      .pages-brand-sec-5 .brand-list .brand-item ul li:hover a {
        font-weight: 700;
        color: #ed1c24;
        border-bottom: thin solid #ed1c24; }

.pages-brand-sec-6 {
  min-height: 815px; }
  .pages-brand-sec-6 .pages-title {
    padding-bottom: 10px; }
  @media screen and (max-width: 1024px) {
    .pages-brand-sec-6 .col-lg-6:first-child {
      margin-bottom: 30px; } }
  .pages-brand-sec-6 .col-lg-6:last-child .col-lg-6 {
    margin-bottom: 30px; }
  .pages-brand-sec-6 .box-img .img {
    overflow: hidden; }
    .pages-brand-sec-6 .box-img .img:hover img {
      transform: scale(1.05); }
    .pages-brand-sec-6 .box-img .img:hover .content {
      color: #ed1c24;
      letter-spacing: 1.5px; }
    .pages-brand-sec-6 .box-img .img .content {
      position: absolute;
      bottom: 1.125rem;
      left: 1.125rem;
      color: #ffffff;
      transition: .3s all  ease-in-out;
      font-weight: 400;
      display: flex;
      align-items: center;
      z-index: 2; }
      .pages-brand-sec-6 .box-img .img .content span {
        margin-left: 10px;
        font-size: 1rem; }
      .pages-brand-sec-6 .box-img .img .content em {
        font-size: 1.25rem; }
  .pages-brand-sec-6 .box-img.big .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%; }
    .pages-brand-sec-6 .box-img.big .img a img, .pages-brand-sec-6 .box-img.big .img a iframe, .pages-brand-sec-6 .box-img.big .img a video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .pages-brand-sec-6 .box-img.small .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%; }
    .pages-brand-sec-6 .box-img.small .img a img, .pages-brand-sec-6 .box-img.small .img a iframe, .pages-brand-sec-6 .box-img.small .img a video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }

.pages-brand-sec-7 {
  min-height: 735px; }
  .pages-brand-sec-7 .option-box {
    max-width: 1020px;
    margin: 0 auto;
    margin-top: 30px; }
    @media screen and (min-width: 1024px) {
      .pages-brand-sec-7 .option-box {
        margin-top: 48px; } }
    .pages-brand-sec-7 .option-box .col {
      flex: 0 0 25% !important; }
    .pages-brand-sec-7 .option-box .option-wrapper .option {
      box-shadow: 2px 2px 30px rgba(4, 4, 4, 0.05);
      border-radius: 50px;
      background-color: #ffffff; }

.pages-brand-sec-8 {
  min-height: 680px; }
  .pages-brand-sec-8 .note {
    color: #333333;
    font-size: 1rem;
    font-weight: 400; }
  .pages-brand-sec-8 .contact-info {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    flex-wrap: wrap; }
    @media screen and (max-width: 1024px) {
      .pages-brand-sec-8 .contact-info .item:nth-of-type(1) {
        flex: 0 0 100%; }
      .pages-brand-sec-8 .contact-info .item:nth-of-type(2), .pages-brand-sec-8 .contact-info .item:nth-of-type(3) {
        flex: 0 0 50%;
        padding: 10px 0px; } }
    .pages-brand-sec-8 .contact-info .item + .item {
      margin-left: 20px; }
      @media screen and (max-width: 1024px) {
        .pages-brand-sec-8 .contact-info .item + .item {
          margin-left: 0px; } }
    .pages-brand-sec-8 .contact-info .item .header {
      display: flex;
      align-items: center;
      font-weight: 400; }
      .pages-brand-sec-8 .contact-info .item .header em {
        color: #cccccc;
        font-size: 1.5rem; }
      .pages-brand-sec-8 .contact-info .item .header p {
        margin-left: 10px;
        color: #6d6e70;
        font-size: 1rem; }
    .pages-brand-sec-8 .contact-info .item .main {
      margin-top: 5px;
      margin-left: 35px; }
      .pages-brand-sec-8 .contact-info .item .main .wrapper {
        display: flex;
        align-items: center;
        color: #333333;
        font-size: 0.9375rem;
        font-weight: 400; }
        .pages-brand-sec-8 .contact-info .item .main .wrapper span {
          color: #ed1c24;
          margin-left: 3px; }
  .pages-brand-sec-8 .wrap-form #ctl00_mainContent_ctl03_pnlQuestions {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -10px;
    margin-top: 15px; }
  .pages-brand-sec-8 .wrap-form .form-group {
    margin-bottom: 20px;
    padding: 0px 10px;
    position: relative; }
    .pages-brand-sec-8 .wrap-form .form-group:not(:nth-of-type(5)) {
      flex: 0 0 50%; }
    .pages-brand-sec-8 .wrap-form .form-group:nth-of-type(5) {
      flex: 100%;
      margin-left: 0px; }
    @media screen and (max-width: 768px) {
      .pages-brand-sec-8 .wrap-form .form-group {
        flex: 100%  !important; } }
    .pages-brand-sec-8 .wrap-form .form-group input, .pages-brand-sec-8 .wrap-form .form-group textarea {
      width: 100%;
      height: 50px;
      border: 1px solid #d7d7d7;
      background-color: #ffffff;
      outline: none;
      color: #999999;
      font-size: 1rem;
      font-weight: 400;
      padding: 0px 20px;
      transition: .3s all  ease-in-out; }
      .pages-brand-sec-8 .wrap-form .form-group input::placeholder, .pages-brand-sec-8 .wrap-form .form-group textarea::placeholder {
        color: #999999;
        font-size: 1rem;
        font-weight: 400; }
      .pages-brand-sec-8 .wrap-form .form-group input:focus, .pages-brand-sec-8 .wrap-form .form-group input:active, .pages-brand-sec-8 .wrap-form .form-group textarea:focus, .pages-brand-sec-8 .wrap-form .form-group textarea:active {
        border: thin solid #ed1c24; }
    .pages-brand-sec-8 .wrap-form .form-group span {
      font-size: 0.75rem;
      color: #ed1c24;
      display: block;
      padding: 5px 0px; }
    .pages-brand-sec-8 .wrap-form .form-group textarea {
      height: 100px;
      resize: none; }
    .pages-brand-sec-8 .wrap-form .form-group.frm-btnwrap .frm-btn .frm-btn-reset {
      display: none; }
    .pages-brand-sec-8 .wrap-form .form-group.frm-btnwrap .frm-btn .frm-btn-submit {
      position: relative;
      width: 210px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ed1c24;
      color: #ffffff;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      border: thin solid #ed1c24;
      background: url(../img/submit_arrow.png) no-repeat right 20px center;
      transition: .3s all  ease-in-out; }
      .pages-brand-sec-8 .wrap-form .form-group.frm-btnwrap .frm-btn .frm-btn-submit:hover {
        background-color: #ed1c24;
        background-position: right 10px center; }

.block_banner-home {
  position: relative; }
  .block_banner-home .img-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
    @media screen and (max-width: 1280px) {
      .block_banner-home .img-banner img {
        min-height: 60vh; } }
  .block_banner-home .content-banner {
    position: absolute;
    width: 100%;
    bottom: 10rem;
    left: 0; }
    @media screen and (max-width: 1023px) {
      .block_banner-home .content-banner {
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: unset;
        top: 50%; } }
    @media screen and (max-width: 576px) {
      .block_banner-home .content-banner {
        bottom: 6.25rem; } }
    .block_banner-home .content-banner .title-banner {
      max-width: 625px; }
      @media screen and (max-width: 768px) {
        .block_banner-home .content-banner .title-banner {
          width: 100%;
          max-width: unset; } }
      .block_banner-home .content-banner .title-banner h2 {
        font-size: 2.125rem;
        color: #fff;
        padding-bottom: 1.25rem;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        color: #ffffff;
        font-weight: 700; }
        @media screen and (max-width: 576px) {
          .block_banner-home .content-banner .title-banner h2 {
            padding-bottom: 0.625rem;
            font-size: 1.625rem; } }
    .block_banner-home .content-banner .readmore {
      width: 12.5rem;
      height: 2.5rem;
      border: 2px solid #ffffff;
      color: #fff;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      margin: unset; }
      .block_banner-home .content-banner .readmore span {
        padding-left: 1.25rem; }
  .block_banner-home .swiper-pagination {
    bottom: 80px;
    z-index: 99999; }
    @media screen and (min-width: 1024px) {
      .block_banner-home .swiper-pagination {
        bottom: 120px; } }
    .block_banner-home .swiper-pagination .swiper-pagination-bullet {
      background-color: transparent;
      border: thin solid #ed1c24;
      opacity: 1;
      width: 12px;
      height: 12px; }
    .block_banner-home .swiper-pagination .swiper-pagination-bullet-active {
      background-color: #ed1c24; }
  .block_banner-home .overlay-bot {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transform: translateX(-50%); }
    @media screen and (max-width: 576px) {
      .block_banner-home .overlay-bot {
        padding: 0 20px; } }
    .block_banner-home .overlay-bot .trademark {
      padding: 19.5px 22px; }
      @media screen and (max-width: 768px) {
        .block_banner-home .overlay-bot .trademark {
          flex: 0 0 75px;
          padding: 5px;
          margin: 0 15px; } }
      @media screen and (max-width: 576px) {
        .block_banner-home .overlay-bot .trademark {
          margin: 0 5px;
          flex: 0 0 20%; }
          .block_banner-home .overlay-bot .trademark img {
            width: 95%;
            height: 95%;
            object-fit: cover; } }

.block_s-1 .block_title {
  margin: 45px 0px; }
  @media screen and (max-width: 1280px) {
    .block_s-1 .block_title {
      margin: 1.875rem 0; } }
  .block_s-1 .block_title .title {
    position: relative; }
    .block_s-1 .block_title .title span {
      color: #ed1c24;
      letter-spacing: 1.8px; }
    .block_s-1 .block_title .title::after {
      content: '';
      position: absolute;
      background-image: url("../img/s-1/title.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 2.875rem;
      height: 1.125rem;
      top: -30px;
      left: 50%;
      transform: translateX(-50%); }

.block_s-1 .system-wrapper {
  margin-bottom: 30px; }
  .block_s-1 .system-wrapper .img {
    width: 100%;
    position: relative; }
    .block_s-1 .system-wrapper .img::after {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 68.33%; }
    .block_s-1 .system-wrapper .img::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.01), #1b1b1b);
      z-index: 1;
      pointer-events: none; }
    .block_s-1 .system-wrapper .img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .block_s-1 .system-wrapper .img:hover .content-system span:first-child {
      transform: translate(0); }
    .block_s-1 .system-wrapper .img:hover .content-system span:last-child {
      opacity: 1;
      pointer-events: visible;
      visibility: visible;
      transform: translate(0); }
    .block_s-1 .system-wrapper .img .content-system {
      position: absolute;
      z-index: 2;
      bottom: 15px;
      left: 0;
      right: 0;
      padding: 0px 1.875rem;
      color: #fff;
      font-size: 1rem;
      font-weight: 700;
      transition: .3s all ease-in-out; }
      .block_s-1 .system-wrapper .img .content-system > * {
        pointer-events: none; }
      @media screen and (max-width: 768px) {
        .block_s-1 .system-wrapper .img .content-system {
          padding: 0px 15px; } }
      .block_s-1 .system-wrapper .img .content-system.big {
        font-size: 1.5rem; }
        @media screen and (max-width: 575px) {
          .block_s-1 .system-wrapper .img .content-system.big {
            font-size: 1rem; } }
      .block_s-1 .system-wrapper .img .content-system span:first-child {
        transform: translateY(5px);
        transition: .3s all ease-in-out;
        display: block; }
      .block_s-1 .system-wrapper .img .content-system span:last-child {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transition: .3s all ease-in-out;
        transform: translateY(30px); }
    .block_s-1 .system-wrapper .img .see-solution {
      font-size: 0.875rem;
      font-weight: 400;
      display: flex;
      align-items: center; }
      .block_s-1 .system-wrapper .img .see-solution em {
        padding-left: 0.5rem; }
      .block_s-1 .system-wrapper .img .see-solution:hover {
        color: #ed1c24; }

.block_s-1 .readmore {
  margin-top: 0.625rem; }

.block_s-2 .product-wrapper {
  margin-bottom: 30px; }
  .block_s-2 .product-wrapper:hover .big-content-product {
    background-color: rgba(0, 0, 0, 0.9) !important;
    color: #fff; }
  .block_s-2 .product-wrapper .img {
    width: 100%;
    position: relative; }
    .block_s-2 .product-wrapper .img::after {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%; }
    .block_s-2 .product-wrapper .img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .block_s-2 .product-wrapper .img .big-content-product {
      position: absolute;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.9);
      width: 100%;
      height: 3.75rem;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-weight: 700;
      transition: 0.2s all; }
      .block_s-2 .product-wrapper .img .big-content-product span {
        padding-left: 1.25rem;
        color: #ed1c24; }

.block_s-3 {
  position: relative; }
  @media screen and (max-width: 576px) {
    .block_s-3 {
      background-color: #fafafa; } }
  .block_s-3 .img {
    position: relative; }
    .block_s-3 .img::after {
      content: '';
      display: block;
      padding-bottom: 37.63%; }
    .block_s-3 .img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .block_s-3 .trademark-box {
    position: absolute;
    top: 38%;
    transform: translateY(-50%);
    width: 100%; }
    @media screen and (max-width: 1024px) {
      .block_s-3 .trademark-box {
        position: static;
        transform: none;
        background-color: #fafafa;
        padding-bottom: 15px; }
        .block_s-3 .trademark-box .container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column; } }
    .block_s-3 .trademark-box .trademark {
      font-size: 2.25rem;
      color: #333333;
      font-weight: 700;
      margin-bottom: 1.25rem; }
    .block_s-3 .trademark-box .readmore {
      margin: unset; }
    @media screen and (max-width: 576px) {
      .block_s-3 .trademark-box {
        top: unset;
        bottom: -3%;
        display: flex; } }

.block_s-4.section {
  padding-top: 0px; }
  @media screen and (min-width: 1280px) {
    .block_s-4.section {
      padding-bottom: 80px; } }

.block_s-4 .box-video {
  max-width: 810px;
  margin: 0 auto; }
  .block_s-4 .box-video .video {
    position: relative; }
    .block_s-4 .box-video .video::after {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 58%; }
    .block_s-4 .box-video .video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 1.25rem;
      object-fit: cover; }

.option-box .row {
  justify-content: space-between; }
  .option-box .row .col {
    flex: 0 0 20%;
    padding: 0 15px; }
    @media screen and (max-width: 425px) {
      .option-box .row .col {
        flex: 1 !important; } }
    .option-box .row .col .option-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      flex-wrap: wrap; }
      .option-box .row .col .option-wrapper .option {
        width: 180px;
        height: 180px;
        flex: 0 0 180px;
        background-color: #fafafa;
        border-radius: 30%;
        padding: 0; }
        @media screen and (min-width: 425px) {
          .option-box .row .col .option-wrapper .option {
            padding: 18.5%; } }
        @media screen and (max-width: 425px) {
          .option-box .row .col .option-wrapper .option {
            padding: 10%; } }
        .option-box .row .col .option-wrapper .option .img {
          width: 100%;
          position: relative; }
          .option-box .row .col .option-wrapper .option .img::after {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 100%; }
          .option-box .row .col .option-wrapper .option .img img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; }
            @media screen and (min-width: 425px) {
              .option-box .row .col .option-wrapper .option .img img {
                object-fit: none; } }
      .option-box .row .col .option-wrapper .content-option {
        font-size: 1rem;
        padding: 0 0.3125rem;
        padding-top: 1.25rem;
        text-align: center; }

.block_s-5 {
  background-color: #f8f8f8; }
  @media screen and (min-width: 1280px) {
    .block_s-5.section {
      padding-bottom: 75px; } }
  .block_s-5 .block_title {
    margin-bottom: 0.625rem; }
  .block_s-5 .tab_menu ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0.75rem; }
    @media screen and (max-width: 1024px) {
      .block_s-5 .tab_menu ul {
        padding: 0; } }
    .block_s-5 .tab_menu ul li {
      font-size: 1rem;
      margin: 1rem;
      cursor: pointer;
      transition: all .2s;
      position: relative; }
      .block_s-5 .tab_menu ul li:after {
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 0%;
        content: '';
        color: transparent;
        background: #ed1c24;
        height: 2px;
        transition: all .2s; }
      .block_s-5 .tab_menu ul li:hover {
        color: #ed1c24; }
        .block_s-5 .tab_menu ul li:hover:after {
          width: 100%; }
      .block_s-5 .tab_menu ul li.active {
        color: #ed1c24; }
        .block_s-5 .tab_menu ul li.active:after {
          width: 100%; }
  .block_s-5 .tab_menu .body-tab_menu {
    padding-top: 3rem; }
    .block_s-5 .tab_menu .body-tab_menu .img-wrapper {
      margin-bottom: 30px;
      position: relative; }
      .block_s-5 .tab_menu .body-tab_menu .img-wrapper .img {
        width: 100%;
        position: relative; }
        .block_s-5 .tab_menu .body-tab_menu .img-wrapper .img::after {
          content: '';
          display: block;
          width: 100%;
          padding-bottom: 100%; }
        .block_s-5 .tab_menu .body-tab_menu .img-wrapper .img img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .block_s-5 .tab_menu .body-tab_menu .img-wrapper .content_img, .block_s-5 .tab_menu .body-tab_menu .img-wrapper .big-content_img {
        position: absolute;
        z-index: 2;
        bottom: 1.25rem;
        left: 1.875rem;
        margin-right: 1.875rem;
        color: #fff;
        font-size: 1rem;
        font-weight: 700;
        transition: 0.2s all;
        border-bottom: 1px solid transparent; }
        .block_s-5 .tab_menu .body-tab_menu .img-wrapper .content_img:after, .block_s-5 .tab_menu .body-tab_menu .img-wrapper .big-content_img:after {
          position: absolute;
          bottom: -2px;
          left: 0;
          right: 0;
          width: 0;
          content: '';
          color: transparent;
          background: #ffffff;
          height: 2px;
          transition: all .2s; }
      .block_s-5 .tab_menu .body-tab_menu .img-wrapper .big-content_img {
        font-size: 2.4rem; }
      .block_s-5 .tab_menu .body-tab_menu .img-wrapper:hover .content_img:after, .block_s-5 .tab_menu .body-tab_menu .img-wrapper:hover .big-content_img:after {
        width: 100%; }

.block_s-6.section {
  padding-bottom: 0px; }

.block_s-6 .block_title-news {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.875rem; }
  .block_s-6 .block_title-news h2 {
    font-size: 2.25rem; }
  .block_s-6 .block_title-news .readall {
    font-size: 1rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding-top: 0.625rem;
    font-weight: 700;
    transition: 0.2s all; }
    .block_s-6 .block_title-news .readall span {
      padding-left: 1.25rem;
      font-size: 1.25rem;
      font-weight: 700; }
    .block_s-6 .block_title-news .readall:hover {
      color: #ed1c24; }

.block_s-6 .big-news-wrapper {
  position: relative;
  margin-bottom: 30px; }
  @media screen and (max-width: 576px) {
    .block_s-6 .big-news-wrapper {
      margin-bottom: 58%; } }
  .block_s-6 .big-news-wrapper::after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 100%; }
  .block_s-6 .big-news-wrapper .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .block_s-6 .big-news-wrapper .img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .block_s-6 .big-news-wrapper .content-news {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 50%;
    background-color: #f8f8f8; }
    @media screen and (max-width: 576px) {
      .block_s-6 .big-news-wrapper .content-news {
        width: 100%;
        bottom: -50%; } }

.block_s-6 .news-wrapper {
  position: relative;
  margin-bottom: 30px; }
  @media screen and (max-width: 576px) {
    .block_s-6 .news-wrapper {
      margin-bottom: 58%; } }
  .block_s-6 .news-wrapper::after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 47.5%; }
    @media screen and (max-width: 576px) {
      .block_s-6 .news-wrapper::after {
        padding-bottom: 100%; } }
  .block_s-6 .news-wrapper .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%; }
    @media screen and (max-width: 576px) {
      .block_s-6 .news-wrapper .img {
        width: 100%; } }
    .block_s-6 .news-wrapper .img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .block_s-6 .news-wrapper .content-news {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: #f8f8f8; }
    @media screen and (max-width: 576px) {
      .block_s-6 .news-wrapper .content-news {
        width: 100%;
        height: 50%;
        top: unset;
        bottom: -50%;
        right: unset;
        left: 0; } }

.block_s-6 .content-news {
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .block_s-6 .content-news .body-content time {
    display: block;
    font-size: 0.875rem;
    color: #666666;
    margin-bottom: 1.875rem;
    position: relative; }
    .block_s-6 .content-news .body-content time::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1rem;
      width: 1.875rem;
      height: 2px;
      background-color: #ed1c24; }
  .block_s-6 .content-news .body-content .link-news {
    font-size: 1.25rem;
    color: #111111;
    font-weight: 700;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.2s all; }
    .block_s-6 .content-news .body-content .link-news:hover {
      color: #ed1c24; }
  .block_s-6 .content-news .read-more {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #ed1c24;
    font-weight: 700;
    width: max-content;
    transition: all .2s;
    position: relative; }
    .block_s-6 .content-news .read-more:after {
      position: absolute;
      bottom: -0.2rem;
      left: 0;
      right: 0;
      width: 0%;
      content: '';
      color: transparent;
      background: #ed1c24;
      height: 0.2rem;
      transition: all .2s; }
    .block_s-6 .content-news .read-more:hover {
      color: #ed1c24; }
      .block_s-6 .content-news .read-more:hover:after {
        width: 100%; }
    .block_s-6 .content-news .read-more span {
      padding-left: 0.625rem;
      font-size: 1.125rem; }

@media screen and (min-width: 576px) {
  .block_s-6 .row .col-lg-6:nth-child(2) .news-wrapper:nth-child(2) .content-news {
    left: 0;
    right: unset; }
  .block_s-6 .row .col-lg-6:nth-child(2) .news-wrapper:nth-child(2) .img {
    left: unset;
    right: 0; } }

.block_s-7 .support-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media screen and (max-width: 576px) {
    .block_s-7 .support-wrapper {
      margin-bottom: 1.875rem; } }
  .block_s-7 .support-wrapper .img {
    width: 3.75rem;
    height: 3.75rem;
    margin-bottom: 1.25rem; }
  .block_s-7 .support-wrapper .title {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    margin-bottom: 2.5rem; }
    .block_s-7 .support-wrapper .title::after {
      position: absolute;
      content: '';
      width: 0.9375rem;
      height: 2px;
      bottom: -r(15px);
      left: 50%;
      transform: translateX(-50%);
      background-color: #ed1c24; }
  .block_s-7 .support-wrapper .content {
    text-align: center;
    margin-bottom: 1.25rem; }
    .block_s-7 .support-wrapper .content p {
      font-size: 1.125rem; }
    .block_s-7 .support-wrapper .content a {
      font-size: 1.125rem;
      color: #ed1c24; }
    .block_s-7 .support-wrapper .content .send {
      display: flex;
      align-items: center;
      font-size: 1.125rem;
      color: #ed1c24;
      font-weight: 500; }
      .block_s-7 .support-wrapper .content .send span {
        padding-left: 0.625rem; }

.pages-news-detail.section {
  padding-bottom: 30px; }
  @media screen and (min-width: 1024px) {
    .pages-news-detail.section {
      padding-top: 45px; } }

.pages-news-detail .article-wrapper {
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 15px; }

.pages-news-detail .article-head {
  padding-bottom: 20px;
  border-bottom: thin solid  #d5d5d5; }
  .pages-news-detail .article-head .title {
    color: #0a0a0a;
    font-size: 1.875rem;
    font-weight: 700; }
  .pages-news-detail .article-head .wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .pages-news-detail .article-head .wrapper .date {
      color: #666666;
      font-size: 0.875rem;
      font-weight: 400;
      letter-spacing: 0.7px; }
    .pages-news-detail .article-head .wrapper .social-icons {
      display: flex;
      justify-content: center;
      align-items: center; }
      .pages-news-detail .article-head .wrapper .social-icons .icon {
        width: 40px;
        height: 40px;
        border: thin solid #999999;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s all  ease-in-out;
        border-radius: 100%; }
        @media screen and (max-width: 768px) {
          .pages-news-detail .article-head .wrapper .social-icons .icon {
            width: 1.875rem;
            height: 1.875rem; } }
        .pages-news-detail .article-head .wrapper .social-icons .icon:hover {
          border: thin solid #ed1c24;
          background-color: #ed1c24; }
          .pages-news-detail .article-head .wrapper .social-icons .icon:hover em {
            color: #ffffff; }
        .pages-news-detail .article-head .wrapper .social-icons .icon + .icon {
          margin-left: 20px; }
          @media screen and (max-width: 1024px) {
            .pages-news-detail .article-head .wrapper .social-icons .icon + .icon {
              margin-left: 10px; } }
        .pages-news-detail .article-head .wrapper .social-icons .icon em {
          color: #999999;
          transition: .3s all  ease-in-out;
          font-size: 1.25rem; }
          @media screen and (max-width: 768px) {
            .pages-news-detail .article-head .wrapper .social-icons .icon em {
              font-size: 1rem; } }

.pages-news-detail .article-body {
  padding-bottom: 40px;
  border-bottom: thin solid #d5d5d5; }
  .pages-news-detail .article-body .content * {
    color: #333333;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.4px;
    margin-top: 1.25rem; }
  .pages-news-detail .article-body .content strong {
    font-weight: 700; }
  .pages-news-detail .article-body .content table {
    max-width: 1230px;
    margin: 0 auto;
    margin-top: 1.25rem; }
    .pages-news-detail .article-body .content table tr:nth-of-type(odd) {
      background-color: #f5f5f5; }
    .pages-news-detail .article-body .content table tr td {
      width: 50%; }
      .pages-news-detail .article-body .content table tr td:first-child {
        font-weight: 600;
        padding-left: 10px; }
      .pages-news-detail .article-body .content table tr td:last-child {
        padding-left: 10px;
        padding-right: 10px; }
  .pages-news-detail .article-body .content .row .col-lg-6 {
    margin-top: 0px; }
    @media screen and (min-width: 1024px) {
      .pages-news-detail .article-body .content .row .col-lg-6:last-child img {
        margin-top: 0px; } }
    .pages-news-detail .article-body .content .row .col-lg-6:first-child img {
      margin-top: 0px; }

.news-relate .relate-title {
  color: #0a0a0a;
  font-size: 1.875rem;
  margin-bottom: 20px;
  font-weight: 700; }

.news-relate .news-relate-slide {
  position: relative; }

.news-relate.section {
  padding-top: 0px; }
  @media screen and (min-width: 1280px) {
    .news-relate.section {
      padding-bottom: 80px; } }

.product-detail-page #page-banner {
  display: none; }

.tab-panel, .nav-brand {
  transition: .3s all  ease-in-out; }
  .tab-panel.tab-panel, .nav-brand.tab-panel {
    padding-top: 40px;
    padding-bottom: 30px; }
    @media screen and (max-width: 1024px) {
      .tab-panel.tab-panel, .nav-brand.tab-panel {
        padding: 30px 0px; } }
  .tab-panel.nav-brand, .nav-brand.nav-brand {
    background-color: #f3f3f3; }
    .tab-panel.nav-brand nav, .nav-brand.nav-brand nav {
      width: 100%; }
      .tab-panel.nav-brand nav ul, .nav-brand.nav-brand nav ul {
        padding-top: 5px; }
        @media screen and (min-width: 1280px) {
          .tab-panel.nav-brand nav ul, .nav-brand.nav-brand nav ul {
            padding: 0px; } }
        .tab-panel.nav-brand nav ul li, .nav-brand.nav-brand nav ul li {
          height: 40px;
          background: transparent;
          padding: 0px 18px;
          width: 100%;
          font-size: 0.9375rem; }
          @media screen and (max-width: 576px) {
            .tab-panel.nav-brand nav ul li, .nav-brand.nav-brand nav ul li {
              width: fit-content; } }
          .tab-panel.nav-brand nav ul li:hover, .tab-panel.nav-brand nav ul li.active, .nav-brand.nav-brand nav ul li:hover, .nav-brand.nav-brand nav ul li.active {
            color: #ffffff;
            background-color: #ed1c24; }
            .tab-panel.nav-brand nav ul li:hover .text-hover::before, .tab-panel.nav-brand nav ul li.active .text-hover::before, .nav-brand.nav-brand nav ul li:hover .text-hover::before, .nav-brand.nav-brand nav ul li.active .text-hover::before {
              width: 100%; }
          .tab-panel.nav-brand nav ul li + li, .nav-brand.nav-brand nav ul li + li {
            margin-left: 0px; }
  .tab-panel nav, .nav-brand nav {
    display: flex;
    justify-content: center;
    align-items: center; }
    .tab-panel nav ul, .nav-brand nav ul {
      padding: 0;
      display: flex;
      align-items: center;
      overflow-x: auto;
      padding-bottom: 3px; }
      .tab-panel nav ul::-webkit-scrollbar-track, .nav-brand nav ul::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
        background-color: #cccccc; }
      .tab-panel nav ul::-webkit-scrollbar, .nav-brand nav ul::-webkit-scrollbar {
        width: 2px;
        height: 2px; }
      .tab-panel nav ul::-webkit-scrollbar-thumb, .nav-brand nav ul::-webkit-scrollbar-thumb {
        border-radius: 20px;
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
        background-color: #ed1c24; }
      .tab-panel nav ul li, .nav-brand nav ul li {
        list-style: none;
        font-size: 1rem;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s all  ease-in-out;
        cursor: pointer;
        color: #666666;
        font-weight: 400;
        white-space: nowrap; }
        .tab-panel nav ul li + li, .nav-brand nav ul li + li {
          margin-left: 30px; }
        .tab-panel nav ul li:hover, .nav-brand nav ul li:hover {
          color: #ed1c24; }
        .tab-panel nav ul li.active, .nav-brand nav ul li.active {
          color: #ed1c24; }
          .tab-panel nav ul li.active .text-hover::before, .nav-brand nav ul li.active .text-hover::before {
            width: 100%; }

.pages-news-list.section {
  padding-top: 0px; }

.pages-news-list .col-lg-6:nth-of-type(even) .col-12:first-child .img {
  order: 1; }

.pages-news-list .col-lg-6:nth-of-type(even) .col-12:first-child .box-content {
  order: 2; }

.pages-news-list .col-lg-6:nth-of-type(even) .col-12:last-child .img {
  order: 2; }

.pages-news-list .col-lg-6:nth-of-type(even) .col-12:last-child .box-content {
  order: 1; }

#sticky-wrapper.is-sticky .nav-brand {
  padding: 2px 0px;
  animation: 0.5s goDown; }
  #sticky-wrapper.is-sticky .nav-brand::before {
    width: 150%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f3f3f3;
    position: absolute;
    content: '';
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    z-index: -1; }

.brand__page .nav-brand nav ul {
  width: 100%;
  justify-content: space-between; }

.pages-product-list .product-sidebar .product-list .list-title {
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 15px 18px;
  background-color: #ed1c24; }
  @media screen and (max-width: 1024px) {
    .pages-product-list .product-sidebar .product-list .list-title {
      background-color: transparent;
      color: #111111; } }

.pages-product-list .product-sidebar .product-list .list-item {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
  border: 1px solid #e1e1e1;
  background-color: #ffffff; }
  @media screen and (max-width: 1024px) {
    .pages-product-list .product-sidebar .product-list .list-item {
      background-color: transparent;
      color: #111111;
      border: 0px;
      box-shadow: none; } }
  .pages-product-list .product-sidebar .product-list .list-item ul {
    padding: 0px; }
    .pages-product-list .product-sidebar .product-list .list-item ul li {
      list-style: none;
      color: #333333;
      font-size: 0.9375rem;
      font-weight: 600;
      transition: .3s all ease-in-out;
      padding: 15px 18px; }
      .pages-product-list .product-sidebar .product-list .list-item ul li:not(:last-child) {
        border-bottom: thin solid #e1e1e1; }
      .pages-product-list .product-sidebar .product-list .list-item ul li.active, .pages-product-list .product-sidebar .product-list .list-item ul li:hover {
        color: #ed1c24;
        font-weight: 700; }

@media screen and (max-width: 1024.98px) {
  .pages-product-list .product-sidebar {
    position: fixed;
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: .3s all ease-in-out;
    z-index: 50;
    right: 0;
    left: 0;
    bottom: 4vh;
    overflow: auto;
    padding: 15px 0px;
    padding-right: 5px;
    top: 4vh; }
    .pages-product-list .product-sidebar::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
      background-color: #cccccc; }
    .pages-product-list .product-sidebar::-webkit-scrollbar {
      width: 0px;
      height: 0px; }
    .pages-product-list .product-sidebar::-webkit-scrollbar-thumb {
      border-radius: 20px;
      box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
      background-color: #ed1c24; }
    .pages-product-list .product-sidebar.active {
      transition-delay: 0.5s;
      opacity: 1;
      pointer-events: visible;
      visibility: visible; } }

@media screen and (max-width: 375px) {
  .pages-product-list .product-sidebar {
    margin-top: 15px;
    padding-top: 0px; } }

.pages-product-list .product-list .head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px; }
  .pages-product-list .product-list .head .filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1; }
    @media screen and (max-width: 1280px) {
      .pages-product-list .product-list .head .filter {
        flex-direction: column;
        align-items: flex-end; } }
    .pages-product-list .product-list .head .filter .wrapper {
      display: flex;
      align-items: center; }
      .pages-product-list .product-list .head .filter .wrapper + .wrapper {
        margin-top: 10px; }
        @media screen and (min-width: 1280px) {
          .pages-product-list .product-list .head .filter .wrapper + .wrapper {
            margin-left: 1.875rem;
            margin-top: 0px; } }
    .pages-product-list .product-list .head .filter .title {
      color: #111111;
      font-size: 0.9375rem;
      font-weight: 600;
      text-transform: uppercase; }
    .pages-product-list .product-list .head .filter select {
      margin-left: 1.25rem;
      border: thin solid #e1e1e1;
      background-color: #ffffff;
      min-width: 90px;
      height: 40px;
      padding: 0px 10px;
      max-width: 155px;
      appearance: none;
      background: url(../img/expand_more.png) no-repeat right 10px center; }

.pages-product-list .product-describe {
  margin-top: 30px;
  position: relative; }
  .pages-product-list .product-describe .full-content {
    max-height: 300px;
    min-height: 1px;
    overflow: hidden; }
    .pages-product-list .product-describe .full-content.active {
      max-height: 100%;
      padding-bottom: 4rem; }
    .pages-product-list .product-describe .full-content * {
      font-size: 1rem;
      color: #666666; }
      .pages-product-list .product-describe .full-content * + * {
        margin-top: 1.25rem; }
    .pages-product-list .product-describe .full-content span {
      color: #ed1c24;
      font-weight: 600; }
  .pages-product-list .product-describe .expand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 200px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .pages-product-list .product-describe .expand.active {
      height: fit-content; }
      .pages-product-list .product-describe .expand.active::before {
        background-image: linear-gradient(#ffffff, #fff); }
    .pages-product-list .product-describe .expand span {
      font-size: 1rem;
      color: #ed1c24;
      font-weight: 400;
      padding: 5px 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border-radius: 5px;
      border: thin solid #ed1c24;
      transition: .3s all ease-in-out; }
      .pages-product-list .product-describe .expand span:hover {
        border-radius: 20px; }
    .pages-product-list .product-describe .expand::before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(#ffffff00, #fff);
      z-index: -1; }

.pages-product-list .filter_tool {
  position: fixed;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -10px;
  left: -10px;
  border-radius: 50% 25% 50% 0;
  background-color: #f8f8f8;
  cursor: pointer;
  transition: .3s all ease-in-out;
  z-index: 20;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25); }
  .pages-product-list .filter_tool.active {
    width: 100%;
    height: 100%;
    left: 0px;
    bottom: 0px;
    border-radius: 0px;
    box-shadow: none; }
    .pages-product-list .filter_tool.active .main em {
      opacity: 0;
      pointer-events: none;
      visibility: hidden; }
    .pages-product-list .filter_tool.active .close-button {
      pointer-events: visible;
      visibility: visible;
      display: block;
      position: fixed;
      right: 15%;
      top: 2vh;
      animation: 2s slideUp;
      z-index: 999;
      cursor: pointer; }
      @media screen and (max-width: 576px) {
        .pages-product-list .filter_tool.active .close-button {
          right: 5%; } }
  @media screen and (min-width: 1024px) {
    .pages-product-list .filter_tool {
      display: none; } }
  .pages-product-list .filter_tool .main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .pages-product-list .filter_tool .main em {
      color: #ed1c24;
      transition: .3s all ease-in-out;
      font-size: 2.1875rem; }
  .pages-product-list .filter_tool .close-button {
    pointer-events: none;
    visibility: hidden;
    transition: .3s all ease-in-out;
    display: none; }

.pages-project-list .main-tab ul {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  @media screen and (max-width: 768px) {
    .pages-project-list .main-tab ul {
      flex-wrap: nowrap;
      overflow: auto;
      justify-content: flex-start; }
      .pages-project-list .main-tab ul::-webkit-scrollbar {
        width: 2px;
        height: 2px; }
      .pages-project-list .main-tab ul li {
        white-space: nowrap; } }
  .pages-project-list .main-tab ul li {
    list-style: none;
    display: inline-block;
    color: #666666;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    margin: 0.5rem 1.125rem; }
    @media screen and (max-width: 768px) {
      .pages-project-list .main-tab ul li {
        margin: 0.1875rem 0.75rem; } }

.pages-recruit-detail .left-side .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: thin solid #d5d5d5; }
  .pages-recruit-detail .left-side .header .main-title {
    color: black;
    font-size: 1.875rem;
    font-weight: 700; }
  .pages-recruit-detail .left-side .header .social-icon {
    display: flex;
    align-items: center;
    margin-left: 20px; }
    .pages-recruit-detail .left-side .header .social-icon .icon {
      width: 32px;
      height: 32px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 20px;
      transition: .3s all ease-in-out;
      border: thin solid #999999; }
      .pages-recruit-detail .left-side .header .social-icon .icon em {
        transition: .3s all ease-in-out;
        color: #999999; }
      .pages-recruit-detail .left-side .header .social-icon .icon:hover {
        background-color: #ed1c24;
        border: thin solid transparent; }
        .pages-recruit-detail .left-side .header .social-icon .icon:hover em {
          color: #ffffff; }
      .pages-recruit-detail .left-side .header .social-icon .icon + .icon {
        margin-left: 10px; }

.pages-recruit-detail .left-side .body {
  margin-top: 30px; }
  .pages-recruit-detail .left-side .body .sec-1 {
    display: flex;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1); }
    @media screen and (max-width: 1024px) {
      .pages-recruit-detail .left-side .body .sec-1 {
        flex-direction: column; }
        .pages-recruit-detail .left-side .body .sec-1 .box-img .img {
          padding-top: 60% !important; }
        .pages-recruit-detail .left-side .body .sec-1 .box-content {
          padding: 30px !important; } }
    @media screen and (max-width: 576px) {
      .pages-recruit-detail .left-side .body .sec-1 .box-img .img {
        padding-top: 113.92405% !important; }
      .pages-recruit-detail .left-side .body .sec-1 .box-content {
        padding: 15px 10px !important; } }
    .pages-recruit-detail .left-side .body .sec-1 .box-img {
      width: 100%;
      height: 100%;
      flex: 0 0 316px; }
      .pages-recruit-detail .left-side .body .sec-1 .box-img .img {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 113.92405%; }
        .pages-recruit-detail .left-side .body .sec-1 .box-img .img img, .pages-recruit-detail .left-side .body .sec-1 .box-img .img iframe, .pages-recruit-detail .left-side .body .sec-1 .box-img .img video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; }
    .pages-recruit-detail .left-side .body .sec-1 .box-content {
      padding: 30px;
      padding-left: 40px;
      flex: 1; }
      .pages-recruit-detail .left-side .body .sec-1 .box-content .title {
        color: #721918;
        font-size: 1.5rem;
        font-weight: 700;
        text-transform: uppercase; }
      .pages-recruit-detail .left-side .body .sec-1 .box-content table {
        width: 100%;
        margin-top: 10px; }
        .pages-recruit-detail .left-side .body .sec-1 .box-content table tbody tr {
          display: flex;
          justify-content: space-between;
          padding: 3px 0px; }
          .pages-recruit-detail .left-side .body .sec-1 .box-content table tbody tr th {
            color: #666666;
            font-size: 1rem;
            font-weight: 600;
            flex: 0 0 50%;
            white-space: nowrap; }
            @media screen and (max-width: 320px) {
              .pages-recruit-detail .left-side .body .sec-1 .box-content table tbody tr th {
                flex: 0 0 155px; } }
            .pages-recruit-detail .left-side .body .sec-1 .box-content table tbody tr th span {
              width: 19px;
              margin-right: 15px;
              text-align: center;
              display: inline-block; }
              @media screen and (max-width: 576px) {
                .pages-recruit-detail .left-side .body .sec-1 .box-content table tbody tr th span {
                  margin-right: 5px; } }
              .pages-recruit-detail .left-side .body .sec-1 .box-content table tbody tr th span img {
                width: fit-content; }
          .pages-recruit-detail .left-side .body .sec-1 .box-content table tbody tr td {
            flex: 0 0 50%;
            padding-left: 5px;
            color: #666666;
            font-size: 1rem;
            font-weight: 500; }
            @media screen and (max-width: 320px) {
              .pages-recruit-detail .left-side .body .sec-1 .box-content table tbody tr td {
                flex: 1; } }
  .pages-recruit-detail .left-side .body .sec-2 .content h3 {
    color: #ed1c24;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 36px; }
  .pages-recruit-detail .left-side .body .sec-2 .content ul {
    padding-left: 15px;
    margin-top: 20px; }
    .pages-recruit-detail .left-side .body .sec-2 .content ul li {
      color: black;
      font-size: 1rem;
      font-weight: 500; }
      .pages-recruit-detail .left-side .body .sec-2 .content ul li + li {
        margin-top: 15px; }
      .pages-recruit-detail .left-side .body .sec-2 .content ul li span {
        font-weight: 600; }
  .pages-recruit-detail .left-side .body .sec-2 .content .title {
    color: #ed1c24;
    font-size: 1.5625rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 20px; }
  .pages-recruit-detail .left-side .body .sec-2 .button {
    display: flex;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap; }
    .pages-recruit-detail .left-side .body .sec-2 .button .btn {
      margin: 10px 0px; }
    .pages-recruit-detail .left-side .body .sec-2 .button span {
      color: #111111;
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
      letter-spacing: 0.8px;
      margin: 0px 20px; }

@media screen and (max-width: 1280px) {
  .pages-recruit-detail .col-xl-3 {
    margin-top: 30px; } }

.pages-recruit-detail .right-side {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
  border: 1px solid #e1e1e1;
  background-color: #ffffff; }
  .pages-recruit-detail .right-side .rs-title {
    color: #ffffff;
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    height: 50px;
    background-color: #ed1c24;
    font-weight: 700;
    text-transform: uppercase; }
  .pages-recruit-detail .right-side .recruit-list {
    margin-top: 5px; }
    .pages-recruit-detail .right-side .recruit-list .recruit-item {
      transition: .3s all ease-in-out;
      padding: 15px;
      border-bottom: thin solid #ccc; }
      .pages-recruit-detail .right-side .recruit-list .recruit-item:hover .title {
        color: #ed1c24; }
      .pages-recruit-detail .right-side .recruit-list .recruit-item .title {
        color: #111111;
        font-size: 1.125rem;
        font-weight: 700;
        transition: .3s all ease-in-out; }
      .pages-recruit-detail .right-side .recruit-list .recruit-item .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap; }
        .pages-recruit-detail .right-side .recruit-list .recruit-item .wrapper * {
          font-weight: 400;
          color: #666666;
          transition: .3s all ease-in-out;
          font-size: 0.9375rem; }
        .pages-recruit-detail .right-side .recruit-list .recruit-item .wrapper p {
          margin-left: 5px; }
        .pages-recruit-detail .right-side .recruit-list .recruit-item .wrapper .address, .pages-recruit-detail .right-side .recruit-list .recruit-item .wrapper .money {
          display: flex;
          align-items: center; }
        .pages-recruit-detail .right-side .recruit-list .recruit-item .wrapper * {
          white-space: nowrap; }
        .pages-recruit-detail .right-side .recruit-list .recruit-item .wrapper .money * {
          color: #ee8236; }
        .pages-recruit-detail .right-side .recruit-list .recruit-item .wrapper em {
          font-size: 1rem;
          color: #999999; }
        .pages-recruit-detail .right-side .recruit-list .recruit-item .wrapper .link a {
          transition: .3s all ease-in-out; }

@media screen and (min-width: 1280px) {
  .pages-recruit-list.section {
    padding-bottom: 75px;
    padding-top: 70px; } }

.pages-recruit-list .row {
  margin-top: 25px;
  margin-bottom: 30px; }
  @media screen and (min-width: 1280px) {
    .pages-recruit-list .row .col-lg-6 {
      display: flex; }
      .pages-recruit-list .row .col-lg-6:nth-of-type(odd) {
        justify-content: flex-start; }
      .pages-recruit-list .row .col-lg-6:nth-of-type(even) {
        justify-content: flex-end; } }

.pages-recruit-list .item {
  border-top: thin solid transparent;
  border-bottom: thin solid #d7d7d7;
  padding: 15px 20px;
  transition: .3s all  ease-in-out; }
  @media screen and (min-width: 1280px) {
    .pages-recruit-list .item {
      flex: 0 0 580px; } }
  .pages-recruit-list .item:hover {
    border-bottom: thin solid transparent;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    background-color: #ffffff; }
    .pages-recruit-list .item:hover .link a em {
      transform: translateX(5px); }
    .pages-recruit-list .item:hover .title * {
      color: #ed1c24; }
  .pages-recruit-list .item * {
    font-weight: 400;
    color: #666666;
    transition: .3s all  ease-in-out; }
  .pages-recruit-list .item em {
    font-size: 1rem;
    color: #999999; }
  .pages-recruit-list .item p {
    margin-left: 5px;
    font-size: 1rem; }
  .pages-recruit-list .item .title {
    transition: .3s all  ease-in-out; }
    .pages-recruit-list .item .title * {
      color: #111111;
      font-size: 1.125rem;
      font-weight: 600;
      transition: .3s all  ease-in-out; }
  .pages-recruit-list .item .address, .pages-recruit-list .item .money, .pages-recruit-list .item .date {
    display: flex;
    align-items: center; }
  .pages-recruit-list .item .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 576px) {
      .pages-recruit-list .item .wrapper {
        flex-wrap: wrap; } }
    .pages-recruit-list .item .wrapper * {
      white-space: nowrap; }
    .pages-recruit-list .item .wrapper .money {
      margin-right: 5px; }
      .pages-recruit-list .item .wrapper .money * {
        color: #ee8236; }
    .pages-recruit-list .item .wrapper .date {
      margin-right: 5px;
      margin-top: 0px;
      color: #666666;
      font-size: 1rem;
      letter-spacing: 0px;
      font-weight: 400; }
    .pages-recruit-list .item .wrapper .link em {
      font-family: remixicon;
      font-size: 18px;
      font-weight: 400;
      margin-left: 5px;
      color: #ed1c24;
      transition: .3s all  ease-in-out; }
    .pages-recruit-list .item .wrapper .link a {
      transition: .3s all  ease-in-out;
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      font-weight: 700;
      letter-spacing: 0.7px;
      color: #ed1c24; }

.pages-product-slide {
  overflow: hidden; }
  @media screen and (max-width: 1280px) {
    .pages-product-slide {
      min-height: 770px; } }
  .pages-product-slide.section {
    padding-top: 90px;
    padding-bottom: 110px; }
  .pages-product-slide .pages-content {
    max-width: 850px;
    margin: 0 auto;
    margin-bottom: 25px; }
  .pages-product-slide .product-slide {
    position: relative; }

.solution-list__page .pages-brand-sec-1 .pages-content {
  max-width: 820px; }

.pages-product-detial-2 .wrapper {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  padding: 30px 70px 60px; }
  @media screen and (max-width: 1023.98px) {
    .pages-product-detial-2 .wrapper {
      padding: 15px 30px; } }
  .pages-product-detial-2 .wrapper .tab-info {
    padding-bottom: 15px; }
    @media screen and (max-width: 768px) {
      .pages-product-detial-2 .wrapper .tab-info {
        padding-bottom: 0px; } }
    .pages-product-detial-2 .wrapper .tab-info ul {
      padding: 0px;
      display: flex;
      align-items: center;
      overflow-x: auto;
      padding-bottom: 15px;
      position: relative; }
      .pages-product-detial-2 .wrapper .tab-info ul::before {
        content: '';
        position: absolute;
        width: 100%;
        left: 0px;
        height: 1px;
        bottom: 2px;
        background-color: #e1e1e1; }
      .pages-product-detial-2 .wrapper .tab-info ul::-webkit-scrollbar {
        width: 0px;
        height: 0px; }
      .pages-product-detial-2 .wrapper .tab-info ul li {
        list-style: none;
        padding: 0px 12px;
        white-space: nowrap; }
        .pages-product-detial-2 .wrapper .tab-info ul li.text-hover::after {
          height: 3px;
          top: 35px; }
          @media screen and (max-width: 1279.98px) {
            .pages-product-detial-2 .wrapper .tab-info ul li.text-hover::after {
              top: 33px; } }
          @media screen and (max-width: 1023.98px) {
            .pages-product-detial-2 .wrapper .tab-info ul li.text-hover::after {
              top: 32px; } }
  .pages-product-detial-2 .wrapper .tab-content .content {
    margin-top: 15px; }
    .pages-product-detial-2 .wrapper .tab-content .content * {
      color: #333333;
      font-size: 1.125rem;
      font-weight: 400; }
      .pages-product-detial-2 .wrapper .tab-content .content * + * {
        margin-top: 25px; }
    .pages-product-detial-2 .wrapper .tab-content .content strong {
      font-weight: 700; }

.pages-product-detial-1 .info-wrapper {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap; }
  .pages-product-detial-1 .info-wrapper .box-img {
    flex: 0 0 470px;
    max-width: 470px;
    padding: 40px 30px;
    border-right: thin solid #e1e1e1; }
    @media screen and (max-width: 1279.98px) {
      .pages-product-detial-1 .info-wrapper .box-img {
        flex: 0 0 50%;
        max-width: 50%; } }
    @media screen and (max-width: 1023.98px) {
      .pages-product-detial-1 .info-wrapper .box-img {
        flex: 0 0 100%;
        max-width: 100%; } }
    @media screen and (max-width: 768px) {
      .pages-product-detial-1 .info-wrapper .box-img {
        padding: 15px; } }
    .pages-product-detial-1 .info-wrapper .box-img .top-slide {
      position: relative; }
      .pages-product-detial-1 .info-wrapper .box-img .top-slide .img {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 100%; }
        .pages-product-detial-1 .info-wrapper .box-img .top-slide .img img, .pages-product-detial-1 .info-wrapper .box-img .top-slide .img iframe, .pages-product-detial-1 .info-wrapper .box-img .top-slide .img video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; }
        @media screen and (max-width: 1023.98px) {
          .pages-product-detial-1 .info-wrapper .box-img .top-slide .img {
            padding-top: 60%; }
            .pages-product-detial-1 .info-wrapper .box-img .top-slide .img img {
              object-fit: contain; } }
      .pages-product-detial-1 .info-wrapper .box-img .top-slide .swiper-button-next {
        right: 0px; }
      .pages-product-detial-1 .info-wrapper .box-img .top-slide .swiper-button-prev {
        left: 0px; }
    .pages-product-detial-1 .info-wrapper .box-img .thumbs-slide {
      margin-top: 20px; }
      .pages-product-detial-1 .info-wrapper .box-img .thumbs-slide .swiper-slide {
        border: thin solid #e1e1e1;
        background-color: #ffffff;
        padding: 10px; }
        .pages-product-detial-1 .info-wrapper .box-img .thumbs-slide .swiper-slide .img {
          position: relative;
          display: block;
          height: 0;
          overflow: hidden;
          padding-top: 100%; }
          .pages-product-detial-1 .info-wrapper .box-img .thumbs-slide .swiper-slide .img img, .pages-product-detial-1 .info-wrapper .box-img .thumbs-slide .swiper-slide .img iframe, .pages-product-detial-1 .info-wrapper .box-img .thumbs-slide .swiper-slide .img video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: contain;
            transition: .3s ease-in-out all; }
        .pages-product-detial-1 .info-wrapper .box-img .thumbs-slide .swiper-slide.swiper-slide-thumb-active {
          border: thin solid #ed1c24; }
  .pages-product-detial-1 .info-wrapper .box-content {
    flex: 0 0 475px;
    max-width: 475px;
    padding: 30px;
    padding-top: 25px;
    border-right: thin solid #e1e1e1; }
    @media screen and (max-width: 1279.98px) {
      .pages-product-detial-1 .info-wrapper .box-content {
        flex: 0 0 50%;
        max-width: 50%; } }
    @media screen and (max-width: 1023.98px) {
      .pages-product-detial-1 .info-wrapper .box-content {
        flex: 0 0 100%;
        max-width: 100%;
        padding-top: 0px;
        padding-bottom: 10px; } }
    @media screen and (max-width: 768px) {
      .pages-product-detial-1 .info-wrapper .box-content {
        padding: 15px;
        padding-top: 0px;
        padding-bottom: 10px; } }
    .pages-product-detial-1 .info-wrapper .box-content .main-title {
      padding-bottom: 25px;
      border-bottom: thin solid #e1e1e1;
      line-height: 1.3; }
      @media screen and (max-width: 1023.98px) {
        .pages-product-detial-1 .info-wrapper .box-content .main-title {
          padding-bottom: 10px; } }
    .pages-product-detial-1 .info-wrapper .box-content .content {
      margin-top: 15px; }
      .pages-product-detial-1 .info-wrapper .box-content .content .table {
        margin-top: 10px;
        border-bottom: thin solid  #e1e1e1;
        padding-bottom: 5px; }
        .pages-product-detial-1 .info-wrapper .box-content .content .table table {
          width: 100%; }
          .pages-product-detial-1 .info-wrapper .box-content .content .table table tr {
            color: #333333;
            font-size: 1rem; }
            .pages-product-detial-1 .info-wrapper .box-content .content .table table tr:nth-of-type(odd) {
              background-color: #f3f3f3; }
            .pages-product-detial-1 .info-wrapper .box-content .content .table table tr th {
              font-weight: 700;
              white-space: nowrap;
              padding: 8px 0px;
              padding-left: 20px; }
            .pages-product-detial-1 .info-wrapper .box-content .content .table table tr td {
              font-weight: 400;
              padding-left: 10px; }
      .pages-product-detial-1 .info-wrapper .box-content .content .wrapper {
        margin-top: 30px;
        display: flex;
        align-items: center; }
        @media screen and (max-width: 1023.98px) {
          .pages-product-detial-1 .info-wrapper .box-content .content .wrapper {
            margin-top: 20px; } }
        .pages-product-detial-1 .info-wrapper .box-content .content .wrapper .social-icons {
          margin-left: 30px;
          display: flex;
          justify-content: center;
          align-items: center; }
          .pages-product-detial-1 .info-wrapper .box-content .content .wrapper .social-icons .icon {
            width: 40px;
            height: 40px;
            border: thin solid #999999;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: .3s all  ease-in-out;
            border-radius: 100%; }
            @media screen and (max-width: 768px) {
              .pages-product-detial-1 .info-wrapper .box-content .content .wrapper .social-icons .icon {
                width: 1.875rem;
                height: 1.875rem; } }
            .pages-product-detial-1 .info-wrapper .box-content .content .wrapper .social-icons .icon:hover {
              border: thin solid #ed1c24;
              background-color: #ffffff; }
              .pages-product-detial-1 .info-wrapper .box-content .content .wrapper .social-icons .icon:hover em {
                color: #ed1c24; }
            .pages-product-detial-1 .info-wrapper .box-content .content .wrapper .social-icons .icon + .icon {
              margin-left: 20px; }
              @media screen and (max-width: 1024px) {
                .pages-product-detial-1 .info-wrapper .box-content .content .wrapper .social-icons .icon + .icon {
                  margin-left: 10px; } }
            .pages-product-detial-1 .info-wrapper .box-content .content .wrapper .social-icons .icon em {
              color: #999999;
              transition: .3s all  ease-in-out;
              font-size: 1.25rem; }
              @media screen and (max-width: 768px) {
                .pages-product-detial-1 .info-wrapper .box-content .content .wrapper .social-icons .icon em {
                  font-size: 1rem; } }
  .pages-product-detial-1 .info-wrapper .box-last {
    padding: 10px 30px;
    flex: 1; }
    @media screen and (max-width: 1279.98px) {
      .pages-product-detial-1 .info-wrapper .box-last {
        flex: 100%;
        max-width: unset; } }
    .pages-product-detial-1 .info-wrapper .box-last .item {
      display: flex;
      padding: 20px 0px;
      border-bottom: thin solid #e1e1e1; }
      .pages-product-detial-1 .info-wrapper .box-last .item .img {
        flex: 0 0 60px;
        height: 60px;
        border-radius: 10px;
        background-color: #f3f3f3;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px; }
        .pages-product-detial-1 .info-wrapper .box-last .item .img img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .pages-product-detial-1 .info-wrapper .box-last .item .content {
        margin-left: 18px;
        color: #333333;
        font-size: 0.875rem;
        font-weight: 400; }
        .pages-product-detial-1 .info-wrapper .box-last .item .content span {
          color: #ed1c24;
          font-weight: 700; }

@media screen and (min-width: 768px) {
  .pages-product-detial-3.section {
    padding-top: 30px; } }

.pages-product-detial-3 .product-slide {
  position: relative;
  padding: 10px 0px; }
  .pages-product-detial-3 .product-slide .product-item {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
    border: 1px solid #f3f3f3;
    background-color: #ffffff; }

.pages-project-detail-sec-1 {
  border-bottom: 2px solid #f3f3f3; }
  .pages-project-detail-sec-1 .header .wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .pages-project-detail-sec-1 .header .wrapper .social-icons {
      display: flex;
      justify-content: center;
      align-items: center; }
      .pages-project-detail-sec-1 .header .wrapper .social-icons .icon {
        width: 40px;
        height: 40px;
        border: thin solid #999999;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s all ease-in-out;
        border-radius: 100%; }
        @media screen and (max-width: 768px) {
          .pages-project-detail-sec-1 .header .wrapper .social-icons .icon {
            width: 1.875rem;
            height: 1.875rem; } }
        .pages-project-detail-sec-1 .header .wrapper .social-icons .icon:hover {
          border: thin solid #ed1c24;
          background-color: #ed1c24; }
          .pages-project-detail-sec-1 .header .wrapper .social-icons .icon:hover em {
            color: #ffffff; }
        .pages-project-detail-sec-1 .header .wrapper .social-icons .icon + .icon {
          margin-left: 20px; }
          @media screen and (max-width: 1024px) {
            .pages-project-detail-sec-1 .header .wrapper .social-icons .icon + .icon {
              margin-left: 10px; } }
        .pages-project-detail-sec-1 .header .wrapper .social-icons .icon em {
          color: #999999;
          transition: .3s all ease-in-out;
          font-size: 1.25rem; }
          @media screen and (max-width: 768px) {
            .pages-project-detail-sec-1 .header .wrapper .social-icons .icon em {
              font-size: 1rem; } }
  .pages-project-detail-sec-1 .body .wrapper {
    position: relative; }
    .pages-project-detail-sec-1 .body .wrapper .swiper-button-prev, .pages-project-detail-sec-1 .body .wrapper .swiper-button-next {
      transition: .3s all ease-in-out;
      top: 45%; }
  .pages-project-detail-sec-1 .body .project-detail-top .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 53.19149%; }
    .pages-project-detail-sec-1 .body .project-detail-top .img img, .pages-project-detail-sec-1 .body .project-detail-top .img iframe, .pages-project-detail-sec-1 .body .project-detail-top .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .pages-project-detail-sec-1 .body .project-detail-thumbs .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 52.84553%; }
    .pages-project-detail-sec-1 .body .project-detail-thumbs .img img, .pages-project-detail-sec-1 .body .project-detail-thumbs .img iframe, .pages-project-detail-sec-1 .body .project-detail-thumbs .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .pages-project-detail-sec-1 .body .pages-content, .pages-project-detail-sec-1 .body .table {
    max-width: 1020px;
    margin: 0 auto;
    margin-top: 25px; }
  .pages-project-detail-sec-1 .body .table {
    width: 100%;
    overflow: auto; }
    .pages-project-detail-sec-1 .body .table::-webkit-scrollbar {
      width: 2px;
      height: 2px; }
    .pages-project-detail-sec-1 .body .table table {
      width: 100%; }
      @media screen and (max-width: 576px) {
        .pages-project-detail-sec-1 .body .table table {
          width: 800px; } }
      .pages-project-detail-sec-1 .body .table table tr {
        color: #333333;
        font-size: 1.125rem;
        line-height: 50px; }
        .pages-project-detail-sec-1 .body .table table tr:nth-of-type(odd) {
          background-color: #f3f3f3; }
        .pages-project-detail-sec-1 .body .table table tr th {
          font-weight: 700;
          padding-left: 30px;
          padding-right: 10px;
          max-width: 200px; }
        .pages-project-detail-sec-1 .body .table table tr td {
          font-weight: 400; }

.pages-project-detail-sec-2 {
  min-height: 300px; }
  @media screen and (min-width: 1280px) {
    .pages-project-detail-sec-2 {
      min-height: 585px; } }
  @media screen and (min-width: 1280px) {
    .pages-project-detail-sec-2.section {
      padding-bottom: 80px; } }
  .pages-project-detail-sec-2 .project-detail-slide-2 {
    position: relative; }
