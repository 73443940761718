.pages-brand-sec-6
    min-height: 815px
    .pages-title
        padding-bottom: 10px
    .col-lg-6
        &:first-child
            @media screen and ( max-width: 1024px )
                margin-bottom: 30px
        &:last-child

            .col-lg-6
                margin-bottom: 30px
    .box-img
        .img
            overflow: hidden
            &:hover
                img
                    transform: scale(1.05)
                .content
                    color: $main
                    letter-spacing: 1.5px
            .content
                position: absolute
                bottom: r(18px)
                left: r(18px)
                color: $white
                transition: .3s all  ease-in-out
                font-weight: 400
                display: flex
                align-items: center
                z-index: 2
                span
                    margin-left: 10px
                    font-size: r(16px)
                em
                    font-size: r(20px)
        &.big
            .img
                a
                    +img-ratio(600/600)
        &.small
            .img
                a
                    +img-ratio(285/285)
