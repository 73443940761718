.modulepager
	.pagination
		display: flex
		list-style-type: none
		justify-content: center
		flex-wrap: wrap
		padding: 0
		margin-top: 10px
		li
			margin: 0px 5px
			color: $main
			display: flex
			justify-content: center
			align-items: center
			&:last-child
				margin-right: 0
			a, span
				width: 40px
				height: 40px
				display: flex
				justify-content: center
				align-items: center
				background: $tran
				transition: .2s ease-in-out all
				overflow: hidden
				font-size: r(18px)
				color: #999999
				font-size: r(16px)
				font-weight: 400
				border: 2px solid #999999
			&.active, &:hover
				a, span
					color: $white
					background: $main
					border: 2px solid $tran
			@media(min-width: 1024px)

				a, span
					width: 40px
					height: 40px
