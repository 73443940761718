.pages-introduce-sec-2
    min-height: 870px
    &.section
        @media screen and ( min-width: 1280px )
            padding-top: 65px

    .pages-content
        max-width: 860px
        margin: 0 auto
    .box-gallery
        display: grid
        grid-template-columns: repeat(6,1fr)
        grid-template-rows: 230px 345px
        grid-auto-flow: row
        grid-gap: 5px
        @media screen and ( max-width: 1280px )
            grid-template-rows: 230px 230px 320px
        @media screen and ( max-width: 768px )
            grid-template-rows: auto
        @media screen and ( max-width: 768px )
            grid-template-columns: 1fr
        .box-item
            position: relative
            &:nth-of-type(1)
                grid-column: 1 /3
                grid-row: 1
            &:nth-of-type(2)
                grid-column: 3/5
                grid-row: 1
            &:nth-of-type(3)
                grid-column: 5/7
                grid-row: 1
            &:nth-of-type(4)
                grid-column: 1/4
                grid-row: 2
            &:nth-of-type(5)
                grid-column: 4/7
                grid-row: 2
            @media screen and ( max-width: 1280px )
                &:nth-of-type(1)
                    grid-column: 1 /4
                    grid-row: 1
                &:nth-of-type(2)
                    grid-column: 4/7
                    grid-row: 1
                &:nth-of-type(3)
                    grid-column: 1/4
                    grid-row: 2
                &:nth-of-type(4)
                    grid-column: 4/7
                    grid-row: 2
                &:nth-of-type(5)
                    grid-column: 1/7
                    grid-row: 3
            @media screen and ( max-width: 768px )
                &:nth-of-type(1)
                    grid-column: auto
                    grid-row: 1
                &:nth-of-type(2)
                    grid-column: auto
                    grid-row: 2
                &:nth-of-type(3)
                    grid-column: auto
                    grid-row: 3
                &:nth-of-type(4)
                    grid-column: auto
                    grid-row: 4
                &:nth-of-type(5)
                    grid-column: auto
                    grid-row: 5
            &:hover
                img
                    transform: scale(1.05)
            &.small
                .img
                    a
                        +img-ratio(230/407)
            &.big
                .img
                    a
                        +img-ratio(345/612)
            .img
                overflow: hidden
                width: 100%
                height: 100%

                a
                    height: 100% !important
            .content
                position: absolute
                bottom: 20px
                left: 50%
                transform: translateX(-50%)
                color: $white
                font-size: r(18px)
                font-weight: 400
                line-height: r(24px)
                text-align: center
                padding: 0px 40px
                width: 100%
                z-index: 2
